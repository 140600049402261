/********************************************************************
 *
 * /components/layout/header/SideMenu.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Box,
  Drawer,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Hidden,
} from "@mui/material"
import { Divider, BookButton } from "../../index"

/**
 * SideMenu
 *
 * Renders the side menu for smaller displays
 *
 * @param {*} props
 */
const SideMenu = ({ items, open, onClose }) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(
        relativePath: { eq: "canadian-coastal-sailing-squamish-icon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 50, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Drawer anchor={"left"} open={open} onClose={onClose}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "1em" }}
      >
        <Grid item>
          <Link href="/">
            <GatsbyImage
              image={getImage(logo)}
              alt="Canadian Coastal Sailing Squamish"
            />
          </Link>
        </Grid>
        <Hidden smUp>
          <Grid item>
            <BookButton label="See Dates" />
          </Grid>
        </Hidden>
      </Grid>
      <ListContainer navItems={items} onClose={onClose} />
    </Drawer>
  )
}

const ListContainer = ({ navItems, onClose }) => (
  <Box
    role="presentation"
    onClick={onClose}
    onKeyDown={onClose}
    sx={{
      width: 250,
    }}
  >
    <List>
      <Divider />
      {navItems &&
        navItems.map(({ label, hide, path, children }, index) => (
          <React.Fragment key={index}>
            {!hide && (
              <>
                {children && children.length > 0 ? (
                  <>
                    {children.map(({ label, hide, path }, index) => (
                      <React.Fragment key={index}>
                        {!hide && (
                          <ListItem button component="a" href={path}>
                            <ListItemText primary={label} />
                          </ListItem>
                        )}
                      </React.Fragment>
                    ))}
                    <Divider />
                  </>
                ) : (
                  <ListItem button component="a" href={path}>
                    <ListItemText primary={label} />
                  </ListItem>
                )}
              </>
            )}
          </React.Fragment>
        ))}
    </List>
  </Box>
)

/*
 **  PropTypes
 */
SideMenu.propTypes = {
  items: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

SideMenu.defaultProps = {
  open: true,
}

export default SideMenu
