/********************************************************************
 *
 * /components/category/tips/TipSingles.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link, Typography } from "@mui/material"
import Tip from "./Tip"
import { useSiteTools } from "../../../providers/SiteToolsProvider"

/**
 * Renders a tip for singles booking a sail tour
 *
 * @param {*} param0
 * @returns
 */
const TipSingles = ({ open, onClose }) => {
  const { onSupportRequest } = useSiteTools()
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        relativePath: {
          eq: "products/sailing-tours/pretty-girl-on-a-sailboat-1-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)

  return (
    <Tip image={image} open={open} onClose={onClose}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Sailing Tours for Solo Travelers
      </Typography>
      <Typography paragraph>
        Whether you're new to town or a solo traveller following your own path
        around the world, come aboard, share an incredible sailing experience
        and leave with new friends and great memories.
      </Typography>
      <Typography paragraph>
        Sailing has the unique benefit of appealing to adventure-loving people
        just like you, all united by a common thread: filling their lives with
        amazing people and interesting stories. We'd love to hear yours.
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Small Group Sailing Tours for Singles
      </Typography>
      <Typography paragraph>
        Our small group tours are perfect for singles and solo travellers. The
        afternoon{" "}
        <Link href="/p/sailing-tours/sailing-experience/">
          Sailing Experience
        </Link>{" "}
        welcomes adventure-seekers to experience the scenic beauty of Northern
        Howe Sound and the legendary{" "}
        <Link href="/squamish/wind/" target="_blank">
          Squamish wind.
        </Link>{" "}
      </Typography>
      <Typography paragraph>
        If you spend your day playing in the mountains, the{" "}
        <Link href="/p/sailing-tours/sunset-sailboat-cruise/">
          Sunset Cruise
        </Link>{" "}
        is an ideal way to relax and enjoy the alpenglow over snow capped
        mountains after a few hours of hands-on sailing in emerald waters.
      </Typography>
      <Typography paragraph>
        <Link onClick={onSupportRequest}>Let us know</Link> if your perfect date
        is sold out - as a free agent, there is a good chance that we can make
        room just for you.
      </Typography>
    </Tip>
  )
}

/*
 **  PropTypes
 */
TipSingles.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TipSingles
