/********************************************************************
 *
 * /components/hero.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

/**
 * Hero
 *
 * Renders a page banner.
 *
 * @param {*} param0
 */
const Hero = ({
  title,
  titleComponent,
  subtitle = undefined,
  height = "40vh",
  maxHeight,
  minHeight,
  img,
  background,
  children = undefined,
  classes,
  sx,
  ...other
}) => {
  return (
    <Box
      component="section"
      {...other}
      sx={[
        {
          display: "block",
          position: "relative",
          boxSizing: "border-box",
          height: !!height ? height : "inherit",
          maxHeight: !!maxHeight ? maxHeight : "inherit",
          minHeight: !!minHeight ? minHeight : undefined,
          width: "100%",
        },
        !!classes && !!classes.root && classes.root,
        sx,
      ]}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          position: "relative",
          padding: { xs: "0 32px", md: "0 100px" },
          width: "100%",
          height: "100%",
          textAlign: { xs: "center", sm: "left" },
          zIndex: 10,
          background: background ? background : "inherit",
        }}
      >
        {(title || subtitle) && (
          <Box
            sx={{
              maxWidth: "500px",
            }}
          >
            {title && (
              <Typography
                variant="hero1"
                component={titleComponent ? titleComponent : "div"}
              >
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography variant="hero2" component="div">
                {subtitle}
              </Typography>
            )}
          </Box>
        )}
        {children}
      </Box>
      {img && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            minWidth: "100%",
            overflow: "hidden",
            zIndex: 0,
            "& > *": {
              minWidth: "100%",
              minHeight: "100%",
            },
          }}
        >
          {img}
        </Box>
      )}
    </Box>
  )
}

/*
 **  PropTypes
 */
Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.string,
}

Hero.defaultProps = {
  height: "40vh",
}

export default Hero
