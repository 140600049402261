/********************************************************************
 *
 * app/lib/SyncButton.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import LoadingButton from "@mui/lab/LoadingButton"

/**
 * SyncButton
 *
 * Renders a button that can be diabled while performing async functions.
 *
 * @param {*} props
 */
const SyncButton = ({ type, disabled, processing, children, onClick, sx }) => (
  <LoadingButton
    type={type}
    color="primary"
    variant="contained"
    disabled={disabled}
    loading={processing}
    onClick={onClick}
    sx={sx}
  >
    {children}
  </LoadingButton>
)

/*
 **  PropTypes
 */
SyncButton.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  sx: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

SyncButton.defaultProps = {
  type: "button",
}

export default SyncButton
