/********************************************************************
 *
 * /components/PageSEO.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

/**
 * PageSEO component that queries for data with Gatsby's useStaticQuery
 * React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
function PageSEO({ description, lang = "en", meta, title, sd }) {
  const location = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            name
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://canadiancoastal.com${location.pathname}`,
        },
        {
          property: `og:image`,
          content: `https://canadiancoastal.com/images/canadian-coastal-sailing-md.png`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {!!sd && (
        <script type="application/ld+json">
          {buildStructuredData(Array.isArray(sd) ? [...sd] : sd)}
        </script>
      )}
      <link
        rel="canonical"
        href={`https://canadiancoastal.com${location.pathname}`}
      />
    </Helmet>
  )
}

const buildStructuredData = (...args) => {
  let jsonLd = {
    "@graph": [],
  }

  args.forEach(arg => {
    if (Array.isArray(arg)) jsonLd["@graph"] = [...jsonLd["@graph"], ...arg]
    else jsonLd["@graph"].push(arg)
  })
  return JSON.stringify(jsonLd)
}

/*
 **  PropTypes
 */

PageSEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  sd: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object, PropTypes.func),
  ]),
}

PageSEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default PageSEO
