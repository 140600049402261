/********************************************************************
 *
 * /components/product/footer.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { AppBar, Toolbar, Box, Button, Typography } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PopupPromo } from "../index"

const Footer = ({ product, onShowCalendar }) => (
  <AppBar
    position="fixed"
    component="section"
    sx={{
      backgroundColor: "#ffffff",
      color: "text.primary",
      width: "100%",
      height: "70px",
      top: "auto",
      borderTop: "solid 1px #eeeeee",
      bottom: 0,
    }}
  >
    <Toolbar
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: { xs: "flex-end", sm: "space-between" },
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "flex" },
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            height: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            marginRight: "1em",
            marginBottom: "0.5em",
          }}
        >
          <GatsbyImage
            image={getImage(product.image.img)}
            alt={product.image.alt}
            placeholder="blurred"
            loading="lazy"
            style={{
              position: "relative",
              borderRadius: "50%",
              overflow: "hidden",
              zIndex: 1,
              height: "100%",
              minWidth: "100%",
            }}
          />
        </Box>
        <Typography
          sx={{
            fontWeight: "600",
            display: "inline-block",
          }}
        >
          {product.name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            paddingRight: "1em",
          }}
        >
          {product.pricing.tag}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={onShowCalendar}
          sx={{
            fontWeight: 700,
            textAlign: "center",
            fontSize: { xs: "8pt", sm: "10pt", md: "11pt", lg: "12pt" },
            letterSpacing: { sm: "0.125em", md: "0.125em", lg: "0.125em" },
          }}
        >
          {product.pricing.label}
        </Button>
      </Box>
    </Toolbar>
    <PopupPromo
      color="primary"
      action={
        <Button
          variant="outlined"
          color="highlight"
          onClick={onShowCalendar}
          sx={{
            fontWeight: 700,
            textAlign: "center",
            fontSize: { xs: "8pt", sm: "10pt", md: "11pt", lg: "12pt" },
            letterSpacing: { sm: "0.125em", md: "0.125em", lg: "0.125em" },
          }}
        >
          {product.pricing.label}
        </Button>
      }
    />
  </AppBar>
)

/*
 **  PropTypes
 */
Footer.propTypes = {
  product: PropTypes.object.isRequired,
  onShowCalendar: PropTypes.func.isRequired,
}

export default Footer
