/********************************************************************
 *
 * app/orderProcessing/components/results/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { DateTime } from "luxon"
import { useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import SKUs from "./SKUs"
import InlineContainer from "./InlineContainer"
import ModalContainer from "./ModalContainer"

/**
 * Results
 *
 * Renders the search results
 */
const Results = ({ skus, cover = false, onClear }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("md"))

  //
  //  Display in fullscreen modal if the containing component requests
  //  cover-mode or we are on a mobile device
  //
  if (cover || mobile)
    return (
      skus && (
        <ModalContainer show={!!skus} mobile={mobile} onClear={onClear}>
          <ProductList skus={skus} />
        </ModalContainer>
      )
    )

  //
  //  Otherwise display placeholder / list of products
  //
  return (
    <InlineContainer show={!!skus}>
      <ProductList skus={skus} />
    </InlineContainer>
  )
}

const ProductList = ({ skus }) => {
  if (!skus) return null

  /* Each product type has its own tree. */
  return (
    skus &&
    skus.map((dayPTSkus, index) => <SKUs key={index} skus={dayPTSkus} />)
  )
}

/*
 **  PropTypes
 */
Results.propTypes = {
  date: PropTypes.instanceOf(DateTime),
  zone: PropTypes.string.isRequired,
  skus: PropTypes.array,
}

export default Results
