/********************************************************************
 *
 * /components/Policy.jsx
 *
 * Copyright 2023 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

const Policy = ({ policy }) => (
  <Box>
    <Typography variant="h3" component="h2" gutterBottom>
      {policy.name}
    </Typography>
    <Typography variant="body1" gutterBottom>
      {policy.policy}
    </Typography>
  </Box>
)

/*
 **  PropTypes
 */
Policy.propTypes = {
  policy: PropTypes.object,
}

export default Policy
