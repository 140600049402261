/********************************************************************
 *
 * /components/marquee/Marqueeboard.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Paper, Typography } from "@mui/material"

/**
 * MarqueeBoard
 *
 * Renders a message box within a Marquee
 *
 * @param {*} param0
 */
const MarqueeBoard = ({ label, children }) => {
  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
        border: 0,
      }}
    >
      <Paper
        sx={{
          height: "100%",
          width: { xs: "100%", sm: "420px" },
          position: "relative",
          p: { xs: 0, sm: 4 },
          borderRadius: 4,
          color: "secondary.contrastText",
          boxShadow: { xs: "none", sm: "0 6px 20px rgb(0 0 0 / 20%)" },
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          sx={{
            marginTop: "0.5em",
            marginBottom: { xs: "0.8em", sm: "0.5em" },
          }}
        >
          {label}
        </Typography>
        {children}
      </Paper>
    </Box>
  )
}

/*
 **  PropTypes
 */
MarqueeBoard.propTypes = {
  label: PropTypes.string,
  children: PropTypes.element,
}

export default MarqueeBoard
