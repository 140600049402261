/********************************************************************
 *
 * components/product/Body.jsx
 *
 * Copyright 22022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import { getImage, withArtDirection } from "gatsby-plugin-image"
import { Box, Grid, Link, Hidden, Typography } from "@mui/material"
import {
  Section,
  Assistant,
  Materialize,
  Endorsement,
  ProductCard,
} from "../index"
import InfoSection from "./InfoSection"
import Includes from "./Includes"
import Policy from "./policy"
import Sims from "./sims"

import { TourPicker } from "../../app/orderProcessing/components"

/**
 * Body
 *
 * Renders the body portion of a product page
 *
 * @param {*} param0
 */
const Body = ({ product, sims, calendarRef }) => {
  return (
    <>
      <Section top maxWidth="lg" component="div">
        <Grid container>
          <Grid item xs={12} md={7}>
            <InfoSection label="Welcome aboard!">
              <Materialize content={product.fullDescription} />
            </InfoSection>
            <InfoSection label="What's Included" divider>
              <Includes product={product} />
            </InfoSection>
            <InfoSection label="What to Bring" divider>
              <Materialize content={product.prepare} />
            </InfoSection>
            <InfoSection label="What People Say" divider>
              <Endorsement classes={reviewStyles} showRating={true} />
              <Endorsement classes={reviewStyles} showRating={true} />
              <Endorsement classes={reviewStyles} showRating={true} />
              <Endorsement classes={reviewStyles} showRating={true} />
            </InfoSection>
          </Grid>
          <Grid item xs={12} md={5}>
            <ProductPicker calendarRef={calendarRef} product={product} />
          </Grid>
        </Grid>
      </Section>
      <Section maxWidth="lg" component="div" divider>
        <InfoSection label="Additional Information">
          <Policy label="Age Restrictions" divider>
            <Typography>
              Guests of all ages are welcome aboard. Please be aware that
              sailing involves movement of people and the vessel, and with any
              recreational activity, we recommend that our guests weigh their
              physical abilities and comfort with the activity. Guest's can
              choose to participate in a hands-on role to help sail the vessel,
              or sit back in the Admiral's chair, enjoy the stunning scenery and
              help us keep an eye out for marine-life. Please{" "}
              <Link href="/contact/">Contact Us</Link> if you have any
              questions.
            </Typography>
          </Policy>
          <Policy label="Accessibility" divider>
            <Typography paragraph>
              Reducing barriers is a core value that enables us to share our
              passion for sailing British Columbia's coast. The design of a
              Canadian Coastal sailboat tour overcomes many of the obstacles
              associated with typical sailing vessels and enhances the
              experience for all of our guests.
            </Typography>
            <Typography>
              Your comfort and safety is our priority. Please{" "}
              <Link href="/contact/">Contact Us</Link> if you have specific
              needs or questions.
            </Typography>
          </Policy>
          <Policy label="Cancellation Policy" divider>
            <Typography>
              As sailors, we understand that life does not follow a straight
              line and sometimes plans change. Learn more about our{" "}
              <Link href="/policies/cancellation/">Cancellation Policies</Link>.
            </Typography>
          </Policy>
          <Policy label="Questions?" divider>
            <Typography>
              We are here to help and encourage you to ask questions. Check out
              our <Link href="/faq/">Frequently Asked Questions</Link> to
              discover some of the common answers, or{" "}
              <Link href="/contact/">Contact Us</Link> if you have specific
              questions.
            </Typography>
          </Policy>
        </InfoSection>
      </Section>
      <Section maxWidth="lg" divider>
        <Sims
          productCards={sims.nodes.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        />
      </Section>
    </>
  )
}

const ProductPicker = ({ calendarRef, product }) => {
  const bannerImage = withArtDirection(getImage(product.searchBanner.img), [])

  return (
    <>
      <Hidden mdUp>
        <InfoSection label="Select A Date" component="div" />
      </Hidden>
      <Box
        sx={{
          position: { md: "sticky" },
          top: { md: "150px" },
        }}
      >
        <Box
          sx={{
            pb: { xs: 0, md: 4 },
            pt: { md: 4 },
          }}
        >
          <Box
            ref={calendarRef}
            sx={{
              display: "block",
              position: "relative",
              top: "-125px",
              visibility: "hidden",
            }}
          />
          <TourPicker
            productTypeIds={[product.id]}
            cover={true}
            resultBanner={{
              img: bannerImage,
              alt: product.searchBanner.alt,
            }}
          />
        </Box>
      </Box>
    </>
  )
}

//
//  Styles for endorsement
//
const reviewStyles = {
  root: { mb: 5 },
  quote: {
    mb: 0.5,
  },
  rating: {
    color: "primary.main",
    mt: 1,
  },
  name: { fontSize: "1em", fontWeight: 600 },
}

/*
 **  PropTypes
 */
Body.propTypes = {
  product: PropTypes.object.isRequired,
  sims: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  calendarRef: PropTypes.object.isRequired,
}

Body.defaultProps = {
  label: "Book Now!",
}

export default Body
