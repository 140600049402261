/********************************************************************
 *
 * components/Carousel/CarouselButton.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { IconButton } from "@mui/material"

/**
 * CarouselButton
 *
 * Styled button component used for mobile-mode Carousel
 *
 * @param {*} param0
 * @returns
 */
const CarouselButton = ({ ariaLabel, onClick, disabled, children }) => (
  <IconButton
    aria-label={ariaLabel}
    size="small"
    disabled={disabled}
    onClick={onClick}
    sx={{
      "&::disabled": {
        opacity: "0.5",
        cursor: "not-allowed",
        color: "rgb(221, 221, 221)",
        transform: "scale(1)",
      },
      "&::before": {
        content: "''",
        display: "block",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        border: "1px solid rgb(221,221,221)",
      },
    }}
  >
    {children}
  </IconButton>
)

/*
 **  PropTypes
 */
CarouselButton.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default CarouselButton
