/********************************************************************
 *
 * /components/splitScreen/ContentPane.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Grid } from "@mui/material"

/**
 * ContentPane
 *
 * Renders a splitscreen (l/r pane) content pane
 */
const ContentPane = ({ xs, sm, md, lg, xl, sx, children }) => (
  <Grid
    className="SplitScreenContent"
    item
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    sx={sx}
  >
    {children}
  </Grid>
)

/*
 **  PropTypes
 */
ContentPane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

export default ContentPane
