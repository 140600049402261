/********************************************************************
 *
 * /providers/SiteToolsProvider.jsx
 *
 * Provides common functionality within the website context:
 *
 * - Injects a 'Contact Us' popup into page that can be triggered
 *   using onSupportRequest
 *
 * Copyright 2024 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { DateTime } from "luxon"
import { Assistant } from "../components"

const WebsiteContext = React.createContext()
const EMPTY_SUPPORT_REQUEST = {
  name: "",
  email: "",
  phone: "",
  defaultMessage: "",
}

const SiteToolsProvider = ({ children }) => {
  const [showSupportRequest, setShowSupportRequest] = useState(false)
  const [contactPopupData, setContactPopupData] = useState(
    EMPTY_SUPPORT_REQUEST
  )
  const { siteConfig } = useStaticQuery(graphql`
    query {
      siteConfig: allConfigJson {
        nodes {
          locale {
            zone
          }
          season {
            name
            start
            end
            soldOut
          }
        }
      }
    }
  `)

  const { locale, season: _season } = siteConfig.nodes[0]

  const season = {
    name: _season.name,
    start: DateTime.fromISO(_season.start, {
      zone: locale.zone,
    }).startOf("day"),
    end: DateTime.fromISO(_season.end, {
      zone: locale.zone,
    }).endOf("day"),
    display:
      DateTime.now() <
        DateTime.fromISO(_season.end, {
          zone: locale.zone,
        }).endOf("day") && !_season.soldOut,
  }

  /**
   * OnSupportData
   *
   * Triggers a popup with a 'Contact Us' dialog
   *
   * @param {*} defaultData
   */
  const onSupportRequest = requestData => {
    setContactPopupData({ ...EMPTY_SUPPORT_REQUEST, ...requestData })
    setShowSupportRequest(true)
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Affiliate API functions
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WebsiteContext.Provider value={{ locale, season, onSupportRequest }}>
      {children}
      {onSupportRequest && (
        <Assistant
          name={contactPopupData.name}
          email={contactPopupData.email}
          phone={contactPopupData.phone}
          defaultMessage={contactPopupData.defaultMessage}
          show={!!showSupportRequest}
          onClose={() => {
            setShowSupportRequest(false)
          }}
        />
      )}
    </WebsiteContext.Provider>
  )
}

///////////////////////////////////////////////////////////////////
//
//  Private Helper Functions
//
///////////////////////////////////////////////////////////////////

/*
 **  Hooks
 */
const useSiteTools = () => {
  return React.useContext(WebsiteContext)
}

export { SiteToolsProvider, useSiteTools }
