/********************************************************************
 *
 * /components/BannerItemCard.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Card, CardContent, Typography } from "@mui/material"

/**
 * BannerItemCard
 *
 * Renders a card-style banner item
 *
 * @param {*} param0
 */
const BannerItemCard = ({ icon, label, children, sx }) => (
  <Card
    sx={[
      {
        color: "#666666",
        padding: "0.5em 0.5em 0 0.5em",
        height: "100%",
        boxShadow: "0 6px 16px rgba(0,0,0,0.12)",
        borderRadius: "12px",
      },
      sx,
    ]}
  >
    <CardContent>
      {icon}
      <Typography
        variant="h3"
        sx={{
          marginTop: "0.5em",
          marginBottom: "0.5em",
        }}
      >
        {label}
      </Typography>
      {children}
    </CardContent>
  </Card>
)

/*
 **  PropTypes
 */
BannerItemCard.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  children: PropTypes.element,
}

export default BannerItemCard
