/********************************************************************
 *
 * components/Features/Features.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2023 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

/**
 * Features
 *
 * @param {*} param0
 * @returns
 */
const Features = ({ label, children }) => {
  return (
    <Box data-component-id="FEATURES">
      <Typography
        variant="h1"
        component="h2"
        sx={{
          textAlign: "center",
          maxWidth: { xs: "none", md: "572", lg: "672px" },
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: { xs: 2, sm: 6, xl: 8 },
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: { xs: "block", sm: "grid" },
          gridTemplateColumns: { xs: "none", sm: "1fr 1fr" },
          columnGap: { xs: "none", sm: "16px" },
        }}
      >
        {children}
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */
Features.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
}

export default Features
