/********************************************************************
 *
 * app/orderProcessing/components/results/ModalContainer.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import {
  Dialog,
  DialogContent,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Slide,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Close } from "@mui/icons-material/"

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" timeout={50000} ref={ref} {...props} />
})

/**
 * ModalContainer
 *
 * Renders a modal frame for search results
 */
const ModalContainer = ({ show, mobile, onClear, children }) => {
  const { bannerImg } = useStaticQuery(graphql`
    {
      bannerImg: file(relativePath: { eq: "banner-default-search.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const theme = useTheme()

  return (
    <Dialog
      fullScreen={mobile}
      TransitionComponent={Transition}
      scroll="body"
      open={show}
      onClose={onClear}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        "& .MuiPaper-root": { borderRadius: { md: "15px" } },
      }}
    >
      <AppBar
        color="inherit"
        elevation={mobile ? 1 : 0}
        sx={{
          position: "sticky",
          height: { xs: mobile ? "33vh" : "30px", md: "30px" },
          padding: { xs: 0 },
          marginBottom: "20px",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClear}
            aria-label="close"
            size="large"
            sx={{
              backgroundColor: "rgb(255,255,255,0.5)",
              "&:hover": {
                backgroundColor: "rgb(255,255,255,0.75)",
              },
            }}
          >
            <Close />
          </IconButton>
        </Toolbar>
        {mobile && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              minWidth: "100%",
              minHeight: "33vh",
              maxHeight: "33vh",
              zIndex: -1,
              "& > *": {
                minWidth: "100%",
                minHeight: "33vh",
                maxHeight: "33vh",
              },
            }}
          >
            <GatsbyImage
              image={withArtDirection(getImage(bannerImg), [])}
              alt={"Couple On A Sailboat Near Squamish"}
              placeholder="blurred"
              loading="eager"
              objectFit="cover"
              objectPosition="50% 50%"
              style={{ height: "100%" }}
            />
          </Box>
        )}
      </AppBar>
      <DialogContent
        sx={{
          py: { md: 0 },
          px: { md: 4 },
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

/*
 **  PropTypes
 */
ModalContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  onClear: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default ModalContainer
