/********************************************************************
 *
 * /icons/Distance.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { SvgIcon } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const Distance = ({
  height = 100,
  width = 100,
  color,
  background = false,
  sx,
}) => {
  const classes = styles()

  return (
    <div
      className={classes.base}
      role="img"
      style={{ color: !!color ? color : undefined }}
    >
      <SvgIcon
        width={width}
        height={height}
        fill={!!color ? "currentColor" : undefined}
        viewBox="0 0 512 512"
        sx={[
          {
            transition: "color 0.5s linear",
            width: "inherit",
            height: "inherit",
          },
          !!color && {
            color: color,
            "& path": {
              fill: "inherit",
            },
          },
          sx,
        ]}
      >
        <g fill="white" fill-rule="nonzero">
          <path d="M104 112a7.975 7.975 0 005.657-2.343l32-32-11.314-11.314L104 92.686 93.657 82.343 82.343 93.657l16 16A7.975 7.975 0 00104 112z" />
          <path d="M112 144a56 56 0 10-56-56 56.064 56.064 0 0056 56zm0-96a40 40 0 11-40 40 40.045 40.045 0 0140-40zM392 112a7.975 7.975 0 005.657-2.343l32-32-11.314-11.314L392 92.686l-10.343-10.343-11.314 11.314 16 16A7.975 7.975 0 00392 112z" />
          <path d="M400 144a56 56 0 10-56-56 56.064 56.064 0 0056 56zm0-96a40 40 0 11-40 40 40.045 40.045 0 0140-40zM245.657 109.657L256 99.313l10.343 10.344 11.314-11.314L267.313 88l10.344-10.343-11.314-11.314L256 76.687l-10.343-10.344-11.314 11.314L244.687 88l-10.344 10.343z" />
          <path d="M256 144a56 56 0 10-56-56 56.064 56.064 0 0056 56zm0-96a40 40 0 11-40 40 40.045 40.045 0 0140-40zM496 356v-20a8 8 0 00-8-8h-8V196a28.031 28.031 0 00-28-28H348a27.918 27.918 0 00-20 8.423A27.918 27.918 0 00308 168H204a27.918 27.918 0 00-20 8.423A27.918 27.918 0 00164 168h-60v16h60a12.014 12.014 0 0112 12v84H48v-84a12.014 12.014 0 0112-12h28v-16H60a28.031 28.031 0 00-28 28v132h-8a8 8 0 00-8 8v20a28.043 28.043 0 0024 27.715v48.57A28.043 28.043 0 0016 460v12a8 8 0 008 8h80a8 8 0 008-8v-12a28.043 28.043 0 00-24-27.715V384h280v-16H44a12.014 12.014 0 01-12-12v-12h127.716a11.922 11.922 0 018.485 3.515l10.142 10.142a8 8 0 0011.314 0l10.142-10.142a11.922 11.922 0 018.485-3.515h95.432a11.922 11.922 0 018.485 3.515l10.142 10.142a8 8 0 0011.314 0l10.142-10.142a11.922 11.922 0 018.485-3.515H480v12a12.014 12.014 0 01-12 12h-84v16h40v48.285A28.043 28.043 0 00400 460v12a8 8 0 008 8h80a8 8 0 008-8v-12a28.043 28.043 0 00-24-27.715v-48.57A28.043 28.043 0 00496 356zM96 460v4H32v-4a12.014 12.014 0 0112-12h40a12.014 12.014 0 0112 12zm-24-76v48H56v-48zm276-200h104a12.014 12.014 0 0112 12v84H336v-84a12.014 12.014 0 0112-12zm-144 0h104a12.014 12.014 0 0112 12v84H192v-84a12.014 12.014 0 0112-12zm-44.284 144H48v-32h128v37.215A27.794 27.794 0 00159.716 328zm144 0h-95.432A27.794 27.794 0 00192 333.215V296h128v37.215A27.794 27.794 0 00303.716 328zM336 333.215V296h128v32H352.284A27.794 27.794 0 00336 333.215zM456 384v48h-16v-48zm24 76v4h-64v-4a12.014 12.014 0 0112-12h40a12.014 12.014 0 0112 12z" />
          <path d="M208 208h16v16h-16zM248 208h16v16h-16zM288 208h16v16h-16zM208 240h16v16h-16zM248 240h16v16h-16zM288 240h16v16h-16zM64 208h16v16H64zM104 208h16v16h-16zM144 208h16v16h-16zM64 240h16v16H64zM104 240h16v16h-16zM144 240h16v16h-16zM352 208h16v16h-16zM392 208h16v16h-16zM432 208h16v16h-16zM352 240h16v16h-16zM392 240h16v16h-16zM432 240h16v16h-16z" />
        </g>
      </SvgIcon>
    </div>
  )
}

const styles = makeStyles(theme => ({
  base: { lineHeight: "1em" },
  override: {
    transition: "color 0.5s linear",
    "& $st0, & $st1, & $st2, & $st3, & $st4, & $st6, & $st7": {
      fill: "inherit",
    },
  },
  transparent: { opacity: 0 },
}))

/*
 **  PropTypes
 */
Distance.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export default Distance
