/********************************************************************
 *
 * /components/category/tips/Tip.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import {
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Slide,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Close } from "@mui/icons-material"

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" timeout={50000} ref={ref} {...props} />
})

/**
 * Renders a Tip in the tip list
 *
 * @param {*} param0
 * @returns
 */
const Tip = ({ image, open, onClose, children }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Dialog
      keepMounted
      fullScreen={mobile}
      maxWidth="md"
      TransitionComponent={Transition}
      scroll="paper"
      open={open}
      onClose={onClose}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
        "& .MuiPaper-root": { borderRadius: { md: "15px" } },
      }}
    >
      <DialogActions sx={{ justifyContent: "left" }}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row-reverse" },
            pb: 2,
          }}
        >
          <Box
            sx={{
              display: { xs: "block", sm: "block" },
              pl: { xs: 0, md: 6 },
              pb: { xs: 4, md: 0 },
            }}
          >
            <Box
              sx={{
                mx: { xs: 0, md: 4 },
                mb: 2,
                height: { xs: "auto", sm: "250px" },
                width: { xs: "100%", sm: "250px" },
                aspectRatio: { xs: "1.618", sm: "1" },
                borderRadius: { xs: "15px", sm: "50%" },
                overflow: "hidden",
                "& img": { objectPosition: { xs: "50% 25%", md: "50% 50%" } },
              }}
            >
              <GatsbyImage
                image={getImage(image)}
                alt={"Young couple on a sailboat tour"}
                placeholder="blurred"
                loading="lazy"
                objectFit="cover"
                style={{ height: "100%" }}
              />
            </Box>
          </Box>
          <Box>{children}</Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

/*
 **  PropTypes
 */
Tip.propTypes = {
  transition: PropTypes.element,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default Tip
