/********************************************************************
 *
 * /components/Biosphere.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { Box, Typography, useTheme } from "@mui/material"
import { Unesco } from "../icons"

const Biosphere = ({ horizontal }) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          sm: "row",
          lg: horizontal ? "row" : "column",
        },
        alignItems: "center",
        border: "solid 1px rgb(235, 235, 235)",
        borderRadius: "4px",
        width: { xs: "100%", sm: "600px", lg: horizontal ? "600px" : "400px" },
        px: { xs: 4, md: 5 },
        py: 4,
      }}
    >
      <Box
        sx={{
          pb: { xs: 2, sm: 0, md: 2 },
          pr: { xs: 4, lg: horizontal ? 4 : 0 },
        }}
      >
        <Unesco
          height="100px"
          width="100px"
          color={theme.palette.text.primary}
        />
      </Box>
      <Box>
        <Typography textAlign="center" variant="h5" paragraph>
          Átl&amp;ka7tsem/Howe Sound <br />
          UNESCO Biosphere Reserve
        </Typography>
        <Typography textAlign="center" variant="caption" paragraph>
          Canadian Coastal Sailing promotes education, awareness and sustainable
          tourism practices so that we may respect and protect the rich
          ecological system in which we are privileged to share with nature,
          residents and guests.
        </Typography>
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */
Biosphere.propTypes = {}

export default Biosphere
