/********************************************************************
 *
 * Month.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography, LinearProgress } from "@mui/material"
import { DateTime } from "luxon"
import Week from "./Week"

/**
 * Month
 *
 * Renders a row of seven CalandarDays on the calendar grid.
 *
 */
const Month = ({
  startDate,
  zone,
  numWeeks,
  activeDate,
  value,
  loading = false,
  onDateRender,
  onChanged,
}) => {
  if (!startDate || !startDate.isValid) return null

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          width: "100%",
          height: "1.65em",
        }}
      >
        <Typography variant="body2" sx={styles.caldow}>
          Su
        </Typography>
        <Typography variant="body2" sx={styles.caldow}>
          Mo
        </Typography>
        <Typography variant="body2" sx={styles.caldow}>
          Tu
        </Typography>
        <Typography variant="body2" sx={styles.caldow}>
          We
        </Typography>
        <Typography variant="body2" sx={styles.caldow}>
          Th
        </Typography>
        <Typography variant="body2" sx={styles.caldow}>
          Fr
        </Typography>
        <Typography variant="body2" sx={styles.caldow}>
          Sa
        </Typography>
      </Box>
      <LinearProgress
        value={100}
        variant={loading ? "indeterminate" : "determinate"}
        sx={{
          height: "1px",
          "& .MuiLinearProgress-root": {
            marginBottom: 2,
          },
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#cccccc",
          },
          "& .MuiLinearProgress-colorPrimary": {
            backgroundColor: "primary.main",
          },
        }}
      />
      <Box>
        {[...Array(numWeeks).keys()].map((key, index) => (
          <Week
            key={key}
            startDate={startDate.plus({ weeks: index })}
            zone={zone}
            activeDate={activeDate}
            value={value}
            onDateRender={onDateRender}
            onChanged={onChanged}
          />
        ))}
      </Box>
    </Box>
  )
}

/*
 **  Styles
 */

const styles = {
  caldow: {
    textAlign: "center",
    display: "inline-block",
    position: "relative",
    width: "14.2857%",
    height: "100%",
    paddingLeft: "5px",
  },
}

/*
 **  Props
 */

Month.propType = {
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  zone: PropTypes.string.isRequired,
  numWeeks: PropTypes.number,
  activeDate: PropTypes.instanceOf(DateTime).required,
  value: PropTypes.instanceOf(DateTime),
  onDateRender: PropTypes.func,
  onChanged: PropTypes.func,
}

Month.defaultProps = {
  numWeeks: 6,
}

export default Month
