/********************************************************************
 *
 * app/orderProcessing/components/MobileBar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import { Box, Button } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { Divider } from "../../../../components"
import ProductTypePicker from "./ProductTypePicker"
import DatePicker from "./DatePicker"

/**
 * MobileBar
 *
 * Renders a search bar for desktop layouts
 *
 */
const MobileBar = ({
  defaultPT,
  productTypes,
  ptName,
  selectedPT,
  onSelectPT,
  selectedDate,
  loading,
  onMonthChange,
  onDateStatus,
  onChangeDate,
  minDate,
  maxDate,
  onSubmit,
}) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   */
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "relative",
        textAlign: "left",
        px: 4,
        pt: 4,
      }}
    >
      {/* <Box sx={{ pb: 2 }}>
        <ProductTypePicker
          defaultPT={defaultPT}
          productTypes={productTypes}
          ptName={ptName}
          value={selectedPT}
          onSelect={onSelectPT}
        />
      </Box> */}
      <Box sx={{ pb: 2 }}>
        <DatePicker
          value={selectedDate}
          loading={loading}
          showLoading={true}
          onMonthChange={onMonthChange}
          onDateStatus={onDateStatus}
          onChange={onChangeDate}
          min={minDate}
          max={maxDate}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "auto",
          pt: 2,
          pb: 8,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={onSubmit}
          sx={{
            backgroundColor: "primary.dark",
            color: "primary.contrastText",
            borderRadius: "10px",
            p: 2,
            height: "48px",
            width: "100%",
          }}
        >
          Search
        </Button>
      </Box>
      <Divider />
    </Box>
  )
}

/*
 **  PropTypes
 */
MobileBar.propTypes = {}

export default MobileBar
