import { DateTime } from "luxon"

let format = new (class Format {
  timeFormat = "HH:mm"

  /**
   * getLocalized
   *
   * Initializes a date. If a timezone argument is included, then the
   * time is timezone adjusted, otherwise the time will be localized
   * to the browser locale.
   *
   * @param {date} date
   * @param {timezone} zone
   */
  getLocalized(date, zone) {
    if (zone) {
      return DateTime.fromISO(date, { zone })
    }
    return DateTime.fromISO(date)
  }

  date(date, zone) {
    return this.getLocalized(date, zone).toFormat("DD")
  }

  shortDayDate(date, zone) {
    return date.toLocaleString({
      weekday: "long",
      month: "long",
      day: "2-digit",
    })
  }

  month(date, zone) {
    return this.getLocalized(date, zone).toFormat("MMMM YYYY")
  }

  mday(date, zone) {
    let mdate = this.getLocalized(date, zone)

    if (false && 1 === mdate.date()) {
      return mdate.toFormat("d MMM")
    } else {
      return mdate.toFormat("d")
    }
  }

  toApiDateTime(datetime) {
    return datetime.toISO()
  }

  toApiDate(datetime) {
    return datetime.toISODate()
  }

  percent(amount) {
    return `${amount * 100}%`
  }

  currency(amount, dollarsOnly = false) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: dollarsOnly ? 0 : 2,
    })

    return formatter.format(amount / 100)
  }

  waterLevel(metricData) {
    if (!metricData) return "--"
    return `${parseFloat(metricData).toFixed(2)}m`
  }
})()

export default format
