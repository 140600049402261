/********************************************************************
 *
 * /components/category/tips/TipFamily.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link, Typography } from "@mui/material"
import Tip from "./Tip"
import { useSiteTools } from "../../../providers/SiteToolsProvider"

/**
 * Renders a tip for families booking a sail tour
 *
 * @param {*} param0
 * @returns
 */
const TipFamily = ({ open, onClose }) => {
  const { onSupportRequest } = useSiteTools()
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        relativePath: {
          eq: "products/sailing-tours/family-sailing-tour-1-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)

  return (
    <Tip image={image} open={open} onClose={onClose}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Sailing Tours for Familes
      </Typography>
      <Typography paragraph>
        Sailing is the perfect activity for all ages!{" "}
      </Typography>
      <Typography paragraph>
        Kids have fun exploring the vessel and learning new skills, and teens
        and adults can join the crew to help sail the vessel. Those that prefer
        to relax will find plenty of comfortable areas to sit back and enjoy the
        stunning scenery. Note that children under the age of two are welcome at
        no charge when accompanied by an adult.
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Small Family Sailing Tours
      </Typography>
      <Typography paragraph>
        For families up to five, consider joining others on a shared afternoon{" "}
        <Link href="/p/sailing-tours/sailing-experience/">
          Sailing Experience
        </Link>
        , or if the intent is to plan a unique experience, choose the
        flexibility of a{" "}
        <Link href="/p/sailing-charters/private-sailboat-charter/">
          Private Sailboat Charter
        </Link>{" "}
        and let us help plan something special.
      </Typography>
      <Typography paragraph>
        <Link onClick={onSupportRequest}>Ask Us</Link> about designing a sailing
        tour specifically for the needs and wishes of your family, or check out
        our FAQ for great{" "}
        <Link href="/faq/" target="_blank">
          information on sailing tours in BC
        </Link>
        .
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Private Sailboat Charter for Families and Events
      </Typography>
      <Typography paragraph>
        For larger familes and those wishing to celebrate an anniversary,
        graduation, birthday, reunion or any other special occasion, a{" "}
        <Link href="/p/sailing-charters/private-sailboat-charter/">
          Private Sailboat Charter
        </Link>{" "}
        is an excellent choice and perfect for familes that desire exclusive use
        of the vessel and crew.
      </Typography>
      <Typography paragraph>
        <Link onClick={onSupportRequest}>Let us know</Link> how our
        premium-level sailing experience can be designed to suit your family and
        ensure a lifeftime of great memories.
      </Typography>
    </Tip>
  )
}

/*
 **  PropTypes
 */
TipFamily.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TipFamily
