/********************************************************************
 *
 * app/componenets/Popup.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, ClickAwayListener } from "@mui/material"

/**
 * Popup
 *
 * Implements a non-modal popup. The container is absolute positioned,
 * so needs to be a child of a positioned component. Offsets are defined
 * top and left.
 *
 * @param {*} param0
 * @returns
 */
const Popup = ({ top, left, open, onClose, children }) => {
  return open ? (
    <ClickAwayListener onClickAway={onClose}>
      <Box sx={{ position: "relative" }}>
        <Box
          role="presentation"
          sx={{
            position: "absolute",
            zIndex: 10,
            top,
            left,
          }}
        >
          {children}
        </Box>
      </Box>
    </ClickAwayListener>
  ) : null
}
/*
 **  PropTypes
 */
Popup.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Popup
