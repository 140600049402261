/********************************************************************
 *
 * app/orderProcessing/componenets/searchBar/ControlBase.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography, FormControl, InputBase } from "@mui/material"

const ControlBase = ({ label, value, open, onClick, children }) => {
  const ref = React.useRef()
  const labelId = `label-${Math.floor(Math.random() * 1000)}`

  return (
    <FormControl
      ref={ref}
      //   classes={{
      //     root: [openPicker && styles.openProduct],
      //   }}
      onClick={() => {
        onClick(ref)
      }}
      sx={{
        width: "100%",
        boxShadow: { xs: "rgb(221 221 221) 0px 0px 0px 1px inset", sm: "none" },
        maxHeight: "64px",
        flexDirection: "column",
        justifyContent: "flex-start",
        borderRadius: { xs: "10px", sm: "33px" },
        padding: "14px 32px",
        "&:hover": {
          backgroundColor: "action.hover",
        },
        "&:after": {
          backgroundClip: "padding-box !important",
          border: "1px solid transparent !important",
          borderRadius: { xs: "10px", sm: "32px" },
          position: "absolute !important",
          content: "''",
          top: "0px !important",
          left: "0px !important",
          bottom: "0px !important",
          right: "0px !important",
          zIndex: "0 !important;",
          backgroundColor: open ? "#FFFFFF !important" : "transparent",
          borderColor: open ? "#FFFFFF !important" : "none",
          boxShadow: open ? "0px 6px 20px rgb(0 0 0 / 20%) !important" : "none",
        },
      }}
    >
      <Box sx={{ position: "relative", zIndex: 1 }}>
        <Typography
          htmlFor={labelId}
          variant="label"
          component="label"
          paragraph
        >
          {label}
        </Typography>
        <InputBase
          id={labelId}
          sx={{
            width: "100%",
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "left",
            textTransform: "none",
            "&:focus": { backgroundColor: "transparent" },
            "& .MuiInputBase-input": {
              display: "block !important",
              border: "0px !important",
              margin: "0px !important",
              padding: "0px !important",
              width: "100% !important",
              background: "none !important",
              fontSize: "16px !important",
              lineHeight: "18px !important",
              fontWeight: "400 !important",
              color: "#222222 !important",
              textOverflow: "ellipsis !important",
            },
          }}
          readOnly
          value={value}
        />

        {children}
      </Box>
    </FormControl>
  )
}

/*
 **  PropTypes
 */
ControlBase.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default ControlBase
