/********************************************************************
 *
 * /icons/Unesco.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { SvgIcon } from "@mui/material"

const Unesco = ({ height, width, color, sx }) => (
  <SvgIcon
    fill={!!color ? "currentColor" : undefined}
    viewBox="15 13 70 70"
    sx={[
      {
        width: width ? width : "inherit",
        height: height ? height : "inherit",
      },
      !!color && {
        color: color,
        "& path": {
          fill: "inherit",
        },
      },
      sx,
    ]}
  >
    <g>
      <path
        fill="#000000"
        d="M21.011 36.146v26.624c0 0.723 -0.08 1.083 0.16 1.444 0.12 0.24 0.361 0.361 0.561 0.361 0.281 0 0.521 -0.12 0.681 -0.401 0.241 -0.361 0.201 -0.722 0.201 -1.483V36.146h1.243v27.146c0 1.844 -0.682 2.766 -2.125 2.766 -0.602 0 -1.123 -0.201 -1.524 -0.641s-0.602 -1.123 -0.602 -2.086V36.146h1.404zM32.638 36.146l2.045 17.161V36.146h0.922v29.752h-1.323l-2.005 -18.284v18.284H31.195V36.146h1.443zM46.432 37.429h-1.965v12.109H46.432v1.042h-1.965v14.074h2.246v1.243h-4.01V36.146H46.432v1.283zM15.317 31.936h70.049v2.486H15.317v-2.486zM15.317 68.102h70.049v2.487H15.317v-2.487zM10.826 73.115h78.389v2.486H10.826v-2.486zM6.856 77.966h86.288v2.526H6.856v-2.526zM15.156 29.129v-2.646l34.844 -10.184 35.204 10.024v2.646L49.679 19.305 15.156 29.129z"
      />
      <path
        fill="#000000"
        d="M23.135 29.129l27.226 -7.819 26.865 7.819h-7.258L50.281 23.796l-19.647 5.172 -7.498 0.16zM56.415 44.726h1.524v-5.774s-0.04 -1.684 -0.442 -2.165c-0.4 -0.441 -0.881 -0.842 -1.924 -0.842 -1.002 0.04 -1.243 0.321 -1.604 0.762 -0.401 0.441 -0.561 1.203 -0.561 2.005s0.04 4.371 0.16 5.573c0.12 1.163 1.243 5.894 1.885 8.179 0.602 2.325 0.882 3.489 0.923 4.13 0.039 0.682 0.119 2.968 0.039 4.09 -0.039 1.123 0 2.687 0 2.687s0.08 1.243 -0.641 1.243c-0.762 0 -0.682 -1.243 -0.682 -1.243v-7.058h-1.524v7.017s-0.4 2.566 2.005 2.566c2.446 0 2.446 -2.286 2.446 -2.286V57.197s-0.12 -2.125 -0.522 -3.609c-0.4 -1.443 -1.684 -6.054 -1.965 -7.337 -0.32 -1.363 -0.441 -3.369 -0.441 -5.172 0 -1.804 0.04 -2.927 0.04 -2.927s-0.04 -0.842 0.641 -0.842c0.641 0 0.602 0.882 0.602 0.882l0.04 6.535zM68.083 44.806h1.323V37.87c0 -1.082 -0.601 -2.165 -2.086 -2.165 -1.363 0 -2.164 1.002 -2.164 2.205v26.064c0 1.162 0.802 2.164 2.164 2.164 1.484 0 2.086 -1.043 2.086 -2.125v-7.818h-1.323v7.779c0 0.4 0.04 0.881 -0.762 0.881 -0.842 0 -0.761 -0.723 -0.761 -1.002v-25.862c0 -0.281 -0.081 -1.002 0.761 -1.002 0.803 0 0.762 0.521 0.762 0.922v6.896zM79.15 35.705c-1.363 0 -2.325 1.002 -2.325 2.205v26.064c0 1.162 0.962 2.164 2.325 2.164 1.483 0 2.245 -1.043 2.245 -2.125V37.87c0.001 -1.082 -0.762 -2.165 -2.245 -2.165zm0.922 28.268c0 0.4 -0.119 0.881 -0.922 0.881 -0.842 0 -0.922 -0.723 -0.922 -1.002v-25.862c0 -0.281 0.08 -1.002 0.922 -1.002 0.803 0 0.922 0.521 0.922 0.922v26.063z"
      />
    </g>
  </SvgIcon>
)

export default Unesco
