/********************************************************************
 *
 * /components/tag.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

/**
 * Tag
 *
 * Renders a tag badge
 *
 * @param {*} param0
 */
const Tag = ({ variant, children }) => {
  return (
    <Box
      sx={{
        display: "inline-block",
        borderRadius: "5px",
        color: "#ffffff",
        backgroundColor: "primary.main",
        padding: "0.3em 0.4em",
        marginRight: "0.4em",
        marginBottom: "0.4em",
      }}
    >
      <Typography
        variant={variant}
        sx={{
          fontFamily: "Lato, Helvetica, Arial, sans-serif",
          fontSize: "0.625rem",
          fontWeight: 200,
          textTransform: "uppercase",
          letterSpacing: { sm: "0.125em" },
        }}
      >
        {children}
      </Typography>
    </Box>
  )
}

/*
 **  PropTypes
 */
Tag.propTypes = {
  children: PropTypes.string,
}

Tag.defaultProps = {
  variant: "body1",
}

export default Tag
