/********************************************************************
 *
 * app/lib/Assistant.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Lead } from "."

/**
 * Assistant
 *
 * Renders the "call the captain" popup with overlay.
 */
const Assistant = ({
  name = "",
  email = "",
  phone = "",
  defaultMessage = "",
  show,
  onClose,
}) => {
  const theme = useTheme()
  const [showClose, setShowClose] = useState(false)

  return (
    <Dialog
      classes={{
        paper: {
          width: "100%",
          padding: 2,
        },
      }}
      open={show}
      scroll="body"
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      }}
    >
      <DialogContent>
        <DialogContentText>
          <Lead
            title="Contact the Captain"
            subtitle="We are here to help! Let us know what you need."
            name={name}
            email={email}
            phone={phone}
            defaultMessage={defaultMessage}
            typeId={6}
            onSaved={() => setShowClose(true)}
            onCancelled={() => onClose && onClose()}
          />
        </DialogContentText>
      </DialogContent>
      {showClose && (
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setShowClose(false)
              onClose && onClose()
            }}
          >
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

/*
 **  PropTypes
 */

Assistant.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Assistant
