/********************************************************************
 *
 * /components/SEO/StructuredData/SportsActivityLocationSchema.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

const SportsActivityLocationSchema = () => {
  return {
    "@context": "https://schema.org/",
    "@type": "SportsActivityLocation",
    "@id": "https://canadiancoastal.com/#SportsActivity",
    name: "Canadian Coastal Squamish Sailing Departures",
    address: {
      "@type": "PostalAddress",
      streetAddress: "37778 Loggers Ln",
      addressLocality: "Squamish",
      addressRegion: "BC",
      postalCode: "V8B0S8",
      addressCountry: "CA",
    },
    url: "https://canadiancoastal.com/",
    logo: "https://canadiancoastal.com/images/canadian-coastal-sailing-sm.png",
    image: [
      "https://canadiancoastal.com/images/stock/1x1/sailing-squamish.png",
      "https://canadiancoastal.com/images/stock/4x3/sailing-squamish.png",
      "https://canadiancoastal.com/images/stock/16x9/sailing-squamish.png",
    ],
    sameAs: [
      "https://www.instagram.com/canadiancoastal/",
      "https://en.wikipedia.org/wiki/Boat_tour/",
    ],
    additionalType: {
      "@type": "TouristAttraction",
      touristType: [
        "Family",
        "Small Group",
        "Local",
        "Wedding",
        "Environmental",
      ],
    },
    priceRange: "$129-$169",
    geo: {
      "@type": "GeoCoordinates",
      "@id": "https://canadiancoastal.com/#Geo",
      latitude: "49.6945523",
      longitude: "-123.1545193",
      name: "Canadian Coastal Sailing Departure Location",
      description:
        "Sail tours departing from the docks of the Squamish Harbour Authority",
    },
    areaServed: {
      "@type": "GeoCircle",
      "@id": "https://canadiancoastal.com/#Served",
      name: "Canadian Coastal Service Area",
      geoMidpoint: {
        "@type": "GeoCoordinates",
        latitude: "49.6945523",
        longitude: "-123.1545193",
        name: "Canadian Coastal Sailing Departure Location",
        description:
          "Sail tours departing from the docks of the Squamish Harbour Authority",
      },
      geoRadius: "200000",
    },
  }
}

export default SportsActivityLocationSchema
