/********************************************************************
 *
 * /components/category/tips/TipCouple.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link, Typography } from "@mui/material"
import Tip from "./Tip"
import { useSiteTools } from "../../../providers/SiteToolsProvider"

/**
 * Renders a tip for couples booking a sail tour
 *
 * @param {*} param0
 * @returns
 */
const TipCouple = ({ open, onClose }) => {
  const { onSupportRequest } = useSiteTools()
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        relativePath: {
          eq: "products/sailing-tours/young-couple-on-a-sailboat-1-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)

  return (
    <Tip image={image} open={open} onClose={onClose}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Sailing Tours for Couples
      </Typography>
      <Typography paragraph>
        Sailing is a great activity for two! Simply decide if you prefer the
        intimacy and exclusivity of a private charter, or would like to join
        other guests for a shared sailing experience.
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Shared Sailing Experiences
      </Typography>
      <Typography paragraph>
        A{" "}
        <Link href="/p/sailing-tours/sunset-sailboat-cruise/">
          Sunset Cruise
        </Link>{" "}
        is ideal for couples looking for a fun and romantic date-night. After
        some hands-on sailing, cuddle up with your sweetheart under the
        alpenglow and twilight.
      </Typography>
      <Typography paragraph>
        Adventure-seeking couples may prefer an afternoon{" "}
        <Link href="/p/sailing-tours/sailing-experience/">
          Sailing Experience
        </Link>
        . Grab the lines, jump on the helm and enjoy the unique experience found
        in one of the{" "}
        <Link href="/squamish/wind/" target="_blank">
          world's-best sailing destinations
        </Link>
        .
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Private Sailboat Charter
      </Typography>
      <Typography paragraph>
        A{" "}
        <Link href="/p/sailing-charters/private-sailboat-charter/">
          Private Sailboat Charter
        </Link>{" "}
        is perfect for couples that desire exclusive use of the vessel and crew.
        Our premium-level sailing experience is perfect for those seeking
        intimate time alone, or to celebrate a special occasion such as a
        birthday, engagement, honeymoon, or anniversary.
      </Typography>
      <Typography paragraph>
        Tell us about your{" "}
        <Link onClick={onSupportRequest}>
          ideas, wishes, or special requests to help design a perfect occasion{" "}
        </Link>
        and celebrate your time together.
      </Typography>
    </Tip>
  )
}

/*
 **  PropTypes
 */
TipCouple.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TipCouple
