/********************************************************************
 *
 * Policy.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import {
  SplitScreen,
  SplitScreenTitlePane,
  SplitScreenContentPane,
  Divider,
} from "../index"

/**
 * Policy
 *
 * Renders a policy block. Used in the body of a product page.
 *
 * @param {*} param0
 */
const Policy = ({ label, divider, children }) => (
  <Box>
    {divider && <Divider sx={{ mb: { xs: 4, md: 4 } }} />}
    <SplitScreen mb={{ xs: 4, md: 4 }}>
      <SplitScreenTitlePane
        label={label}
        variant="h3"
        xs={12}
        md={4}
      ></SplitScreenTitlePane>
      <SplitScreenContentPane xs={12} md={8}>
        {children}
      </SplitScreenContentPane>
    </SplitScreen>
  </Box>
)

/*
 **  PropTypes
 */
Policy.propTypes = {
  label: PropTypes.string,
  divider: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

Policy.defaultProps = {
  divider: false,
}

export default Policy
