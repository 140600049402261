/********************************************************************
 *
 * app/orderProcessing/components/SearchBar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { Box, Typography, Link } from "@mui/material"
import { DateTime } from "luxon"
import { useApp, useCCAPI, useAuthAPI } from "../../../../providers/AppProvider"
import { useAffiliate } from "../../../../providers/AffiliateProvider"
import * as tags from "../../../../lib/tags"
import CustomAdapterLuxon from "../../../../lib/CustomAdapterLuxon"
import Results from "../results"
import useSelectedDate from "../selectedDate"
import ModalContainer from "../results/ModalContainer"
import MobileBar from "./MobileBar"
import DesktopBar from "./DesktopBar"
import { useSiteTools } from "../../../../providers/SiteToolsProvider"

//
//  Initialize a defalut product type to use in the product type selection.
//  The default is used as a 'show me everything' option.
//
const DEFAULT_PT = {
  id: -1,
  name: "Show me everything!",
  url: "/p/sailing-tours/",
}

/**
 * SearchBar
 *
 * Renders a search panel
 *
 * productTypes - array of product type options
 * ptName - category name for product types
 *
 * */
const SearchBar = ({ productTypes, ptName }) => {
  const { locale, season, onSupportRequest } = useSiteTools()
  const [showNoAvail, setShowNoAvail] = useState(false)
  const [loading, setLoading] = useState(false)
  const [productType, setProductType] = useState(null)
  const [selectedDate, setSelectedDate] = useSelectedDate(
    season.start,
    season.end,
    locale.zone
  )
  const [ptAvailability, setPtAvailability] = useState(null)
  const [SKUs, setSKUs] = useState(null)
  const app = useApp()
  const api = useCCAPI()
  const authApi = useAuthAPI()
  const { getAffiliate } = useAffiliate()

  useEffect(() => {
    setProductType(DEFAULT_PT)
  }, [])

  useEffect(() => {
    const now = DateTime.now()

    //
    //  Prefetch the SKUs for the calendar month. There may be a
    //  scalability issue if the number of products increases
    //
    fetchSKUs(
      selectedDate ||
        (now < season.start
          ? season.start
          : now > season.end
          ? season.end
          : now)
    )
  }, [productType])

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchSKUs
   *
   * Calls the API to fetch the product types' sku trees for the dates
   * visible on the calendar.
   *
   */
  const fetchSKUs = calendarDate => {
    const cal = new CustomAdapterLuxon()
    let calendarDates = null
    let qs = ""

    if (!calendarDate || !productType) return

    setPtAvailability(null)
    calendarDates = cal.getWeekArray(calendarDate || DateTime.now())

    return new Promise((resolve, reject) => {
      //
      //  Product types are sent in the query string as one or
      //  more pt values. If no pt values are included, use the default
      //
      //  TODO: Add more intelligence to query string parsing and defaults
      //
      if (productType.id > 0)
        qs += `${qs.length > 0 ? "&" : ""}pt=${productType.id}`
      else
        productTypes.forEach(pt => {
          qs += `${qs.length > 0 ? "&" : ""}pt=${pt.id}`
        })

      setLoading(true)

      let start = (
        calendarDates[0][0].startOf("day") > DateTime.now().startOf("day")
          ? calendarDates[0][0]
          : DateTime.now().startOf("day")
      ).toISO()
      let end = calendarDates[calendarDates.length - 1][6].endOf("day").toISO()

      //
      //  Request searches
      //
      api
        .fetch(`/api/skus/daterange/${start}/${end}?${qs}`, getAffiliate().id)
        .then(({ payload: ptSkuTrees }) => {
          setPtAvailability(ptSkuTrees)
          resolve()
          setLoading(false)
        })
        .catch(error => {
          reject(error)
          setPtAvailability(null)
          setLoading(false)
        })
    })
  }

  /**
   * GetSKUsForDate
   *
   * Parses the array of PT SKU trees for skus available for the
   * given date.
   *
   * @param {*} date
   *
   * @returns Returns a 2D array of skus.
   */
  const getSKUsForDate = date => {
    let y,
      m,
      d,
      daySkus = null

    if (!ptAvailability || !DateTime.isDateTime(date) || !date.isValid)
      return null

    //
    //  Fetch the 2D array of product type skus for the day
    //
    ptAvailability.forEach(ptSkuTree => {
      y = ptSkuTree.years[date.year]
      if (y) m = y.months[date.month - 1]
      if (m) d = m.days[date.day]
      if (d) {
        let availableSkus = d.nodes.filter(node => node.available > 0)
        if (availableSkus && availableSkus.length > 0) {
          if (!daySkus) daySkus = []
          daySkus.push(availableSkus)
        }
      }
    })

    return daySkus
  }

  /**
   * TrackDateHit
   *
   * @param {Datetime} selectedDate
   */
  const trackDateHit = (selectedDate, ptIds) => {
    //
    //  Verify date
    //
    if (!DateTime.isDateTime(selectedDate) || !selectedDate.isValid) return

    //
    //  Log the search date
    //
    authApi
      .create(`/event/producttype/`, {
        date: selectedDate.toISODate(),
        ptIds,
        affiliateId: getAffiliate().id,
      })
      .catch(error => {
        app.error({ error, location: "ProductSelector.trackDateHit" })
      })
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * onMonthChange
   *
   * @param {*} date
   */
  const onMonthChange = date => {
    fetchSKUs(date)
  }

  /**
   * onDateStatus
   *
   * @param {*} date
   * @returns
   */
  const onDateStatus = date => {
    return !getSKUsForDate(date)
  }

  /**
   * onSubmit
   *
   */
  const onSubmit = () => {
    let skus = null

    if (selectedDate) {
      //
      //  Fetch and assign SKUs for selected date
      //
      skus = getSKUsForDate(selectedDate)

      if (skus && skus.length > 0) setSKUs(skus)
      else setShowNoAvail(true)

      //
      //  Track date hit
      //
      const productTypeIds = []
      if (productType.id > 0) productTypeIds.push(productType.id)
      else
        productTypes.forEach(productType => {
          productTypeIds.push(productType.id)
        })

      trackDateHit(selectedDate, productTypeIds)
      tags.fireEvent("tour-search-date", {
        searchDate: selectedDate.toISODate(),
        component: "Search Bar",
        productTypes: productTypeIds,
      })
    } else {
      //
      //  No date. Navigate to the product type url (default is the order page with all pts)
      //
      navigate(productType.url)
    }
  }

  /**
   * Render
   */
  return (
    <>
      <Box
        sx={{
          display: { xs: "block", sm: "none" },
          width: "100%",
        }}
      >
        <MobileBar
          defaultPT={DEFAULT_PT}
          productTypes={productTypes}
          ptName={ptName}
          selectedPT={productType}
          onSelectPT={productType => {
            setProductType(productType)
          }}
          selectedDate={selectedDate}
          loading={loading}
          onMonthChange={onMonthChange}
          onDateStatus={onDateStatus}
          onChangeDate={date => {
            setSelectedDate(date)
          }}
          minDate={season.start}
          maxDate={season.end}
          onSubmit={onSubmit}
        />
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "block" },
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <DesktopBar
          defaultPT={DEFAULT_PT}
          productTypes={productTypes}
          ptName={ptName}
          selectedPT={productType}
          onSelectPT={productType => {
            setProductType(productType)
          }}
          selectedDate={selectedDate}
          loading={loading}
          onMonthChange={onMonthChange}
          onDateStatus={onDateStatus}
          onChangeDate={date => {
            setSelectedDate(date)
          }}
          minDate={season.start}
          maxDate={season.end}
          onSubmit={onSubmit}
        />
      </Box>
      <Results
        date={selectedDate}
        zone={locale.zone}
        skus={SKUs}
        cover={true}
        onClear={() => {
          setSKUs(null)
        }}
      />
      {showNoAvail && (
        <ModalContainer
          show={!!showNoAvail}
          onClear={() => {
            setShowNoAvail(false)
          }}
        >
          <Box
            sx={{
              pt: 4,
              pb: 8,
            }}
          >
            <Typography
              textAlign="center"
              variant="h3"
              component="div"
              paragraph
            >
              {`Aww, darn, it seems that ${
                productType.id == -1
                  ? "nothing is"
                  : "a " + productType.name + " is not"
              } available on ${selectedDate.toLocaleString({
                weekday: "long",
                month: "long",
                day: "2-digit",
              })}.`}
            </Typography>
            <Box sx={{ pl: 4 }}>
              <Typography variant="body1" paragraph>
                You can:
              </Typography>
              <Typography variant="body1" sx={{ pl: 2, fontWeight: 600 }}>
                &bull; Choose a different type of tour, or
              </Typography>
              <Typography
                variant="body1"
                sx={{ pl: 2, fontWeight: 600 }}
                paragraph
              >
                &bull; Select the same type of tour on a different date.
              </Typography>
              <Typography variant="body1">
                Alternatively, check out our{" "}
                <Link href="/order/">Tour Calendar</Link> for complete tour
                availability or{" "}
                <Link
                  onClick={() => {
                    setShowNoAvail(false)
                    onSupportRequest()
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  Send Us a Note
                </Link>{" "}
                and we can help you book a tour.
              </Typography>
            </Box>
          </Box>
        </ModalContainer>
      )}
    </>
  )
}

/*
 **  PropTypes
 */
SearchBar.propTypes = {
  productTypes: PropTypes.array.isRequired,
  ptName: PropTypes.string.isRequired,
}

export default SearchBar
