/********************************************************************
 *
 * components/last-minute/SKU.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react"
import { navigate } from "gatsby"
import { DateTime } from "luxon"
import PropTypes from "prop-types"
import {
  Box,
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material"
import { useApp, useCCAPI, useAuthAPI } from "../../providers/AppProvider"
import { useAffiliate } from "../../providers/AffiliateProvider"
import { SyncButton } from "../../components"
import format from "../../lib/format"
import * as tags from "../../lib/tags"

/**
 * SKU
 *
 * Renders a calendar-indexed list of products.
 *
 */
const SKU = ({ sku, meta }) => {
  const [qty, setQty] = useState(sku.available > 2 ? 2 : sku.available)
  const [processing, setProcessing] = useState(false)
  const app = useApp()
  const api = useCCAPI()
  const authApi = useAuthAPI()
  const { getAffiliate } = useAffiliate()

  if (!sku) return null

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnCreateOrder
   *
   * Fired when a user selects the SKU
   *
   * @param {*} event
   */
  const onCreateOrder = ({ sku }) => {
    setProcessing(true)

    api
      .create(
        `/api/orders/order/`,
        { items: [{ skuId: sku.id, qty: qty }] },
        getAffiliate().id
      )
      .then(({ payload: order }) => {
        navigate(`/order/review/${order.code}`)
        trackCreateOrder(order.id)
        tags.fireEvent("add_to_cart", {
          currency: "CAD",
          value: sku.rate / 100,
          items: [
            {
              item_id: sku.productId,
              item_name: sku.name,
              price: sku.rate / 100,
              quantity: qty,
              affiliation: "Canadian Coastal Website",
              item_brand: "Canadian Coastal Sailing",
            },
          ],
        })
      })
      .catch(error => {
        app.error({ error, location: "SKU.onCreateOrder" })
        setProcessing(false)
      })
  }

  /**
   * TrackCreateOrder
   *
   * @param {*} selectedDate
   */
  const trackCreateOrder = orderId => {
    //
    //  Request affiliate id
    //
    authApi.create(`/event/order`, { orderId }).catch(error => {
      app.error({ error, location: "SKU.trackCreateOrder" })
    })
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!sku) return null

  let start = DateTime.fromISO(sku.start, { zone: sku.timezone })
  let end = DateTime.fromISO(sku.end, { zone: sku.timezone })

  return (
    <Box sx={{ px: 2 }}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          my: 0,
          mx: 0,
          p: 2,
        }}
      >
        <Box sx={{ pb: 2 }}>
          <Typography variant="subtitle1" component="div">
            {start.toFormat("EEE, LLL. dd")}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {start.toFormat("t")}-{end.toFormat("t")}
          </Typography>
        </Box>
        <Box sx={{ flex: "1 0 auto", pb: 2 }}>
          <Typography
            variant="body1"
            component="span"
            sx={{ textDecoration: "line-through" }}
          >
            $139.00
          </Typography>{" "}
          <Typography variant="body1" component="span" sx={{ fontWeight: 700 }}>
            {`${format.currency(sku.rate)}`}
          </Typography>{" "}
          <Typography variant="body1" component="span">
            {`${!!meta && meta.unit} (CAD)`}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#ff4444", pt: 0 }}>
            {sku.available > 0 &&
              sku.available < 6 &&
              `Popular date - only ${
                parseInt(sku.available) === 1 ? "1 " : `${sku.available} `
              } spaces remaining!`}
            &nbsp;
          </Typography>
        </Box>
        {parseInt(sku.available) === 0 ? (
          <Typography
            variant="body2"
            noWrap
            sx={{
              color: "#ff4444",
              padding: 1,
              paddingLeft: "0",
              fontWeight: 700,
              fontSize: "0.9em",
            }}
          >
            SORRY, SOLD OUT
          </Typography>
        ) : (
          <Box sx={{ display: "flex" }}>
            {(!meta || meta.selector) && (
              <FormControl
                variant="outlined"
                margin="dense"
                sx={{ padding: "0 1em 0 0", margin: "0" }}
              >
                <Select
                  value={qty}
                  onChange={e => {
                    e.stopPropagation()
                    setQty(e.target.value)
                  }}
                  sx={{
                    "& .MuiInputBase-input": { px: 2, py: 1.1 },
                  }}
                >
                  {(() => {
                    let items = []

                    for (let i = 1; i <= parseInt(sku.available); i++) {
                      items.push(
                        <MenuItem key={i} value={i}>
                          {i} guest{i == 1 ? "" : "s"}
                        </MenuItem>
                      )
                    }
                    return items
                  })()}
                </Select>
              </FormControl>
            )}
            <SyncButton
              onClick={() => {
                onCreateOrder({ sku })
              }}
              processing={!!processing}
            >
              Book!
            </SyncButton>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

SKU.propTypes = {
  sku: PropTypes.object.isRequired,
}

export default SKU
