/********************************************************************
 *
 * /icons/Logo.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { SvgIcon } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const Logo = ({ height, width, color, sx }) => {
  const classes = styles()
  const idRoot = Math.floor(Math.random() * 1000)

  /* 2.56 aspect ratio */

  return (
    <div role="img">
      <SvgIcon
        aria-labelledby={`title-${idRoot} desc-${idRoot}`}
        // width={width}
        // height={height}
        fill={!!color ? "currentColor" : undefined}
        viewBox={"20 40 750 325"}
        sx={[
          {
            transition: "color 0.5s linear",
            width: width ? width : "inherit",
            height: height ? height : "inherit",
          },
          !!color && {
            color: color,
            "& path": {
              fill: "inherit",
            },
          },
          sx,
        ]}
      >
        <title id={`title-${idRoot}`}>Canadian Coastal Sailing</title>
        <desc id={`desc-${idRoot}`}>
          British Columbia's premiere sailing tour experience
        </desc>
        <g>
          <g>
            <path
              className={classes.st2}
              d="M479.91,339.01c-0.41-3.16-2.12-5.17-8.28-9.14c-9.22-5.91-13.04-9.89-13.98-17.16
				c-1.41-10.9,11.75-24.95,22.84-26.38c9.18-1.18,14.95,3.61,15.88,10.78c0.89,6.88-2.49,13.84-7.27,14.45
				c-1.82,0.23-3.36-0.44-3.46-1.21c-0.04-0.29,0.21-0.61,0.9-1.29c2.88-2.51,6.51-8.32,5.84-13.49c-0.48-3.73-3.31-6.09-8.38-5.43
				c-9.28,1.2-19.32,12.99-18.15,22.08c0.55,4.3,2.85,7.02,7.52,10.79c8.99,7.1,10.62,10,11.31,15.36
				c1.27,9.85-9.27,20.83-21.89,22.46c-10.62,1.37-17.25-4.09-18.32-12.41c-0.85-6.6,2.66-13.27,7.63-13.92
				c1.53-0.2,2.88,0.5,2.98,1.27c0.04,0.29-0.21,0.61-0.9,1.28c-2.8,2.4-6.34,8.11-5.73,12.89c0.56,4.3,4.19,6.85,10.98,5.97
				C470.92,354.46,480.81,345.99,479.91,339.01z"
            />
            <path
              className={classes.st2}
              d="M520.56,316.55c-11.57,1.49-23.29,19.92-22.35,27.19c0.25,1.91,1.1,2.48,1.96,2.37
				c2.58-0.33,8.24-6.99,13.51-15.84c2.55-4.32,2.8-5.42,3.37-5.49c1.15-0.15,3.36,1.9,3.54,3.33c0.26,2.01-3.86,7.01-3.33,11.12
				c0.31,2.39,1.56,3.01,2.99,2.82c5.54-0.72,10.99-9.78,12.97-13.24c0.56-0.95,0.74-1.07,1.02-1.1c0.29-0.04,0.5,0.13,0.54,0.42
				c0.05,0.38-0.01,0.68-0.39,1.51c-2.36,5.07-8.53,14.52-15.32,15.39c-3.54,0.46-6.35-1.71-6.79-5.15
				c-0.18-1.43-0.13-2.51,0.26-4.02c-4.29,6.68-10.09,13.75-15.16,14.4c-2.49,0.32-5.58-1.03-6.16-5.53
				c-1.46-11.28,13.54-29.16,26.16-30.79c2.87-0.37,4.62,0.38,5.75,1.59c0.67,0.69,0.28,1.42-0.63,1.15
				C522.02,316.56,521.33,316.45,520.56,316.55z"
            />
            <path
              className={classes.st2}
              d="M539.84,343.91c-3.54,0.46-6.74-1.75-7.47-7.4c-0.84-6.5,6.03-24.11,10.71-24.71
				c1.53-0.2,3.89,1.54,4.02,2.5c0.07,0.57-0.36,1.02-1.32,2.6c-6.31,10.63-8.12,16.21-7.56,20.52c0.28,2.2,1.28,3.14,3.19,2.89
				c5.45-0.7,10.95-10.07,12.99-13.83c0.46-0.94,0.74-1.07,1.03-1.11c0.29-0.04,0.6,0.12,0.65,0.5c0.05,0.38-0.27,0.91-0.5,1.43
				C553.07,332.67,546.63,343.03,539.84,343.91z M554.52,297.97c0.16-1.77-1.54-3.69-3.21-3.77c-1.96-0.14-4.42,3.39-4.59,6.62
				c-0.1,2.25,1.52,3.5,3.09,3.59C551.67,304.47,554.38,300.62,554.52,297.97z"
            />
            <path
              className={classes.st2}
              d="M564.34,339.39c4.78-0.62,11.27-11.37,14.15-16.9c0.46-0.93,0.74-1.07,1.02-1.1c0.29-0.04,0.6,0.12,0.65,0.5
				c0.05,0.38-0.27,0.91-0.5,1.43c-3.65,7.86-10.11,18.81-16.71,19.66c-3.92,0.51-7.45-1.96-8.24-8.08
				c-1.21-9.37,3.46-25.92,8.49-36.68c3.59-7.56,6.46-12.5,10.47-13.02c2.29-0.3,4.24,1.2,4.34,1.97c0.07,0.57-0.77,0.78-1.71,1.78
				c-2.6,2.47-4.52,5.64-6.82,10.41c-5.23,10.69-10.18,27.37-9.05,36.07C560.82,338.48,562.14,339.67,564.34,339.39z"
            />
            <path
              className={classes.st2}
              d="M584.97,338.08c-3.54,0.46-6.74-1.75-7.47-7.4c-0.84-6.5,6.03-24.11,10.71-24.71
				c1.53-0.2,3.89,1.54,4.02,2.5c0.07,0.57-0.35,1.02-1.32,2.6c-6.31,10.63-8.12,16.21-7.56,20.52c0.28,2.2,1.28,3.14,3.19,2.89
				c5.45-0.7,10.95-10.07,12.99-13.83c0.46-0.94,0.74-1.07,1.02-1.11s0.6,0.12,0.65,0.5c0.05,0.38-0.27,0.91-0.5,1.43
				C598.2,326.85,591.76,337.21,584.97,338.08z M599.65,292.15c0.16-1.77-1.55-3.69-3.21-3.77c-1.96-0.14-4.42,3.39-4.59,6.62
				c-0.1,2.25,1.52,3.5,3.09,3.59C596.8,298.64,599.51,294.79,599.65,292.15z"
            />
            <path
              className={classes.st2}
              d="M608,301.86c1.72-0.22,4.34,1.97,4.49,3.12c0.07,0.57-0.45,1.03-1.32,2.6c-2.91,5.33-6.18,13.92-7.52,21.58
				c5.7-10.84,15.06-23.43,21.28-24.23c2.1-0.27,4.78,0.84,5.13,3.62c0.48,3.73-3.55,9.4-3.01,13.61c0.3,2.29,1.64,2.9,3.46,2.67
				c4.3-0.56,8.71-7.06,10.13-9.67c0.46-0.93,0.74-1.07,1.02-1.1c0.29-0.04,0.6,0.12,0.65,0.5c0.05,0.38-0.27,0.91-0.5,1.43
				c-1.98,4.24-7.67,12.17-13.59,12.93c-3.82,0.49-7.11-1.61-7.58-5.24c-0.62-4.78,3.91-11.88,3.66-13.79
				c-0.09-0.67-0.61-0.99-1.19-0.92c-2.49,0.32-11.87,8.24-19.71,27.33c-0.3,0.72-0.56,0.95-1.04,1.01
				c-1.05,0.14-3.99-0.75-4.36-3.62C597.25,327.75,603.03,302.51,608,301.86z"
            />
            <path
              className={classes.st2}
              d="M661.59,313.32c-0.4,0.63-1,1.3-1.57,2.15c-4.73,6.25-10.57,13.03-15.63,13.69
				c-2.49,0.32-5.58-1.03-6.16-5.53c-1.46-11.28,13.06-29.1,25.4-30.69c2.87-0.37,5.32,0.58,6.45,1.79
				c0.67,0.69,0.28,1.42-0.63,1.15c-0.5-0.13-1.9-0.43-2.67-0.34c-11.19,1.44-22.52,19.82-21.58,27.09c0.25,1.91,1.1,2.48,1.96,2.37
				c2.68-0.35,9.57-6.49,14.89-15.73c2.62-4.52,4.81-8.69,6.15-8.86c1.72-0.22,2.65,0.92,2.7,1.3c0.11,0.86-3.3,6.07-3.96,13.05
				c-0.38,3.84-0.35,10.06-0.17,18.3c0.3,11.34-4.03,24.53-15.98,26.08c-7.94,1.02-12.88-1.84-13.68-8.05
				c-0.75-5.83,4.91-12.4,11.13-16.21c0.81-0.49,1.28-0.65,1.56-0.69c0.76-0.1,1.28,0.13,1.35,0.7c0.07,0.57-0.44,1.13-1.43,1.74
				c-5.01,3.37-8.06,9.89-7.43,14.76c0.48,3.73,3.17,5.72,6.61,5.27c8.51-1.1,12.07-14.2,11.98-24.68c-0.08-5.14-0.09-9.71,0-12.06
				C660.92,317.2,661.14,315.13,661.59,313.32z"
            />
          </g>
        </g>
        <g>
          <path
            className={classes.st3}
            d="M75.84,89.65c12.09,0,20.83,3.83,28.98,11.14c1.08,0.96,1.08,2.39,0.12,3.35l-7.3,7.54
			c-0.84,0.96-2.04,0.96-2.99,0c-5.03-4.43-11.73-7.06-18.44-7.06c-15.33,0-26.7,12.81-26.7,27.9c0,14.97,11.5,27.66,26.82,27.66
			c7.18,0,13.29-2.75,18.32-6.83c0.96-0.84,2.28-0.72,2.99,0l7.42,7.66c0.96,0.84,0.72,2.39-0.12,3.23
			c-8.14,7.9-18.44,11.61-29.1,11.61c-23.95,0-43.22-19.04-43.22-42.98C32.62,108.92,51.89,89.65,75.84,89.65z"
          />
          <path
            className={classes.st3}
            d="M109.72,172.26l28.64-61.98c0.28-0.55,0.74-1.01,1.57-1.01h0.92c0.92,0,1.29,0.46,1.57,1.01l28.37,61.98
			c0.55,1.2-0.18,2.39-1.57,2.39h-8.01c-1.38,0-2.03-0.55-2.67-1.84l-4.51-9.95h-27.54l-4.51,9.95c-0.37,0.92-1.2,1.84-2.67,1.84
			h-8.01C109.91,174.66,109.17,173.46,109.72,172.26z M149.51,152.65l-9.21-20.26h-0.28L131,152.65H149.51z"
          />
          <path
            className={classes.st3}
            d="M179.16,110.92c0-0.92,0.83-1.66,1.75-1.66h2.3l38.31,40.71h0.09v-38.04c0-0.92,0.74-1.75,1.75-1.75h8.47
			c0.92,0,1.75,0.83,1.75,1.75v61.98c0,0.92-0.83,1.66-1.75,1.66h-2.21l-38.5-42.27h-0.09v39.6c0,0.92-0.74,1.75-1.75,1.75h-8.38
			c-0.92,0-1.75-0.83-1.75-1.75V110.92z"
          />
          <path
            className={classes.st3}
            d="M241.98,172.26l28.64-61.98c0.28-0.55,0.74-1.01,1.57-1.01h0.92c0.92,0,1.29,0.46,1.57,1.01l28.37,61.98
			c0.55,1.2-0.18,2.39-1.57,2.39h-8.01c-1.38,0-2.03-0.55-2.67-1.84l-4.51-9.95h-27.54l-4.51,9.95c-0.37,0.92-1.2,1.84-2.67,1.84
			h-8.01C242.16,174.66,241.42,173.46,241.98,172.26z M281.76,152.65l-9.21-20.26h-0.28l-9.03,20.26H281.76z"
          />
          <path
            className={classes.st3}
            d="M309.58,111.94c0-0.92,0.74-1.75,1.66-1.75h21.83c17.78,0,32.33,14.46,32.33,32.14
			c0,17.87-14.55,32.33-32.33,32.33h-21.83c-0.92,0-1.66-0.83-1.66-1.75V111.94z M331.96,163.42c11.97,0,20.72-9.03,20.72-21.09
			c0-11.97-8.75-21-20.72-21h-10.5v42.09H331.96z"
          />
          <path
            className={classes.st3}
            d="M373.86,111.94c0-0.92,0.83-1.75,1.75-1.75h8.57c0.92,0,1.75,0.83,1.75,1.75v60.97
			c0,0.92-0.83,1.75-1.75,1.75h-8.57c-0.92,0-1.75-0.83-1.75-1.75V111.94z"
          />
          <path
            className={classes.st3}
            d="M394.31,172.26l28.64-61.98c0.28-0.55,0.74-1.01,1.57-1.01h0.92c0.92,0,1.29,0.46,1.57,1.01l28.37,61.98
			c0.55,1.2-0.18,2.39-1.57,2.39h-8.01c-1.38,0-2.03-0.55-2.67-1.84l-4.51-9.95h-27.54l-4.51,9.95c-0.37,0.92-1.2,1.84-2.67,1.84
			h-8.01C394.49,174.66,393.76,173.46,394.31,172.26z M434.1,152.65l-9.21-20.26h-0.28l-9.03,20.26H434.1z"
          />
          <path
            className={classes.st3}
            d="M461.91,110.92c0-0.92,0.83-1.66,1.75-1.66h2.3l38.31,40.71h0.09v-38.04c0-0.92,0.74-1.75,1.75-1.75h8.47
			c0.92,0,1.75,0.83,1.75,1.75v61.98c0,0.92-0.83,1.66-1.75,1.66h-2.21l-38.5-42.27h-0.09v39.6c0,0.92-0.74,1.75-1.75,1.75h-8.38
			c-0.92,0-1.75-0.83-1.75-1.75V110.92z"
          />
          <path
            className={classes.st4}
            d="M176.51,187.7c11.16,0,19.23,3.54,26.75,10.28c0.99,0.88,0.99,2.21,0.11,3.09l-6.74,6.96
			c-0.77,0.88-1.88,0.88-2.76,0c-4.64-4.09-10.83-6.52-17.02-6.52c-14.15,0-24.65,11.83-24.65,25.75
			c0,13.82,10.61,25.53,24.76,25.53c6.63,0,12.27-2.54,16.91-6.3c0.88-0.77,2.1-0.66,2.76,0l6.85,7.07
			c0.88,0.77,0.66,2.21-0.11,2.98c-7.52,7.29-17.02,10.72-26.86,10.72c-22.1,0-39.9-17.57-39.9-39.68S154.41,187.7,176.51,187.7z"
          />
          <path
            className={classes.st4}
            d="M243.64,200.77c18.42,0,33.16,14.83,33.16,33.25c0,18.42-14.74,33.06-33.16,33.06s-33.06-14.64-33.06-33.06
			C210.57,215.6,225.22,200.77,243.64,200.77z M243.64,255.11c11.6,0,21.18-9.49,21.18-21.09s-9.58-21.28-21.18-21.28
			s-21.09,9.67-21.09,21.28S232.03,255.11,243.64,255.11z"
          />
          <path
            className={classes.st4}
            d="M277.34,263.77l28.64-61.98c0.28-0.55,0.74-1.01,1.57-1.01h0.92c0.92,0,1.29,0.46,1.57,1.01l28.37,61.98
			c0.55,1.2-0.18,2.39-1.57,2.39h-8.01c-1.38,0-2.03-0.55-2.67-1.84l-4.51-9.95h-27.54l-4.51,9.95c-0.37,0.92-1.2,1.84-2.67,1.84
			h-8.01C277.53,266.17,276.79,264.97,277.34,263.77z M317.13,244.15l-9.21-20.26h-0.28l-9.03,20.26H317.13z"
          />
          <path
            className={classes.st4}
            d="M345.87,257.14l3.32-5.71c0.74-1.29,2.3-1.29,3.13-0.64c0.46,0.28,7.92,5.71,13.91,5.71
			c4.79,0,8.38-3.13,8.38-7.09c0-4.7-3.96-7.92-11.7-11.05c-8.66-3.5-17.31-9.03-17.31-19.89c0-8.2,6.08-17.68,20.72-17.68
			c9.39,0,16.58,4.79,18.42,6.17c0.92,0.55,1.2,2.12,0.55,3.04l-3.5,5.25c-0.74,1.11-2.12,1.84-3.22,1.11
			c-0.74-0.46-7.74-5.07-12.8-5.07c-5.25,0-8.1,3.5-8.1,6.45c0,4.33,3.41,7.28,10.87,10.32c8.93,3.59,19.25,8.93,19.25,20.81
			c0,9.49-8.2,18.24-21.18,18.24c-11.6,0-18.42-5.43-20.26-7.18C345.5,259.07,345.04,258.61,345.87,257.14z"
          />
          <path
            className={classes.st4}
            d="M408.03,212.75h-14.09c-1.01,0-1.75-0.83-1.75-1.75v-7.55c0-0.92,0.74-1.75,1.75-1.75h40.25
			c1.01,0,1.75,0.83,1.75,1.75V211c0,0.92-0.74,1.75-1.75,1.75H420.1v51.67c0,0.92-0.83,1.75-1.75,1.75h-8.57
			c-0.92,0-1.75-0.83-1.75-1.75V212.75z"
          />
          <path
            className={classes.st4}
            d="M431.43,263.77l28.64-61.98c0.28-0.55,0.74-1.01,1.57-1.01h0.92c0.92,0,1.29,0.46,1.57,1.01l28.37,61.98
			c0.55,1.2-0.18,2.39-1.57,2.39h-8.01c-1.38,0-2.03-0.55-2.67-1.84l-4.51-9.95h-27.54l-4.51,9.95c-0.37,0.92-1.2,1.84-2.67,1.84
			h-8.01C431.61,266.17,430.87,264.97,431.43,263.77z M471.21,244.15L462,223.89h-0.28l-9.03,20.26H471.21z"
          />
          <path
            className={classes.st4}
            d="M503.72,203.44c0-0.92,0.74-1.75,1.75-1.75h8.47c0.92,0,1.75,0.83,1.75,1.75v51.67h23.49
			c1.01,0,1.75,0.83,1.75,1.75v7.55c0,0.92-0.74,1.75-1.75,1.75h-33.71c-1.01,0-1.75-0.83-1.75-1.75V203.44z"
          />
        </g>
        <g>
          <g>
            <path
              className={classes.st2}
              d="M681.52,139.8c0.29,1.14,0.55,2.29,0.8,3.44C682.08,142.09,681.81,140.95,681.52,139.8z M684.6,158.8
				C684.6,158.8,684.6,158.81,684.6,158.8c0.49,0.16,0.99,0.33,1.48,0.51C685.59,159.13,685.1,158.96,684.6,158.8z"
            />
            <path
              className={classes.st2}
              d="M684.6,158.8C684.6,158.8,684.6,158.81,684.6,158.8c0.49,0.16,0.99,0.33,1.48,0.51
				C685.59,159.13,685.1,158.96,684.6,158.8z"
            />
            <path
              className={classes.st2}
              d="M755.06,158.29c0,7.9-0.86,15.72-2.54,23.26l-0.1,0.5c-1.78,7.89-4.43,15.51-7.92,22.73
				c-0.2-0.31-0.4-0.61-0.61-0.91c-0.22-0.31-0.43-0.63-0.64-0.94c-0.77-1.11-1.55-2.2-2.35-3.28c-2.22-2.99-4.59-5.87-7.09-8.62
				c-0.3-0.33-0.61-0.66-0.91-0.99c-0.39-0.43-0.79-0.85-1.2-1.27c-0.37-0.39-0.74-0.77-1.12-1.16c-0.43-0.44-0.86-0.87-1.3-1.3
				c-0.46-0.45-0.92-0.91-1.39-1.35c-0.13-0.13-0.27-0.26-0.4-0.38c-0.33-0.31-0.67-0.63-1.01-0.94c-0.21-0.2-0.42-0.39-0.63-0.58
				c-0.4-0.37-0.8-0.73-1.21-1.08c-0.13-0.13-0.26-0.24-0.4-0.36c-0.44-0.38-0.88-0.76-1.32-1.14c-0.1-0.1-0.21-0.19-0.31-0.26
				c-0.38-0.33-0.76-0.65-1.14-0.96c-0.3-0.25-0.6-0.49-0.9-0.73c-0.4-0.33-0.82-0.66-1.23-0.98c-0.53-0.42-1.06-0.83-1.6-1.23
				c-0.17-0.13-0.34-0.26-0.51-0.38c-0.38-0.29-0.77-0.58-1.16-0.85c-0.24-0.19-0.49-0.36-0.74-0.54c-0.42-0.31-0.84-0.61-1.27-0.9
				c-0.05-0.03-0.1-0.07-0.15-0.1c-0.54-0.38-1.07-0.75-1.62-1.11c-0.52-0.35-1.05-0.7-1.58-1.04c-0.52-0.33-1.04-0.67-1.57-1
				c-0.75-0.47-1.52-0.94-2.29-1.39c-0.66-0.39-1.32-0.78-1.99-1.16c-0.66-0.38-1.33-0.75-2.01-1.12c-0.68-0.38-1.37-0.74-2.06-1.1
				c-1.35-0.69-2.71-1.37-4.09-2.01c-0.69-0.33-1.39-0.65-2.08-0.96c-0.7-0.31-1.39-0.61-2.08-0.9c-0.6-0.25-1.2-0.5-1.81-0.75
				c-0.46-0.18-0.92-0.36-1.39-0.54c-0.43-0.17-0.85-0.33-1.28-0.49c-0.66-0.25-1.32-0.49-1.99-0.72c-0.49-0.18-0.98-0.35-1.48-0.51
				c0,0,0-0.01-0.01-0.01c-0.05-0.72-0.11-1.44-0.19-2.15c-0.01-0.09-0.01-0.19-0.03-0.28c-0.13-1.31-0.28-2.62-0.45-3.92
				c0-0.03-0.01-0.07-0.01-0.11c-0.04-0.33-0.09-0.66-0.14-0.98c-0.07-0.5-0.14-1-0.22-1.5c-0.11-0.74-0.24-1.48-0.37-2.22
				c-0.1-0.57-0.2-1.14-0.31-1.71c-0.01-0.1-0.03-0.22-0.06-0.32c-0.15-0.79-0.32-1.58-0.49-2.36c-0.24-1.15-0.52-2.29-0.8-3.44
				c-0.08-0.36-0.18-0.72-0.27-1.08c-0.17-0.66-0.34-1.32-0.53-1.97c-0.17-0.65-0.36-1.3-0.56-1.95c-0.02-0.08-0.04-0.15-0.07-0.23
				c-0.2-0.66-0.4-1.31-0.61-1.97c-0.01-0.05-0.03-0.1-0.05-0.15c-0.13-0.43-0.26-0.84-0.41-1.25c-0.12-0.38-0.25-0.76-0.38-1.14
				c-0.09-0.28-0.19-0.56-0.29-0.83c-0.14-0.43-0.29-0.85-0.45-1.27c-0.15-0.43-0.32-0.86-0.49-1.3c-0.07-0.21-0.15-0.41-0.24-0.61
				c-0.16-0.43-0.33-0.86-0.5-1.28c-0.15-0.39-0.31-0.77-0.47-1.16c-0.06-0.17-0.14-0.34-0.22-0.52c-0.23-0.55-0.47-1.1-0.7-1.65
				c-0.39-0.89-0.79-1.78-1.21-2.66c-0.2-0.44-0.41-0.88-0.63-1.31c-0.2-0.4-0.39-0.82-0.6-1.22c-0.06-0.11-0.1-0.22-0.17-0.33
				c-0.27-0.56-0.56-1.11-0.85-1.66c-0.08-0.17-0.17-0.33-0.26-0.49c-0.24-0.47-0.49-0.93-0.75-1.39c-0.3-0.55-0.61-1.09-0.91-1.64
				c-0.31-0.55-0.63-1.09-0.96-1.63c-0.07-0.13-0.14-0.25-0.22-0.38c-0.29-0.49-0.58-0.97-0.89-1.45c-0.1-0.17-0.21-0.35-0.32-0.52
				c-0.25-0.41-0.52-0.82-0.78-1.23c-0.18-0.29-0.36-0.56-0.55-0.85c-0.18-0.28-0.36-0.55-0.55-0.83c-0.25-0.39-0.52-0.77-0.78-1.16
				c-0.38-0.56-0.78-1.14-1.19-1.69c-0.29-0.41-0.58-0.82-0.87-1.21c-0.61-0.84-1.23-1.67-1.88-2.5c-0.4-0.52-0.8-1.04-1.21-1.55
				c-0.57-0.72-1.16-1.43-1.75-2.14c-0.57-0.68-1.15-1.35-1.74-2.02c-0.42-0.47-0.84-0.95-1.27-1.42c-0.02-0.03-0.05-0.05-0.07-0.08
				c-0.5-0.55-1.02-1.1-1.53-1.65c-0.37-0.4-0.74-0.79-1.12-1.17c-0.24-0.25-0.47-0.49-0.72-0.74c-0.03-0.03-0.06-0.06-0.08-0.08
				c-0.91-0.92-1.83-1.82-2.77-2.7c-0.08-0.08-0.16-0.15-0.24-0.22c-0.53-0.49-1.05-0.98-1.59-1.46c-0.45-0.42-0.91-0.82-1.37-1.22
				c-0.24-0.22-0.48-0.43-0.73-0.63c-0.34-0.3-0.68-0.59-1.03-0.88c-1.78-1.5-3.6-2.94-5.47-4.33c-0.29-0.22-0.6-0.45-0.9-0.66
				c-0.4-0.3-0.81-0.59-1.21-0.88c-0.1-0.07-0.21-0.15-0.31-0.22c-0.41-0.29-0.83-0.59-1.25-0.87c-1.92-1.32-3.9-2.59-5.9-3.79
				c-0.47-0.29-0.96-0.57-1.44-0.85c-0.12-0.07-0.24-0.14-0.36-0.2c-0.4-0.24-0.79-0.46-1.19-0.68c-0.54-0.31-1.09-0.61-1.65-0.91
				c-0.47-0.26-0.93-0.5-1.4-0.75c-1.51-0.79-3.04-1.55-4.59-2.27c-0.66-0.31-1.32-0.62-1.99-0.91c-0.58-0.26-1.16-0.52-1.74-0.76
				c-0.22-0.1-0.44-0.2-0.67-0.29c-0.49-0.22-0.99-0.42-1.48-0.61c-1.45-0.59-2.91-1.14-4.39-1.67c12.65-5.07,26.29-7.71,40-7.71
				C706.91,50.96,755.06,99.11,755.06,158.29z"
            />
          </g>
          <g>
            <path
              className={classes.st3}
              d="M673.42,155.67c0.72,0.16,1.45,0.33,2.17,0.52v-0.01C674.87,156,674.14,155.83,673.42,155.67z
				 M675.55,155.82c0.01,0.12,0.03,0.24,0.03,0.36h0.01C675.58,156.06,675.56,155.94,675.55,155.82z M615.35,157.39
				C615.35,157.39,615.35,157.39,615.35,157.39c0.06-0.43,0.12-0.86,0.17-1.3C615.47,156.52,615.41,156.96,615.35,157.39z"
            />
            <path
              className={classes.st3}
              d="M673.42,155.67c0.72,0.16,1.45,0.33,2.17,0.52v-0.01C674.87,156,674.14,155.83,673.42,155.67z
				 M615.35,157.39C615.35,157.39,615.35,157.39,615.35,157.39c0.06-0.43,0.12-0.86,0.17-1.3
				C615.47,156.52,615.41,156.96,615.35,157.39z"
            />
            <path
              className={classes.st3}
              d="M675.58,156.18c-0.72-0.18-1.44-0.35-2.17-0.51c-0.7-0.16-1.39-0.31-2.1-0.45
				c-0.69-0.15-1.38-0.28-2.07-0.41c-0.01,0-0.03-0.01-0.04-0.01c-0.7-0.13-1.41-0.26-2.11-0.38c-1.26-0.21-2.53-0.4-3.81-0.57
				c-0.33-0.04-0.66-0.08-0.98-0.13c-0.5-0.06-1-0.13-1.51-0.17c-0.06-0.01-0.11-0.02-0.17-0.02c-0.5-0.06-1.01-0.11-1.52-0.16
				c-1.79-0.17-3.59-0.31-5.4-0.4c-0.35-0.02-0.7-0.03-1.05-0.05c-0.45-0.02-0.91-0.03-1.37-0.05c-1.19-0.03-2.38-0.06-3.57-0.06
				c-0.74,0-1.47,0.01-2.21,0.02c-0.25,0.01-0.5,0.01-0.75,0.02c-0.7,0.01-1.39,0.03-2.09,0.07c-0.82,0.03-1.63,0.08-2.45,0.13
				c-0.03,0-0.07,0.01-0.11,0.01c-0.82,0.06-1.64,0.12-2.46,0.19c-0.83,0.07-1.67,0.15-2.5,0.24c-0.79,0.08-1.59,0.17-2.38,0.28
				c-0.13,0.01-0.27,0.03-0.4,0.06c-0.55,0.07-1.09,0.15-1.65,0.23c-0.4,0.06-0.81,0.12-1.21,0.18c-0.15,0.02-0.3,0.04-0.45,0.08
				c-1.89,0.3-3.77,0.65-5.65,1.05c-0.06,0.01-0.12,0.02-0.17,0.03c-0.42,0.09-0.84,0.19-1.27,0.29c-0.43,0.09-0.85,0.19-1.28,0.29
				c-0.21,0.05-0.43,0.1-0.63,0.15c-0.59,0.14-1.16,0.29-1.74,0.44c-0.21,0.06-0.42,0.11-0.63,0.17c-0.49,0.13-0.98,0.26-1.46,0.4
				c-0.3,0.08-0.6,0.17-0.89,0.25c0.06-0.43,0.11-0.86,0.16-1.3c0.36-2.87,0.61-5.74,0.75-8.62c0.03-0.57,0.05-1.14,0.07-1.72
				c0.02-0.68,0.04-1.35,0.05-2.02c0.01-0.68,0.02-1.35,0.02-2.02c0-28.7-10.58-56.28-29.8-77.66c-0.66-0.72-1.32-1.45-2-2.16
				c3.29,0.49,6.57,1.12,9.81,1.91c22.01,5.37,42.19,17.94,56.82,35.41c0.47,0.56,0.93,1.13,1.39,1.69
				c0.45,0.56,0.89,1.12,1.33,1.69c0.02,0.02,0.04,0.05,0.06,0.08c0.43,0.55,0.85,1.12,1.27,1.68c0.38,0.51,0.75,1.02,1.12,1.53
				c0.13,0.17,0.25,0.35,0.38,0.53c0.25,0.36,0.5,0.71,0.75,1.07c0.23,0.33,0.46,0.68,0.69,1.01c0.29,0.43,0.58,0.86,0.86,1.3
				c0.37,0.56,0.73,1.14,1.09,1.71c0.36,0.57,0.7,1.14,1.05,1.71c0.03,0.06,0.07,0.11,0.1,0.17c0.27,0.46,0.54,0.92,0.81,1.38
				c0.53,0.92,1.05,1.85,1.55,2.79c0.25,0.47,0.49,0.94,0.74,1.42c0.33,0.63,0.65,1.27,0.96,1.91c0.31,0.63,0.61,1.27,0.91,1.9
				c0.01,0.03,0.03,0.06,0.05,0.1c0.29,0.62,0.57,1.25,0.84,1.88c0.25,0.56,0.49,1.12,0.73,1.69c0.11,0.26,0.22,0.53,0.33,0.79
				c0.24,0.57,0.47,1.14,0.69,1.71c0.23,0.58,0.45,1.16,0.66,1.74c0.25,0.67,0.49,1.34,0.72,2.01c0.01,0.01,0.01,0.01,0.01,0.01
				c0.21,0.59,0.4,1.17,0.6,1.76c0.88,2.69,1.66,5.42,2.31,8.18c0.08,0.31,0.15,0.63,0.22,0.96c0.11,0.45,0.21,0.89,0.31,1.34
				c0.03,0.16,0.07,0.33,0.1,0.49c0.11,0.5,0.22,1,0.31,1.51c0.04,0.2,0.08,0.38,0.11,0.58c0.08,0.41,0.16,0.82,0.23,1.23
				c0.09,0.48,0.17,0.96,0.25,1.44c0.01,0.08,0.03,0.15,0.03,0.23c0.08,0.49,0.16,0.98,0.23,1.48c0.08,0.45,0.14,0.9,0.2,1.35
				c0.03,0.14,0.04,0.28,0.06,0.43c0.08,0.63,0.16,1.26,0.23,1.9c0.01,0.08,0.02,0.15,0.03,0.23
				C675.56,155.94,675.58,156.06,675.58,156.18z"
            />
          </g>
          <path
            className={classes.st4}
            d="M739.73,213.59L739.73,213.59c-19.38,32.16-54.39,52.03-92,52.03c-8.6,0-17.19-1.02-25.51-3.05
			c-20.95-5.1-39.7-16.32-54.25-32.47c-4.58-5.08-8.69-10.63-12.24-16.52c0.06-0.09,0.1-0.18,0.16-0.26c0.4-0.68,0.82-1.34,1.24-2
			c0.39-0.62,0.79-1.24,1.21-1.85c0.27-0.43,0.56-0.84,0.85-1.26c0.15-0.24,0.32-0.47,0.48-0.7c0.23-0.33,0.46-0.66,0.7-0.98
			c0.2-0.29,0.4-0.58,0.62-0.86c0.08-0.13,0.17-0.24,0.26-0.36c0.14-0.2,0.29-0.4,0.45-0.61c0.01-0.03,0.03-0.05,0.05-0.07
			c0.32-0.43,0.64-0.86,0.98-1.28c0.19-0.25,0.38-0.5,0.58-0.75c0.33-0.42,0.66-0.84,0.99-1.25c0.2-0.24,0.4-0.49,0.59-0.73
			c0.46-0.56,0.92-1.11,1.39-1.65c0.03-0.04,0.06-0.08,0.1-0.12c0.4-0.47,0.82-0.94,1.23-1.4c0.2-0.24,0.42-0.47,0.64-0.71
			c0.36-0.4,0.73-0.8,1.09-1.19c0.75-0.81,1.53-1.61,2.31-2.39c0.28-0.28,0.56-0.56,0.84-0.84c0.29-0.28,0.57-0.56,0.86-0.83
			c0.5-0.49,1.02-0.98,1.53-1.44c0.01-0.01,0.01-0.01,0.02-0.02c0.23-0.22,0.46-0.43,0.7-0.63c0.06-0.06,0.11-0.11,0.17-0.16
			c0.31-0.29,0.63-0.56,0.94-0.84c0.29-0.26,0.59-0.52,0.89-0.77c0.4-0.35,0.8-0.69,1.21-1.02c0.2-0.17,0.4-0.34,0.61-0.51
			c0.19-0.16,0.38-0.31,0.57-0.46l1.19-0.94c9.13-7.23,19.28-12.88,30.17-16.81c11.66-4.2,23.9-6.32,36.38-6.32
			c10.74,0,21.32,1.58,31.43,4.67c21.68,6.64,41.12,20.36,54.72,38.65c0.42,0.56,0.83,1.12,1.23,1.69c0.17,0.23,0.33,0.46,0.49,0.69
			c0.52,0.73,1.02,1.47,1.51,2.22c0.42,0.63,0.83,1.25,1.23,1.89c0.33,0.53,0.67,1.07,1,1.6
			C739.48,213.17,739.6,213.38,739.73,213.59z"
          />
          <g>
            <path
              className={classes.st3}
              d="M602.5,161.95c1.16-0.49,2.34-0.97,3.53-1.42c0-0.01,0-0.01,0-0.01
				C604.85,160.98,603.67,161.46,602.5,161.95z M579.39,175.06C579.39,175.07,579.39,175.07,579.39,175.06
				c0.2-0.14,0.4-0.29,0.61-0.43C579.79,174.77,579.59,174.91,579.39,175.06z"
            />
            <path
              className={classes.st3}
              d="M579.39,175.06C579.39,175.07,579.39,175.07,579.39,175.06c0.2-0.14,0.4-0.29,0.61-0.43
				C579.79,174.77,579.59,174.91,579.39,175.06z M602.5,161.95c1.16-0.49,2.34-0.97,3.53-1.42c0-0.01,0-0.01,0-0.01
				C604.85,160.98,603.67,161.46,602.5,161.95z M579.39,175.06C579.39,175.07,579.39,175.07,579.39,175.06
				c0.2-0.14,0.4-0.29,0.61-0.43C579.79,174.77,579.59,174.91,579.39,175.06z M579.39,175.06
				C579.39,175.07,579.39,175.07,579.39,175.06c0.2-0.14,0.4-0.29,0.61-0.43C579.79,174.77,579.59,174.91,579.39,175.06z"
            />
            <path
              className={classes.st3}
              d="M607.68,141.7c0,0.73-0.01,1.46-0.03,2.18c-0.01,0.75-0.03,1.51-0.07,2.26c0,0.16-0.01,0.33-0.02,0.49
				c0,0.06,0,0.13-0.01,0.19c-0.02,0.52-0.05,1.03-0.08,1.55c-0.01,0.26-0.03,0.51-0.06,0.77c-0.04,0.68-0.09,1.35-0.15,2.02
				c-0.06,0.7-0.13,1.39-0.2,2.08c-0.01,0.15-0.03,0.31-0.05,0.45c-0.08,0.73-0.17,1.45-0.26,2.17c-0.1,0.78-0.22,1.56-0.33,2.34
				c-0.1,0.7-0.22,1.39-0.34,2.09c-0.01,0.08-0.03,0.16-0.04,0.24c-1.19,0.45-2.36,0.93-3.53,1.43c-0.36,0.15-0.72,0.31-1.08,0.47
				c-0.38,0.16-0.75,0.32-1.11,0.49c-0.86,0.38-1.71,0.77-2.57,1.19c-0.52,0.25-1.04,0.5-1.55,0.76c-0.2,0.1-0.4,0.2-0.59,0.29
				c-0.61,0.31-1.21,0.61-1.81,0.93c-1.31,0.69-2.61,1.41-3.9,2.15c-0.6,0.34-1.19,0.69-1.78,1.05c-0.61,0.37-1.23,0.75-1.83,1.13
				c-1.02,0.63-2.03,1.28-3.03,1.96c-0.27,0.18-0.54,0.36-0.81,0.55c-0.82,0.55-1.64,1.12-2.45,1.71c-0.2,0.14-0.4,0.29-0.61,0.43
				c-0.76-1.12-1.51-2.24-2.22-3.38c-0.23-0.36-0.46-0.73-0.68-1.09c-0.22-0.36-0.44-0.71-0.66-1.07c-0.32-0.53-0.63-1.07-0.94-1.6
				c-0.26-0.45-0.52-0.91-0.76-1.36c-0.2-0.36-0.4-0.71-0.59-1.07c-0.01-0.02-0.03-0.04-0.03-0.06c-0.2-0.35-0.38-0.7-0.56-1.06
				c-0.13-0.24-0.26-0.49-0.38-0.74c-0.15-0.29-0.29-0.57-0.43-0.86c-0.84-1.66-1.64-3.34-2.38-5.04c-0.21-0.46-0.4-0.92-0.6-1.39
				c-0.28-0.66-0.55-1.31-0.81-1.97c-0.58-1.44-1.12-2.87-1.62-4.32c-0.15-0.44-0.3-0.87-0.45-1.31c-0.3-0.88-0.58-1.76-0.85-2.64
				c-0.18-0.59-0.36-1.18-0.52-1.77c-0.01-0.01-0.01-0.02-0.01-0.03c-0.15-0.52-0.29-1.04-0.43-1.56c-0.07-0.24-0.14-0.49-0.2-0.73
				c-0.09-0.34-0.18-0.69-0.26-1.04c-0.05-0.15-0.08-0.31-0.12-0.45c-0.11-0.46-0.22-0.92-0.33-1.38c-0.12-0.49-0.23-1-0.33-1.49
				c-0.01-0.05-0.03-0.09-0.03-0.14c-0.11-0.49-0.22-0.98-0.31-1.48c-0.11-0.54-0.22-1.08-0.31-1.62c-0.02-0.08-0.03-0.15-0.04-0.23
				c-0.1-0.56-0.2-1.11-0.29-1.67c-0.07-0.38-0.13-0.77-0.19-1.16c-0.24-1.52-0.45-3.05-0.61-4.58c-0.06-0.44-0.1-0.89-0.15-1.33
				c-0.15-1.43-0.26-2.86-0.34-4.29c-0.03-0.4-0.05-0.8-0.06-1.2c-0.01-0.06-0.01-0.13-0.01-0.2c-0.02-0.34-0.03-0.69-0.05-1.04
				c-0.01-0.06-0.01-0.11-0.01-0.17c-0.01-0.46-0.03-0.92-0.04-1.39c-0.02-0.84-0.03-1.67-0.03-2.52c0-0.98,0.01-1.96,0.04-2.93
				c0.05-1.81,0.14-3.6,0.28-5.4c0.02-0.25,0.03-0.5,0.06-0.75c0.31-3.8,0.84-7.58,1.55-11.31c0.08-0.4,0.16-0.82,0.24-1.23
				c0.03-0.17,0.08-0.34,0.11-0.52l0.26-1.14c2.16-9.79,5.67-19.16,10.45-27.95c0.03,0.03,0.07,0.06,0.1,0.1
				c1.44,1.35,2.84,2.73,4.18,4.14c0.29,0.31,0.58,0.61,0.86,0.91c0.41,0.44,0.82,0.88,1.21,1.32
				C597.9,89.65,607.68,115.16,607.68,141.7z"
            />
          </g>
          <g>
            <path
              className={classes.st2}
              d="M572.52,180.49c-0.08-0.11-0.17-0.23-0.24-0.35c0.08,0.12,0.15,0.23,0.23,0.34
				c-0.15,0.13-0.3,0.26-0.45,0.38C572.21,180.74,572.37,180.61,572.52,180.49z M552.85,202.02c1.02-1.45,2.07-2.87,3.16-4.27
				C554.91,199.14,553.86,200.57,552.85,202.02z"
            />
            <path
              className={classes.st2}
              d="M552.85,202.02c1.02-1.45,2.07-2.87,3.16-4.27C554.91,199.14,553.86,200.57,552.85,202.02z M572.52,180.49
				c-0.08-0.11-0.17-0.23-0.24-0.35c0.08,0.12,0.15,0.23,0.23,0.34c-0.15,0.13-0.3,0.26-0.45,0.38
				C572.21,180.74,572.37,180.61,572.52,180.49z M552.85,202.02c1.02-1.45,2.07-2.87,3.16-4.27
				C554.91,199.14,553.86,200.57,552.85,202.02z M552.85,202.02c1.02-1.45,2.07-2.87,3.16-4.27
				C554.91,199.14,553.86,200.57,552.85,202.02z"
            />
            <path
              className={classes.st2}
              d="M572.51,180.48c-0.15,0.13-0.3,0.26-0.45,0.38c-0.22,0.19-0.43,0.37-0.65,0.56
				c-0.34,0.29-0.68,0.59-1.01,0.89c-0.67,0.59-1.33,1.19-1.99,1.81c-0.29,0.28-0.59,0.55-0.88,0.83c-0.13,0.13-0.26,0.25-0.4,0.39
				c-0.48,0.46-0.96,0.93-1.43,1.4c-0.2,0.19-0.4,0.39-0.59,0.59c-0.03,0.03-0.06,0.06-0.08,0.08c-0.56,0.56-1.11,1.14-1.65,1.71
				c-0.32,0.33-0.64,0.68-0.95,1.01c-0.31,0.34-0.63,0.68-0.93,1.02c-0.3,0.33-0.6,0.67-0.89,1c-0.36,0.4-0.72,0.82-1.07,1.23
				c-0.54,0.64-1.08,1.28-1.62,1.93c-0.52,0.65-1.05,1.3-1.56,1.96c-0.13,0.15-0.24,0.29-0.34,0.44c-1.09,1.39-2.15,2.82-3.16,4.27
				c-0.54,0.76-1.07,1.52-1.58,2.29c-0.1,0.15-0.21,0.3-0.31,0.45c-6.93-14.42-10.57-30.34-10.57-46.47
				c0-17.26,4.03-33.87,11.76-48.89c-0.06,1.21-0.09,2.43-0.1,3.65c-0.01,0.53-0.01,1.06-0.01,1.59c0,2.89,0.1,5.77,0.31,8.62
				c0.01,0.2,0.03,0.4,0.05,0.6c0.01,0.23,0.03,0.46,0.06,0.68c0.03,0.37,0.06,0.74,0.1,1.11c0.03,0.43,0.08,0.87,0.13,1.3
				c0.04,0.49,0.1,0.98,0.16,1.47c0.02,0.2,0.04,0.41,0.08,0.62c0.07,0.61,0.15,1.21,0.24,1.82c0.01,0.09,0.02,0.17,0.03,0.26
				c0.08,0.54,0.15,1.07,0.24,1.6c0.06,0.36,0.11,0.71,0.17,1.06c0.07,0.45,0.15,0.9,0.23,1.34c0.06,0.34,0.11,0.68,0.18,1.02
				c0.06,0.32,0.12,0.65,0.19,0.96c0.1,0.52,0.2,1.05,0.31,1.56c0.07,0.35,0.14,0.7,0.22,1.04c0.12,0.56,0.24,1.11,0.38,1.66
				c0.11,0.5,0.24,1,0.36,1.51c0.36,1.47,0.76,2.93,1.19,4.39c0.08,0.27,0.16,0.54,0.24,0.81c0.01,0.06,0.03,0.13,0.06,0.2
				c0.12,0.41,0.24,0.82,0.37,1.22c0.13,0.42,0.26,0.84,0.4,1.25c0.13,0.4,0.26,0.82,0.4,1.22c0.72,2.15,1.52,4.27,2.38,6.37
				c0.18,0.47,0.38,0.93,0.58,1.4c0.03,0.1,0.08,0.19,0.12,0.29c0.2,0.46,0.39,0.91,0.59,1.37c0.19,0.44,0.38,0.87,0.59,1.3
				c0.17,0.38,0.35,0.77,0.53,1.15c0.2,0.42,0.4,0.84,0.6,1.25c0.2,0.41,0.4,0.82,0.61,1.23c0.29,0.59,0.59,1.18,0.9,1.76
				c0.36,0.68,0.72,1.37,1.09,2.04c0.37,0.68,0.75,1.35,1.13,2.02c0.38,0.68,0.78,1.35,1.18,2.01c0.44,0.75,0.9,1.48,1.37,2.22
				c0.16,0.26,0.33,0.52,0.49,0.77c0.2,0.32,0.4,0.63,0.61,0.95c0.37,0.56,0.75,1.13,1.13,1.69c0.08,0.12,0.15,0.24,0.24,0.35
				C572.35,180.26,572.43,180.37,572.51,180.48z"
            />
          </g>
        </g>
      </SvgIcon>
    </div>
  )
}

const styles = makeStyles(theme => ({
  st0: { stroke: "#231F20", strokeMiterlimit: 10 },
  st1: { stroke: "#231F20", strokeWidth: "0.5517", strokeMiterlimit: 10 },
  st2: { fill: "#66C1B9" },
  st3: { fill: "#084964" },
  st4: { fill: "#0B89A0" },
  st6: { fill: "#231F20" },
  st7: { opacity: "0.18" },
}))

/*
 **  PropTypes
 */
Logo.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default Logo
