/********************************************************************
 *
 * /components/Section.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Container } from "@mui/material"

/**
 * Section
 *
 * Defines a page section container
 *
 * @param {*} param0
 * @returns
 */
const Section = ({
  component = "section",
  sectionRef,
  top = false,
  divider = false,
  backgroundColor,
  maxWidth = false,
  fullWidth = false,
  mb = { xs: 8, md: 8, lg: 10 },
  mt = { xs: 8, md: 8, lg: 10 },
  px = { xs: 4 },
  children,
  sx,
  ...other
}) => (
  <>
    {/* Outer Container 
    
    This encapsulates all real estate for the section. No layout 
    (margin or padding), but can set inheritable properties such 
    as fonts, etc. */}
    <Box
      component={component}
      ref={sectionRef}
      sx={{
        display: "block",
        boxSizing: "border-box",
        backgroundColor: !!backgroundColor ? backgroundColor : "inherit",
        width: "100%",
        margin: 0,
        padding: 0,
      }}
    >
      {/* Baseline Container

        Manages the bottom margin. */}
      <Box
        component="div"
        sx={[
          {
            display: "block",
            boxSizing: "border-box",
            width: "100%",
            margin: { xs: 0 },
            mb: mb,
            padding: 0,
          },
          sx,
        ]}
        {...other}
      >
        {/* Horizontal Adjustment Container
        
        Manages the container width and centers the content. */}
        <Container
          maxWidth={maxWidth}
          component="div"
          sx={[{ px: fullWidth ? { xs: 0 } : px }]}
        >
          {/* Content Container
            
            Contains content. Adds a divier if requested */}
          <Box
            sx={{
              borderTop: !!divider ? "1px solid #EBEBEB" : "none",
              paddingTop: !!divider || !!top ? mt : 0,
            }}
          >
            {children}
          </Box>
        </Container>
      </Box>
    </Box>
  </>
)

/*
 **  PropTypes
 */
Section.propTypes = {
  component: PropTypes.string,
  ref: PropTypes.object,
  top: PropTypes.bool,
  divider: PropTypes.bool,
  children: PropTypes.node,
}

export default Section
