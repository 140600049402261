/********************************************************************
 *
 * /components/ProductListItem.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Link, Grid, Typography } from "@mui/material"
import { Rating } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductListItem = ({ product }) => (
  <Grid container>
    <Grid
      item
      xs={12}
      sm={4}
      sx={{
        position: "relative",
        width: "100%",
        height: { xs: "300px", sm: "200px" },
        overflow: "hidden",
        borderRadius: "5px",
        border: "1px solid #cccccc",
        mb: { xs: 2, sm: 0 },
      }}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        <Link
          href={product.page.url}
          sx={{
            "& .gatsby-image-wrapper": { transition: "transform 1s ease" },
            "& .gatsby-image-wrapper:hover": { transform: "scale(1.1)" },
          }}
        >
          <GatsbyImage
            image={getImage(product.image.img)}
            alt={product.image.alt}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              verticalAlign: "bottom",
            }}
          />
        </Link>
      </Box>
    </Grid>
    <Grid
      item
      xs={12}
      sm={8}
      sx={{
        pl: { xs: 0, sm: 2, md: 4 },
      }}
    >
      <Link href={product.page.url}>
        <Typography variant="body1" color="primary.main" sx={{ pb: 0.75 }}>
          {product.tagLine}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            letterSpacing: 0,
            pb: 0.5,
          }}
        >
          {product.name}
        </Typography>
      </Link>
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          pb: 0.5,
        }}
      >
        {product.time}
      </Typography>
      <Reviews product={product} />
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          pb: 1,
        }}
      >
        {product.category}
      </Typography>
      <Link
        href={product.page.url}
        sx={{ display: { xs: "block", md: "none", lg: "block" } }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "primary.dark",
            pb: 0,
          }}
        >
          {product.description}
        </Typography>
      </Link>
    </Grid>
    <Grid
      item
      md={12}
      sx={{ display: { xs: "none", md: "block", lg: "none" }, pt: 2 }}
    >
      <Link href={product.page.url}>
        <Typography
          variant="body2"
          sx={{
            color: "primary.dark",
            paddingBottom: "0.3em",
          }}
        >
          {product.description}
        </Typography>
      </Link>
    </Grid>
  </Grid>
)

const Reviews = ({ product }) => {
  if (!product) return
  return (
    <Grid container style={{ fontSize: "0.5rem" }}>
      <Rating
        value={parseFloat(product.ratings.average)}
        precision={0.5}
        size="small"
        readOnly
      />
      <Typography variant="body2" style={{ paddingLeft: "5px" }}>
        {product.ratings.average} ({product.ratings.count} reviews)
      </Typography>
    </Grid>
  )
}

/*
 **  PropTypes
 */
ProductListItem.propTypes = {
  product: PropTypes.object,
}

export default ProductListItem
