/********************************************************************
 *
 * /components/trinity/index.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { Box, Typography } from "@mui/material"
import {
  TipsAndUpdatesOutlined,
  SailingOutlined,
  Diversity1Outlined,
} from "@mui/icons-material"
import { Sandwich } from "../index"
import ItemCard from "./ItemCard"

/**
 * TrinityBanner
 *
 * Renders a banner with three cards in a sandwich
 *
 * @param {*} param0
 */
const TrinityBanner = ({ title, items }) => {
  if (!items || items.length < 3) {
    console.error("Trinity Banner must have at least three items")
    return ""
  }

  const icons = [
    <SailingOutlined fontSize="large" color="primary" />,
    <Diversity1Outlined fontSize="large" color="primary" />,
    <TipsAndUpdatesOutlined fontSize="large" color="primary" />,
  ]

  return (
    <Box data-component-id="TRINITY_BANNER">
      {!!title && (
        <Box
          sx={{
            margin: { xs: 0 },
            marginBottom: { xs: 2.5 },
          }}
        >
          <Typography variant="h2">{title}</Typography>
        </Box>
      )}
      <Sandwich sx={{ width: "100%" }}>
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          {items.slice(0, 3).map((item, index) => (
            <ItemCard icon={icons[index]} label={item.title}>
              <Typography
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </ItemCard>
          ))}
        </Box>
      </Sandwich>
    </Box>
  )
}

export default TrinityBanner
