/********************************************************************
 *
 * Week.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { DateTime } from "luxon"
import Day from "./Day"

/**
 * Week
 *
 * Renders a row of seven CalandarDays on the calendar grid.
 */
const Week = ({
  startDate,
  zone,
  activeDate,
  value,
  onDateRender,
  onChanged,
}) => {
  return (
    <Box
      sx={{
        boxSizing: "border-box",
      }}
    >
      {[...Array(7).keys()].map((key, index) => (
        <Day
          key={key}
          date={startDate.plus({ days: index })}
          zone={zone}
          activeDate={activeDate}
          value={value}
          onDateRender={onDateRender}
          onChanged={onChanged}
        />
      ))}
    </Box>
  )
}

/*
 **  Proptypes
 */
Week.propType = {
  startDate: PropTypes.instanceOf(DateTime).isRequired,
  zone: PropTypes.string.isRequired,
  activeDate: PropTypes.instanceOf(DateTime).required,
  value: PropTypes.instanceOf(DateTime),
  onDateRender: PropTypes.func,
  onChanged: PropTypes.func,
}

export default Week
