/********************************************************************
 *
 * /components/category/ProductList.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"
import { Box } from "@mui/material"
import { BannerTitle } from "./index"
import ImageLink from "./ImageLink"
import BentoBox from "./BentoBox"

/**
 * Renders a ProductList component
 *
 * @param {*} param0
 * @returns
 */
const ProductList = ({ title, target, products }) => (
  <Box data-component-id="PRODUCT_LIST">
    <BannerTitle title={title} subtitle={target} />
    <BentoBox>
      {products.map(
        ({ name, target, category, description, image, page }, index) => (
          <ImageLink
            key={index}
            img={getImage(image.img)}
            label={name}
            tag={category}
            target={target}
            description={description}
            href={page.url}
          />
        )
      )}
    </BentoBox>
  </Box>
)

/*
 **  PropTypes
 */
ProductList.propTypes = {
  title: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
}

export default ProductList
