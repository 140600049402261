/********************************************************************
 *
 * /components/Island.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const Island = ({ children }) => (
  <Box
    sx={{
      maxWidth: "800px",
      margin: "0 auto",
    }}
  >
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {children}
    </Box>
  </Box>
)

/*
 **  PropTypes
 */
Island.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Island
