/********************************************************************
 *
 * /components/layout/header/index.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { AppBar, Toolbar, Hidden, Box, Link } from "@mui/material"
import Buttons from "./Buttons"
import MainMenu from "./MainMenu"
import SideMenu from "./SideMenu"
import { Logo } from "../../../icons"

const SHOW_HEADER = 100

/**
 * Header
 *
 * Renders a page header including navigation.
 *
 * @param {*} props
 */
const Header = ({ hide }) => {
  const [menu, setMenu] = useState(false)
  const [visible, setVisible] = useState(!hide)
  const { menuData } = useStaticQuery(graphql`
    query {
      menuData: allMenuJson {
        root: nodes {
          items {
            label
            hide
            path
            children {
              label
              hide
              path
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (hide)
      window.addEventListener("scroll", onScroll, {
        capture: true,
        passive: true,
      })

    return () => {
      //
      //  Removes the event listener
      //
      if (hide) window.removeEventListener("scroll", onScroll)
    }
  }, [hide])

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * ToggleHeader
   *
   * Toggles the visibility of the header
   *
   * @param event
   * @param open
   */
  const toggleHeader = ({ type, key }, open) => {
    if (type === "keydown" && (key === "Tab" || key === "Shift")) {
      return
    }

    setMenu(open)
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Event handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnScroll
   *
   * Event handler for scrolling
   */
  const onScroll = () => {
    setVisible(window.pageYOffset > SHOW_HEADER)
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   * Renders the page header
   */

  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="transparent"
      sx={{
        height: "100px",
        width: "100%",
        "&:before": {
          backgroundImage:
            "-webkit-linear-gradient(to bottom, #000, rgba(0, 0, 0, 0)) !important",
          backgroundImage:
            "-moz-linear-gradient(to bottom, #000, rgba(0, 0, 0, 0)) !important",
          backgroundImage:
            "linear-gradient(to bottom, #000, rgba(0, 0, 0, 0)) !important",
          content: '""',
          height: "140% !important",
          left: "0px !important",
          pointerEvents: "none !important",
          position: "absolute !important",
          top: "0px !important",
          width: "100% !important",
          zIndex: "0 !important",
          transition: "opacity 0.5s linear",
          opacity: visible ? "0 !important" : "0.4 !important",
        },
        "&:after": {
          boxShadow: "rgb(0 0 0 / 8%) 0px 1px 12px !important",
          transition: "background-color 0.5s, opacity 0.5s linear",
          backgroundColor: "rgba(255,255,255,0.95)",
          content: '""',
          height: "100% !important",
          left: "0px !important",
          opacity: "1 !important",
          position: "absolute !important",
          top: "0px !important",
          width: "100% !important",
          zIndex: "0 !important",
          opacity: visible ? "1 !important" : "0 !important",
        },
        "& a": !visible && {
          color: "#FFFFFF !important",
          transition: "color 0.5s linear",
        },
      }}
    >
      <Toolbar
        sx={{
          color: "rgb(103,103,103)",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
          width: "100%",
          paddingLeft: 3,
          paddingRight: 3,
          margin: "0 auto",
          zIndex: 1,
          paddingRight: { sm: 5, lg: 10 },
          opacity: visible ? "1 !important" : "0 !important",
        }}
      >
        <Box>
          <Link href="/" rel="home">
            <Logo height={80} width={205} color={!visible && "#ffffff"} />
          </Link>
        </Box>
        <Hidden lgDown>
          <MainMenu items={menuData.root[0].items} />
        </Hidden>
        <SideMenu
          items={menuData.root[0].items}
          open={menu}
          onClose={event => toggleHeader(event, false)}
        />
        <Buttons toggleMenu={toggleHeader} />
      </Toolbar>
    </AppBar>
  )
}

/*
 **  PropTypes
 */
Header.propTypes = {
  hide: PropTypes.bool,
}

Header.defaultProps = {
  hide: false,
}

export default Header
