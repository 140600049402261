/********************************************************************
 *
 * /components/category/tips/index.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Box, Link } from "@mui/material"
import { People } from "@mui/icons-material"
import { BannerTitle, BannerItemCard } from "../../index"
import TipCouples from "./TipCouples"
import TipFamilies from "./TipFamilies"
import TipFriends from "./TipFriends"
import TipSingles from "./TipSingles"

/**
 * TipBanner
 *
 * Renders a banner containing tips with popup dialogs
 *
 * @param {*} param0
 */
const TipBanner = ({ productName }) => {
  const [open, setOpen] = useState(null)

  return (
    <Box data-component-id="TIPS">
      <BannerTitle title={`Tips for Choosing a ${productName}`} />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gridColumnGap: "16px",
          gridRowGap: "24px",
        }}
      >
        <TipLink
          icon={<People />}
          label="For Couples"
          onClick={() => {
            setOpen("couples")
          }}
        />
        <TipLink
          icon={<People />}
          label="For Families"
          onClick={() => {
            setOpen("families")
          }}
        />
        <TipLink
          icon={<People />}
          label="For Friends"
          onClick={() => {
            setOpen("friends")
          }}
        />
        <TipLink
          icon={<People />}
          label="For Singles"
          onClick={() => {
            setOpen("singles")
          }}
        />
      </Box>
      <TipCouples
        open={open == "couples"}
        onClose={() => {
          setOpen(null)
        }}
      />
      <TipFamilies
        open={open == "families"}
        onClose={() => {
          setOpen(null)
        }}
      />
      <TipFriends
        open={open == "friends"}
        onClose={() => {
          setOpen(null)
        }}
      />
      <TipSingles
        open={open == "singles"}
        onClose={() => {
          setOpen(null)
        }}
      />
    </Box>
  )
}

const TipLink = ({ icon, label, onClick }) => (
  <Box sx={{ gridColumn: { xs: "span 6", md: "span 3", cursor: "pointer" } }}>
    <Link onClick={onClick}>
      <BannerItemCard icon={icon} label={label} />
    </Link>
  </Box>
)

/*
 **  PropTypes
 */
TipBanner.propTypes = {
  productName: PropTypes.string.isRequired,
}

export default TipBanner
