/********************************************************************
 *
 * /components/productCard.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Link, Typography } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ProductCard = ({ product }) => {
  return (
    <Link href={product.page.url} sx={{ display: "block", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          border: "1px solid #cccccc",
          borderRadius: "15px",
          padding: "2em",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            maxHeight: "150px",
            minHeight: "150px",
            minWidth: "150px",
            maxWidth: "150px",
            alignSelf: "center",
            marginRight: { xs: 0, sm: 3 },
            marginBottom: { xs: 3, sm: 0 },
          }}
        >
          <GatsbyImage
            image={getImage(product.image.img)}
            alt={product.image.alt}
            loading="lazy"
            style={{
              position: "relative",
              borderRadius: "50%",
              overflow: "hidden",
              zIndex: 1,
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                paddingBottom: "0.3em",
              }}
            >
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                paddingBottom: "0.3em",
              }}
            >
              {product.category}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "primary.dark",
                paddingBottom: "0.3em",
              }}
            >
              {product.target}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "text.secondary",
                paddingBottom: "0.3em",
              }}
            >
              {product.time}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "secondary.main",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              {product.pricing.tag}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  )
}

/*
 **  PropTypes
 */
ProductCard.propTypes = {
  product: PropTypes.object,
}

export default ProductCard
