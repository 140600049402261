/********************************************************************
 *
 * HTTPError.js
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

export default class HTTPError extends Error {
  constructor(status, message) {
    super(message)
    this.status = status
  }
}
