/********************************************************************
 *
 * /components/splitScreen/ImagePane.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Grid, Box } from "@mui/material"
import { GatsbyImage } from "gatsby-plugin-image"

const ImagePane = ({
  xs,
  sm,
  md,
  lg,
  xl,
  fluid,
  alt,
  radius = "5px",
  fit = "cover",
  mobileHeight = "inherit",
}) => (
  <Grid
    className="SplitScreenImage"
    item
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    sx={[
      {
        position: "relative",
        padding: 0,
        margin: 0,
      },
    ]}
  >
    <Box
      sx={{
        position: { xs: "inherit", md: "absolute" },
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: { xs: mobileHeight, md: "inherit" },
        overflow: "hidden",
        borderRadius: radius,
        zIndex: 1,
      }}
    >
      <GatsbyImage
        image={fluid}
        alt={alt}
        objectFit={fit}
        imgStyle={{ borderRadius: radius }}
        style={{
          borderRadius: radius,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      />
    </Box>
  </Grid>
)

/*
 **  PropTypes
 */
ImagePane.propTypes = {
  fluid: PropTypes.object,
  alt: PropTypes.string,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default ImagePane
