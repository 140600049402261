/********************************************************************
 *
 * /components/SEO/StructuredData/FAQPageSchema.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

const FAQPageSchema = faqs => {
  if (!faqs || !Array.isArray(faqs)) return

  return {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.map(({ q, a }) => ({
      "@type": "Question",
      name: q,
      acceptedAnswer: {
        "@type": "Answer",
        text: a,
      },
    })),
  }
}

export default FAQPageSchema
