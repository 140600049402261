/********************************************************************
 *
 * app/orderProcessing/components/results/InlineContainer.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Typography, Paper, Box } from "@mui/material"
import { Divider } from "../../../../components"

/**
 * InlineContainer
 *
 * Renders the search results
 */
const InlineContainer = ({ show, children }) => {
  //
  //  Otherwise display placeholder / list of products
  //
  return (
    <Box
      sx={{
        padding: "0 0em",
        paddingLeft: { md: 4 },
      }}
    >
      {!show ? <Placeholder /> : children}
    </Box>
  )
}

const Placeholder = () => (
  <>
    <Paper
      elevation={3}
      sx={{
        width: "450px",
        maxWidth: "450px",
        p: 4,
        marginBottom: 1,
      }}
    >
      <Typography
        color="textPrimary"
        variant="h2"
        paragraph
        sx={{ letterSpacing: "0em" }}
      >
        Select a date to check availability
      </Typography>
      <Divider sx={{ py: 1 }} />
      <Typography variant="body2" paragraph>
        Thanks for your interest in Canadian Coastal Sailing!
      </Typography>
      <Typography variant="body2" paragraph>
        We work hard to ensure that your experience with us is memorable, and
        are pround to be one of the top customer-rated activities in British
        Columbia.
      </Typography>
      <Typography variant="body2" paragraph>
        Click the link in the upper right corner of this page if you have any
        questions, special requests or need a hand selecting your trip. We are
        happy to help!
      </Typography>
    </Paper>
  </>
)

/*
 **  PropTypes
 */
InlineContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default InlineContainer
