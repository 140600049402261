/********************************************************************
 *
 * /components/QnA.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material"
import { ExpandMore } from "@mui/icons-material/"

const QnA = ({ q, a, index = Math.floor(Math.random() * 10000), children }) => (
  <Accordion
    key={index}
    elevation={0}
    sx={{
      mx: 0,
      mb: 0,
      mt: 0,
      "&.MuiAccordion-root": { backgroundColor: "transparent" },
      "&.MuiAccordion-root:first-of-type .MuiAccordionSummary-content, &.MuiAccordion-root:first-of-type .MuiAccordionSummary-expandIconWrapper":
        {
          mt: -2,
        },
    }}
  >
    <AccordionSummary
      id={`panel${index}-header`}
      expandIcon={<ExpandMore />}
      aria-controls={`panel${index}-content`}
      sx={[
        {
          padding: 0,
          py: 2,
        },
      ]}
    >
      <Typography
        variant="body1"
        component="h3"
        sx={{
          color: "#222222",
          fontSize: "1.25rem",
        }}
      >
        {q}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {a && <Typography dangerouslySetInnerHTML={{ __html: a }} />}
      {children}
    </AccordionDetails>
  </Accordion>
)

/*
 **  PropTypes
 */
QnA.propTypes = {
  q: PropTypes.string.isRequired,
  a: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
}

export default QnA
