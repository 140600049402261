/********************************************************************
 *
 * /components/splitScreen/TitlePane.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Grid, Typography, Box } from "@mui/material"

/**
 * TitlePane
 *
 * Renders a splitscreen (l/r pane) title pane
 */
const TitlePane = ({
  xs,
  sm,
  md,
  lg,
  xl,
  label,
  variant = "splitScreenTitle",
  children,
}) => (
  <Grid
    className="SplitScreenTitle"
    item
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
  >
    <Box
      sx={{
        maxWidth: { md: "350px" },
        pb: { xs: 3, md: 0 },
      }}
    >
      <Typography variant={variant} component="h2" paragraph>
        {label}
      </Typography>
      {children}
    </Box>
  </Grid>
)

/*
 **  PropTypes
 */
TitlePane.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.element),
  ]),
}

export default TitlePane
