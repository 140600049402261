/********************************************************************
 *
 * /themes/footer.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255,255,255,0.5)",
    },
    text: {
      primary: "rgba(255,255,255,0.5)",
      secondary: "#ffffff",
    },
    background: {
      default: "#1d242a",
    },
  },
  typography: {
    fontFamily: "Quicksand, Helvetica, Arial, sans-serif",
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
})

const FooterThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default FooterThemeProvider
