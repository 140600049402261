/********************************************************************
 *
 * /components/Customerservicepledge/ItemCard.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Card, CardContent, Typography } from "@mui/material"

/**
 * ItemCard
 *
 * Renders an item on the Customer Service Pledge
 *
 * @param {*} param0
 */
const ItemCard = ({ icon, label, children }) => {
  return (
    <Box
      sx={{
        padding: "0 1em 1em 0",
        flexBasis: { sm: "48%" },
      }}
    >
      <Card
        elevation={0}
        sx={{
          color: "#666666",
          padding: 0,
          margin: 0,
          height: "100%",
        }}
      >
        <CardContent>
          {icon}
          <Typography
            variant="h3"
            sx={{
              marginTop: "0.5em",
              marginBottom: "0.5em",
            }}
          >
            {label}
          </Typography>
          {children}
        </CardContent>
      </Card>
    </Box>
  )
}

/*
 **  PropTypes
 */
ItemCard.propTypes = {
  icon: PropTypes.element,
  label: PropTypes.string,
  children: PropTypes.element,
}

export default ItemCard
