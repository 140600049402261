/********************************************************************
 *
 * /components/SKUBanner/index.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Paper, Typography, CircularProgress } from "@mui/material"
import { DateTime } from "luxon"
import { useApp, useCCAPI, useAuthAPI } from "../../providers/AppProvider"
import { useAffiliate } from "../../providers/AffiliateProvider"
import { Divider, Carousel } from "../index"
import SKU from "./SKU"

/**
 * SKUBanner
 *
 * Renders an SKUBanner banner
 *
 * @param {*} param0
 */
const SKUBanner = ({ productTypeIds }) => {
  const [ptSkuTrees, setPtSkuTrees] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const app = useApp()
  const api = useCCAPI()
  const authApi = useAuthAPI()
  const { getAffiliate } = useAffiliate()
  const { SKUMeta } = useStaticQuery(graphql`
    query {
      SKUMeta: allSkusJson {
        nodes {
          items {
            id
            name
            selector
            unit
          }
        }
      }
    }
  `)
  const SKUData = SKUMeta.nodes[0]

  useEffect(() => {
    let start = DateTime.now(),
      end = start.plus({ days: 5 })
    fetchSKUs(start, end)
  }, [])

  /**
   * FetchSKUs
   *
   * Calls the API to fetch the product types' sku trees for the dates
   * visible on the calendar.
   *
   * @param {*} start
   * @param {*} end
   */
  const fetchSKUs = (start, end) => {
    let qs = ""

    return new Promise((resolve, reject) => {
      try {
        if (!productTypeIds) throw new Error("Missing Product Types")
        if (!DateTime.isDateTime(start) && !start.isValid)
          throw new Error("Invalid start date")
        if (!DateTime.isDateTime(end) && !end.isValid)
          throw new Error("Invliad end date")

        if (end < start) throw new Error("End date is before start date")
      } catch (error) {
        reject(error)
        return
      }

      //
      //  Product types are sent in the query string as one or
      //  more pt values. If no pt values are included, use the default
      //
      //  TODO: Add more intelligence to query string parsing and defaults
      //
      productTypeIds.forEach(productTypeId => {
        qs += `${qs.length > 0 ? "&" : ""}pt=${productTypeId}`
      })

      //
      //  Request searches
      //
      api
        .fetch(
          `/api/skus/daterange/${start.toISO()}/${end.toISO()}?${qs}`,
          getAffiliate().id
        )
        .then(({ payload: ptSkuTrees }) => {
          setPtSkuTrees(ptSkuTrees)
          setLoaded(true)
          resolve()
        })
        .catch(error => {
          setLoaded(true)
          reject(error)
          setPtSkuTrees(null)
        })
    })
  }

  const serializeSKUs = ptSKUTrees => {
    if (!ptSKUTrees) return null

    return ptSKUTrees.map(ptSKUTree => {
      let SKUs = []

      Object.values(ptSKUTree.years).forEach(year => {
        if (!year) return null

        Object.values(year).forEach(months => {
          if (!months) return null

          Object.values(months).forEach(month => {
            if (!month) return null

            Object.values(month).forEach(days => {
              if (!days) return null

              Object.values(days).forEach(day => {
                if (!day || !day.nodes) return []
                SKUs = [
                  ...SKUs,
                  ...day.nodes.filter(node => node.available > 0),
                ]
              })
            })
          })
        })
      })

      return SKUs
    })
  }

  let productSkus = null

  if (ptSkuTrees && ptSkuTrees.length > 0)
    productSkus = serializeSKUs(ptSkuTrees)

  if (!loaded) return <CircularProgress />

  return (
    <Box data-component-id="SKU_BANNER">
      <Divider sx={{ pb: 4 }} />
      {!productSkus || productSkus.length == 0 ? (
        <Box sx={{ pb: 4 }}>
          <Paper
            elevation={6}
            sx={{
              py: 4,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h3" component="p" sx={{ pb: 2 }}>
                Sorry, no discounts currently available.
              </Typography>
              <Typography variant="h3" component="p">
                Check back soon for more last minute deals!
              </Typography>
            </Box>
          </Paper>
        </Box>
      ) : (
        productSkus.map(
          (skus, index) =>
            skus &&
            skus.length > 0 && (
              <React.Fragment key={index}>
                {index > 0 && <Divider sx={{ pb: 4 }} />}
                <Box sx={{ pb: 4 }}>
                  <Carousel key={index} label={skus[0].name}>
                    {skus &&
                      skus.map(sku => (
                        <SKU
                          key={sku.id}
                          sku={sku}
                          meta={SKUData.items.find(
                            item =>
                              parseInt(item.id) == parseInt(sku.productTypeId)
                          )}
                        />
                      ))}
                  </Carousel>
                </Box>
              </React.Fragment>
            )
        )
      )}
      <Divider sx={{ pb: 4 }} />
    </Box>
  )
}

export default SKUBanner
