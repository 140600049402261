/********************************************************************
 *
 * /components/Target.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Card, CardContent, CardActions, Typography, Link } from "@mui/material"

const Target = ({ name, img, desc, action, href, onClick }) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      maxWidth: { xs: "400px", md: "300px" },
      "& img": { borderRadius: "4px 4px 0 0" },
    }}
  >
    {img}
    <CardContent
      sx={{
        paddingBottom: 0,
        flexGrow: 2,
      }}
    >
      <Typography
        variant="h3"
        component="h2"
        sx={{ marginTop: "0.5em", marginBottom: "1em" }}
      >
        {name}
      </Typography>
      <Typography variant="body2" component="div" paragraph>
        {desc}
      </Typography>
    </CardContent>
    <CardActions
      sx={{
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "16px",
      }}
    >
      <Link
        href={href}
        onClick={onClick}
        variant="button"
        sx={{ cursor: "pointer" }}
      >
        {action ? action : "Learn More"}
      </Link>
    </CardActions>
  </Card>
)

/*
 **  PropTypes
 */
Target.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  desc: PropTypes.string.isRequired,
  action: PropTypes.string,
  href: PropTypes.string.isRequired,
}

export default Target
