/********************************************************************
 *
 * /icons/Brand.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles } from "@mui/styles"

const Brand = ({ height, width, color, background = false }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.base}
      role="img"
      style={{ color: !!color ? color : undefined }}
    >
      <svg
        className={clsx(!!color && classes.override)}
        width={width}
        height={height}
        fill={!!color ? "currentColor" : undefined}
        viewBox={"0 0 315 315"}
      >
        <g>
          <g>
            <path
              className={clsx(background ? classes.st5 : classes.transparent)}
              d="M79.48,185.14c-0.05,0.03-0.09,0.07-0.14,0.11c-0.02,0.01-0.03,0.03-0.06,0.05
				c-0.09,0.07-0.18,0.14-0.27,0.22l-0.13,0.1c-0.19,0.15-0.37,0.31-0.56,0.46c0.19-0.16,0.38-0.31,0.57-0.46l1.19-0.94
				L79.48,185.14z"
            />
            <path
              className={clsx(background ? classes.st5 : classes.transparent)}
              d="M248.71,213.56c-0.17,0.31-0.34,0.63-0.51,0.93l0.52-0.91L248.71,213.56z"
            />
            <path
              className={clsx(background ? classes.st5 : classes.transparent)}
              d="M146.64,42.23c-18.36,0-36.6,4.38-52.86,12.69c-6.81-1.56-13.76-2.5-20.7-2.81
				c-0.43-0.02-0.84-0.03-1.27-0.05l-2.54-0.08l-1.42,2.32c-0.03,0.05-0.06,0.1-0.09,0.15C61.4,64.95,56.76,76.3,53.99,88.24
				l-0.08,0.31c-15.27,20.24-23.33,44.34-23.33,69.74c0,19.33,4.83,38.38,13.99,55.27c0.2,0.36,0.4,0.73,0.6,1.09
				c0.2,0.37,0.41,0.73,0.62,1.09l4.05,6.53c0,0,6.63,9.4,10.55,13.67c16.03,17.44,35.97,29.5,58.67,35.11
				c8.99,2.22,18.27,3.3,27.57,3.3c39.06,0,75.54-19.83,96.91-52.21c1.37-2.08,2.68-4.2,3.93-6.39v-0.01l0.18-0.32l0.53-0.93
				c0.17-0.31,0.34-0.62,0.51-0.93c5.14-9.43,8.91-19.53,11.24-30.08l0.1-0.48c1.76-8.02,2.65-16.33,2.65-24.71
				C262.7,94.29,210.64,42.23,146.64,42.23z M93.34,63.79c22.01,5.37,42.19,17.94,56.82,35.41c0.47,0.56,0.93,1.13,1.39,1.69
				c0.45,0.56,0.89,1.12,1.33,1.69c0.02,0.02,0.04,0.05,0.06,0.08c0.43,0.55,0.85,1.12,1.27,1.68c0.38,0.51,0.75,1.02,1.12,1.53
				c0.13,0.17,0.25,0.35,0.38,0.53c0.25,0.36,0.5,0.71,0.75,1.07c0.24,0.33,0.46,0.67,0.69,1.01c0.29,0.43,0.58,0.86,0.86,1.3
				c0.37,0.56,0.73,1.14,1.09,1.71c0.36,0.57,0.7,1.14,1.05,1.71c0.03,0.06,0.07,0.11,0.1,0.17c0.27,0.46,0.54,0.92,0.81,1.38
				c0.53,0.92,1.05,1.85,1.55,2.79c0.25,0.47,0.49,0.94,0.74,1.42c0.33,0.63,0.66,1.27,0.96,1.91c0.31,0.63,0.62,1.26,0.91,1.9
				c0.01,0.03,0.03,0.06,0.05,0.1c0.29,0.62,0.57,1.25,0.84,1.88c0.25,0.56,0.49,1.12,0.73,1.69c0.11,0.26,0.22,0.53,0.33,0.79
				c0.24,0.57,0.47,1.14,0.69,1.71c0.23,0.58,0.45,1.16,0.66,1.74c0.25,0.67,0.49,1.34,0.72,2.01c0.01,0.01,0.01,0.01,0.01,0.01
				c0.21,0.59,0.4,1.17,0.6,1.76c0.88,2.69,1.66,5.42,2.31,8.18c0.08,0.31,0.15,0.63,0.22,0.96c0.11,0.45,0.21,0.89,0.31,1.34
				c0.03,0.16,0.07,0.33,0.1,0.49c0.11,0.5,0.22,1,0.31,1.51c0.04,0.2,0.08,0.38,0.11,0.58c0.08,0.41,0.16,0.82,0.23,1.23
				c0.09,0.48,0.17,0.96,0.25,1.44c0.01,0.08,0.03,0.15,0.03,0.23c0.08,0.49,0.16,0.98,0.23,1.48c0.08,0.45,0.14,0.9,0.2,1.35
				c0.03,0.14,0.04,0.28,0.06,0.43c0.08,0.63,0.16,1.26,0.23,1.9c0.01,0.08,0.02,0.15,0.03,0.23c0.01,0.12,0.03,0.24,0.04,0.36
				h-0.01v0.01c-0.72-0.18-1.44-0.36-2.17-0.52c-0.7-0.16-1.39-0.31-2.1-0.45c-0.69-0.15-1.38-0.28-2.07-0.41
				c-0.01,0-0.03-0.01-0.04-0.01c-0.7-0.13-1.41-0.26-2.11-0.38c-1.26-0.21-2.53-0.4-3.81-0.57c-0.33-0.04-0.66-0.08-0.98-0.13
				c-0.5-0.06-1-0.13-1.51-0.17c-0.06-0.01-0.11-0.02-0.17-0.02c-0.5-0.06-1.01-0.11-1.52-0.16c-1.79-0.17-3.59-0.31-5.4-0.4
				c-0.35-0.02-0.7-0.03-1.05-0.05c-0.45-0.02-0.91-0.03-1.37-0.05c-1.19-0.03-2.38-0.06-3.57-0.06c-0.74,0-1.47,0.01-2.21,0.02
				c-0.25,0.01-0.5,0.01-0.75,0.02c-0.7,0.01-1.39,0.03-2.09,0.07c-0.82,0.03-1.63,0.08-2.45,0.13c-0.03,0-0.07,0.01-0.11,0.01
				c-0.82,0.06-1.64,0.12-2.46,0.19c-0.83,0.07-1.67,0.15-2.5,0.24c-0.79,0.08-1.59,0.17-2.38,0.28c-0.13,0.01-0.27,0.03-0.4,0.06
				c-0.55,0.07-1.09,0.15-1.65,0.23c-0.4,0.06-0.81,0.12-1.21,0.18c-0.15,0.02-0.3,0.04-0.45,0.08c-1.89,0.3-3.77,0.65-5.65,1.05
				c-0.06,0.01-0.12,0.02-0.17,0.03c-0.42,0.09-0.84,0.19-1.27,0.29c-0.43,0.09-0.85,0.19-1.28,0.29c-0.21,0.05-0.43,0.1-0.63,0.15
				c-0.59,0.14-1.16,0.29-1.74,0.44c-0.21,0.06-0.42,0.11-0.63,0.17c-0.49,0.13-0.98,0.26-1.46,0.4c-0.3,0.08-0.6,0.17-0.89,0.25
				c-0.01,0-0.01,0-0.01,0c0.06-0.43,0.12-0.87,0.17-1.3c0.36-2.87,0.61-5.74,0.75-8.62c0.03-0.57,0.05-1.14,0.07-1.72
				c0.02-0.68,0.04-1.35,0.05-2.02c0.01-0.68,0.02-1.35,0.02-2.02c0-28.7-10.58-56.28-29.8-77.66c-0.66-0.72-1.32-1.45-2-2.16
				C86.82,62.37,90.11,63,93.34,63.79z M59.72,111.7c0.05-1.81,0.14-3.6,0.28-5.4c0.02-0.25,0.03-0.5,0.06-0.75
				c0.31-3.8,0.84-7.58,1.55-11.31c0.07-0.41,0.15-0.82,0.24-1.23c0.03-0.17,0.08-0.34,0.11-0.52l0.26-1.14
				c2.16-9.79,5.67-19.16,10.45-27.95c0.03,0.03,0.07,0.06,0.1,0.1c1.44,1.35,2.84,2.73,4.18,4.14c0.29,0.31,0.58,0.61,0.86,0.91
				c0.41,0.44,0.82,0.88,1.21,1.32c17.77,19.76,27.55,45.27,27.55,71.81c0,0.73-0.01,1.46-0.03,2.18c-0.01,0.75-0.03,1.51-0.07,2.26
				c0,0.16-0.01,0.33-0.02,0.49c0,0.06,0,0.13-0.01,0.19c-0.02,0.52-0.05,1.03-0.08,1.55c-0.01,0.26-0.03,0.51-0.06,0.77
				c-0.04,0.68-0.09,1.35-0.15,2.02c-0.06,0.7-0.13,1.39-0.2,2.08c-0.01,0.15-0.03,0.31-0.05,0.45c-0.08,0.73-0.17,1.45-0.26,2.17
				c-0.1,0.78-0.21,1.56-0.33,2.34c-0.1,0.7-0.22,1.39-0.34,2.09c-0.01,0.08-0.03,0.16-0.04,0.24c0,0,0,0,0,0.01
				c-1.19,0.45-2.36,0.93-3.53,1.42c-0.36,0.15-0.72,0.31-1.08,0.47c-0.38,0.16-0.75,0.32-1.11,0.49c-0.86,0.38-1.71,0.77-2.57,1.19
				c-0.52,0.25-1.04,0.5-1.55,0.76c-0.2,0.1-0.4,0.2-0.59,0.29c-0.61,0.31-1.21,0.61-1.81,0.93c-1.31,0.69-2.61,1.41-3.9,2.15
				c-0.6,0.34-1.19,0.69-1.78,1.05c-0.61,0.37-1.23,0.75-1.83,1.13c-1.02,0.63-2.03,1.28-3.03,1.96c-0.27,0.18-0.54,0.36-0.81,0.55
				c-0.82,0.55-1.64,1.12-2.45,1.71c-0.2,0.15-0.4,0.29-0.6,0.44c-0.01,0-0.01,0-0.01-0.01c-0.76-1.12-1.51-2.24-2.22-3.38
				c-0.23-0.36-0.46-0.72-0.68-1.09c-0.22-0.36-0.44-0.71-0.66-1.07c-0.32-0.53-0.63-1.07-0.94-1.6c-0.26-0.45-0.52-0.91-0.76-1.36
				c-0.2-0.36-0.4-0.71-0.59-1.07c-0.01-0.02-0.03-0.04-0.03-0.06c-0.2-0.35-0.38-0.7-0.56-1.06c-0.13-0.24-0.26-0.49-0.38-0.74
				c-0.15-0.29-0.29-0.57-0.43-0.86c-0.84-1.66-1.64-3.34-2.38-5.04c-0.21-0.46-0.4-0.92-0.6-1.39c-0.28-0.66-0.55-1.31-0.81-1.97
				c-0.58-1.44-1.12-2.87-1.62-4.32c-0.15-0.44-0.31-0.87-0.45-1.31c-0.3-0.88-0.58-1.76-0.85-2.64c-0.18-0.59-0.36-1.18-0.52-1.77
				c-0.01-0.01-0.01-0.02-0.01-0.03c-0.15-0.52-0.29-1.04-0.43-1.56c-0.07-0.24-0.14-0.49-0.2-0.73c-0.1-0.35-0.18-0.7-0.26-1.04
				c-0.05-0.15-0.08-0.31-0.12-0.45c-0.11-0.46-0.22-0.92-0.33-1.38c-0.12-0.49-0.23-1-0.33-1.49c-0.01-0.05-0.03-0.09-0.03-0.14
				c-0.12-0.49-0.22-0.99-0.31-1.48c-0.12-0.54-0.22-1.08-0.31-1.62c-0.02-0.08-0.03-0.15-0.04-0.23c-0.1-0.56-0.2-1.11-0.29-1.67
				c-0.07-0.38-0.13-0.77-0.19-1.16c-0.24-1.52-0.45-3.05-0.61-4.58c-0.06-0.44-0.1-0.89-0.15-1.33c-0.15-1.43-0.26-2.86-0.34-4.29
				c-0.03-0.4-0.05-0.8-0.06-1.2c-0.01-0.06-0.01-0.13-0.01-0.2c-0.02-0.34-0.03-0.69-0.05-1.04c-0.01-0.06-0.01-0.11-0.01-0.17
				c-0.01-0.46-0.03-0.92-0.04-1.39c-0.02-0.84-0.03-1.67-0.03-2.52C59.68,113.66,59.7,112.68,59.72,111.7z M50.19,204.31
				c-0.1,0.15-0.21,0.3-0.31,0.45c-6.93-14.42-10.57-30.34-10.57-46.47c0-17.26,4.03-33.87,11.76-48.89
				c-0.06,1.21-0.09,2.43-0.1,3.65c-0.01,0.53-0.01,1.06-0.01,1.59c0,2.89,0.1,5.77,0.31,8.62c0.01,0.2,0.03,0.4,0.05,0.6
				c0.01,0.23,0.03,0.46,0.06,0.68c0.03,0.37,0.06,0.74,0.1,1.11c0.03,0.43,0.08,0.87,0.13,1.3c0.04,0.49,0.1,0.98,0.16,1.47
				c0.02,0.2,0.04,0.41,0.08,0.62c0.07,0.61,0.15,1.21,0.24,1.82c0.01,0.09,0.02,0.17,0.03,0.26c0.08,0.54,0.15,1.07,0.24,1.6
				c0.06,0.36,0.11,0.71,0.17,1.06c0.07,0.45,0.15,0.9,0.23,1.34c0.06,0.34,0.11,0.68,0.18,1.02c0.06,0.32,0.12,0.65,0.19,0.96
				c0.1,0.52,0.2,1.05,0.31,1.56c0.07,0.35,0.14,0.7,0.22,1.04c0.12,0.56,0.24,1.11,0.38,1.66c0.11,0.5,0.24,1,0.36,1.51
				c0.36,1.47,0.76,2.93,1.19,4.39c0.08,0.27,0.16,0.54,0.24,0.81c0.01,0.06,0.03,0.13,0.06,0.2c0.12,0.41,0.24,0.82,0.37,1.22
				c0.13,0.42,0.26,0.84,0.4,1.25c0.13,0.4,0.26,0.82,0.4,1.22c0.72,2.15,1.52,4.27,2.38,6.37c0.18,0.47,0.38,0.93,0.58,1.4
				c0.03,0.1,0.08,0.19,0.12,0.29c0.2,0.46,0.39,0.91,0.59,1.37c0.19,0.44,0.38,0.87,0.59,1.3c0.17,0.38,0.35,0.77,0.53,1.15
				c0.2,0.42,0.4,0.84,0.6,1.25c0.2,0.41,0.4,0.82,0.61,1.23c0.29,0.59,0.59,1.18,0.9,1.76c0.36,0.68,0.72,1.37,1.09,2.04
				c0.37,0.68,0.75,1.35,1.13,2.02c0.38,0.68,0.78,1.35,1.18,2.01c0.44,0.75,0.9,1.48,1.37,2.22c0.16,0.26,0.33,0.52,0.49,0.77
				c0.2,0.32,0.4,0.63,0.61,0.95c0.37,0.56,0.75,1.13,1.13,1.69c0.08,0.12,0.15,0.24,0.24,0.35c0.08,0.12,0.16,0.24,0.24,0.35
				c-0.15,0.12-0.31,0.25-0.46,0.38c-0.22,0.19-0.43,0.37-0.65,0.56c-0.34,0.29-0.68,0.59-1.01,0.89c-0.67,0.6-1.33,1.21-1.99,1.81
				c-0.29,0.28-0.59,0.55-0.88,0.83c-0.13,0.13-0.26,0.25-0.4,0.39c-0.48,0.46-0.96,0.93-1.43,1.4c-0.2,0.19-0.4,0.39-0.59,0.59
				c-0.03,0.03-0.06,0.06-0.08,0.08c-0.56,0.56-1.11,1.14-1.65,1.71c-0.32,0.33-0.64,0.68-0.95,1.01c-0.31,0.34-0.63,0.68-0.93,1.02
				c-0.3,0.33-0.6,0.67-0.89,1c-0.36,0.4-0.72,0.82-1.07,1.23c-0.54,0.64-1.08,1.28-1.62,1.93c-0.52,0.65-1.05,1.3-1.56,1.96
				c-0.13,0.15-0.24,0.29-0.34,0.44c-1.09,1.4-2.14,2.82-3.16,4.27C51.23,202.77,50.7,203.53,50.19,204.31z M238.64,213.59
				c-19.38,32.15-54.39,52.03-92,52.03c-8.6,0-17.19-1.02-25.51-3.05c-20.95-5.1-39.7-16.32-54.25-32.47
				c-4.58-5.08-8.69-10.63-12.24-16.52c0.06-0.09,0.1-0.18,0.16-0.26c0.4-0.68,0.82-1.34,1.24-2c0.39-0.62,0.79-1.24,1.21-1.85
				c0.27-0.43,0.56-0.84,0.85-1.26c0.15-0.24,0.32-0.47,0.48-0.7c0.23-0.33,0.46-0.66,0.7-0.98c0.2-0.29,0.4-0.58,0.62-0.86
				c0.08-0.13,0.17-0.24,0.26-0.36c0.14-0.2,0.29-0.4,0.45-0.61c0.01-0.03,0.03-0.05,0.05-0.07c0.32-0.43,0.64-0.86,0.98-1.28
				c0.19-0.25,0.38-0.5,0.58-0.75c0.33-0.42,0.66-0.84,0.99-1.25c0.2-0.24,0.4-0.49,0.59-0.73c0.46-0.56,0.92-1.11,1.39-1.65
				c0.03-0.04,0.06-0.08,0.1-0.12c0.41-0.47,0.82-0.94,1.23-1.4c0.2-0.24,0.42-0.47,0.64-0.71c0.36-0.4,0.73-0.8,1.09-1.19
				c0.75-0.81,1.53-1.61,2.31-2.39c0.28-0.28,0.56-0.56,0.84-0.84c0.29-0.28,0.57-0.56,0.86-0.83c0.5-0.49,1.02-0.98,1.53-1.44
				c0.01-0.01,0.01-0.01,0.02-0.02c0.23-0.22,0.46-0.43,0.7-0.63c0.06-0.06,0.11-0.11,0.17-0.16c0.31-0.29,0.63-0.56,0.94-0.84
				c0.29-0.26,0.59-0.52,0.89-0.77c0.4-0.35,0.79-0.69,1.21-1.02c0.2-0.17,0.4-0.34,0.61-0.51c0.19-0.15,0.37-0.31,0.56-0.46
				l0.13-0.1c0.09-0.08,0.18-0.15,0.27-0.22c0.02-0.02,0.03-0.04,0.06-0.05c0.05-0.04,0.09-0.08,0.14-0.11l0.61-0.47
				c9.13-7.23,19.28-12.88,30.17-16.81c11.66-4.2,23.9-6.32,36.38-6.32c10.74,0,21.32,1.58,31.43,4.67
				c21.68,6.64,41.12,20.36,54.72,38.65c0.42,0.56,0.83,1.12,1.23,1.69c0.17,0.23,0.33,0.46,0.49,0.69
				c0.52,0.73,1.02,1.47,1.51,2.22c0.42,0.62,0.83,1.25,1.23,1.89c0.33,0.53,0.67,1.07,1,1.6
				C238.39,213.17,238.52,213.38,238.64,213.59L238.64,213.59z M251.43,181.55l-0.1,0.5c-1.78,7.89-4.43,15.51-7.92,22.73
				c-0.2-0.31-0.4-0.61-0.61-0.91c-0.22-0.31-0.43-0.63-0.64-0.94c-0.77-1.11-1.55-2.2-2.35-3.28c-2.22-2.99-4.59-5.87-7.09-8.62
				c-0.3-0.33-0.61-0.66-0.91-0.99c-0.39-0.43-0.79-0.85-1.2-1.27c-0.37-0.39-0.74-0.77-1.12-1.16c-0.43-0.44-0.86-0.87-1.3-1.3
				c-0.46-0.45-0.92-0.9-1.39-1.35c-0.13-0.13-0.27-0.26-0.4-0.38c-0.33-0.31-0.67-0.63-1.01-0.94c-0.21-0.2-0.42-0.39-0.63-0.58
				c-0.4-0.37-0.8-0.73-1.21-1.08c-0.13-0.13-0.26-0.24-0.4-0.36c-0.44-0.38-0.88-0.76-1.32-1.14c-0.1-0.1-0.21-0.19-0.31-0.26
				c-0.38-0.33-0.76-0.64-1.14-0.96c-0.3-0.25-0.6-0.49-0.9-0.73c-0.4-0.33-0.82-0.66-1.23-0.98c-0.53-0.42-1.06-0.83-1.6-1.23
				c-0.17-0.13-0.34-0.26-0.51-0.38c-0.38-0.29-0.77-0.57-1.16-0.85c-0.24-0.19-0.49-0.36-0.74-0.54c-0.42-0.31-0.84-0.61-1.27-0.9
				c-0.05-0.03-0.1-0.07-0.15-0.1c-0.54-0.38-1.07-0.75-1.62-1.11c-0.52-0.35-1.05-0.7-1.58-1.04c-0.52-0.33-1.05-0.67-1.57-1
				c-0.75-0.47-1.52-0.94-2.29-1.39c-0.66-0.39-1.32-0.78-1.99-1.16c-0.66-0.38-1.33-0.75-2.01-1.12c-0.68-0.38-1.37-0.74-2.06-1.1
				c-1.35-0.69-2.71-1.37-4.09-2.01c-0.69-0.33-1.39-0.64-2.08-0.96c-0.7-0.31-1.39-0.61-2.08-0.9c-0.6-0.25-1.2-0.5-1.81-0.75
				c-0.46-0.18-0.92-0.36-1.39-0.54c-0.43-0.17-0.85-0.33-1.28-0.49c-0.66-0.24-1.32-0.49-1.99-0.72c-0.49-0.17-0.99-0.35-1.48-0.5
				c0.01,0,0.01-0.01,0.01-0.01s0-0.01-0.01-0.01c-0.06-0.72-0.12-1.44-0.19-2.15c-0.01-0.09-0.01-0.19-0.03-0.28
				c-0.13-1.31-0.28-2.62-0.45-3.92c0-0.03-0.01-0.07-0.01-0.11c-0.04-0.33-0.09-0.66-0.14-0.98c-0.07-0.5-0.15-1-0.22-1.5
				c-0.11-0.74-0.24-1.48-0.37-2.22c-0.1-0.57-0.2-1.14-0.31-1.71c-0.01-0.1-0.03-0.22-0.06-0.32c-0.15-0.79-0.32-1.58-0.49-2.36
				c-0.25-1.15-0.52-2.29-0.8-3.44c-0.08-0.36-0.17-0.72-0.27-1.08c-0.17-0.66-0.34-1.32-0.53-1.97c-0.18-0.66-0.37-1.3-0.56-1.95
				c-0.02-0.08-0.04-0.15-0.07-0.23c-0.2-0.66-0.4-1.31-0.61-1.97c-0.01-0.05-0.03-0.1-0.05-0.15c-0.13-0.42-0.26-0.84-0.41-1.25
				c-0.12-0.38-0.25-0.76-0.38-1.14c-0.09-0.28-0.19-0.56-0.29-0.83c-0.15-0.43-0.29-0.85-0.45-1.27c-0.15-0.43-0.32-0.86-0.49-1.3
				c-0.07-0.21-0.15-0.41-0.24-0.61c-0.16-0.43-0.33-0.86-0.5-1.28c-0.15-0.39-0.31-0.77-0.47-1.16c-0.06-0.17-0.14-0.34-0.22-0.52
				c-0.23-0.55-0.47-1.1-0.7-1.65c-0.39-0.89-0.79-1.78-1.21-2.66c-0.2-0.44-0.41-0.88-0.63-1.31c-0.2-0.41-0.4-0.82-0.6-1.22
				c-0.06-0.11-0.1-0.22-0.17-0.33c-0.28-0.56-0.56-1.11-0.85-1.66c-0.08-0.17-0.17-0.33-0.26-0.49c-0.24-0.47-0.49-0.93-0.75-1.39
				c-0.3-0.55-0.61-1.09-0.91-1.64c-0.31-0.55-0.63-1.09-0.96-1.63c-0.07-0.13-0.14-0.25-0.22-0.38c-0.29-0.49-0.58-0.97-0.89-1.45
				c-0.1-0.17-0.21-0.35-0.32-0.52c-0.25-0.41-0.52-0.82-0.78-1.23c-0.18-0.29-0.36-0.56-0.55-0.85c-0.18-0.28-0.36-0.55-0.55-0.83
				c-0.26-0.39-0.52-0.77-0.78-1.16c-0.38-0.56-0.78-1.14-1.19-1.69c-0.29-0.41-0.58-0.82-0.87-1.21c-0.61-0.84-1.23-1.67-1.88-2.5
				c-0.4-0.52-0.8-1.04-1.21-1.55c-0.57-0.72-1.16-1.43-1.75-2.14c-0.57-0.68-1.15-1.35-1.74-2.02c-0.42-0.47-0.84-0.95-1.27-1.42
				c-0.02-0.03-0.05-0.05-0.07-0.08c-0.5-0.55-1.02-1.1-1.53-1.65c-0.37-0.4-0.74-0.79-1.12-1.17c-0.24-0.25-0.47-0.49-0.72-0.74
				c-0.03-0.03-0.06-0.06-0.08-0.08c-0.91-0.91-1.83-1.82-2.77-2.7c-0.08-0.08-0.16-0.15-0.24-0.22c-0.53-0.49-1.05-0.98-1.59-1.46
				c-0.45-0.42-0.91-0.82-1.37-1.22c-0.24-0.22-0.48-0.43-0.73-0.63c-0.34-0.3-0.68-0.59-1.03-0.88c-1.78-1.5-3.6-2.94-5.47-4.33
				c-0.29-0.22-0.6-0.45-0.9-0.66c-0.4-0.3-0.81-0.59-1.21-0.88c-0.1-0.07-0.21-0.15-0.31-0.22c-0.41-0.29-0.83-0.59-1.25-0.87
				c-1.92-1.32-3.9-2.59-5.9-3.79c-0.47-0.29-0.96-0.57-1.44-0.85c-0.12-0.07-0.24-0.14-0.36-0.2c-0.4-0.24-0.79-0.46-1.19-0.68
				c-0.54-0.31-1.09-0.61-1.65-0.91c-0.47-0.25-0.93-0.5-1.4-0.75c-1.51-0.79-3.04-1.55-4.59-2.27c-0.66-0.31-1.32-0.62-1.99-0.91
				c-0.58-0.26-1.16-0.52-1.74-0.76c-0.22-0.1-0.44-0.2-0.67-0.29c-0.49-0.21-0.99-0.41-1.48-0.61c-1.45-0.59-2.91-1.14-4.39-1.67
				c12.65-5.07,26.29-7.71,40-7.71c59.19,0,107.33,48.15,107.33,107.33C253.97,166.19,253.11,174.01,251.43,181.55z"
            />
          </g>
          <g>
            <path
              className={classes.st2}
              d="M180.44,139.8c0.29,1.14,0.55,2.29,0.8,3.44C181,142.09,180.72,140.95,180.44,139.8z M183.52,158.8
				C183.52,158.8,183.52,158.81,183.52,158.8c0.49,0.16,0.99,0.33,1.48,0.51C184.5,159.13,184.02,158.96,183.52,158.8z"
            />
            <path
              className={classes.st2}
              d="M183.52,158.8C183.52,158.8,183.52,158.81,183.52,158.8c0.49,0.16,0.99,0.33,1.48,0.51
				C184.5,159.13,184.02,158.96,183.52,158.8z"
            />
            <path
              className={classes.st2}
              d="M253.97,158.29c0,7.9-0.86,15.72-2.54,23.26l-0.1,0.5c-1.78,7.89-4.43,15.51-7.92,22.73
				c-0.2-0.31-0.4-0.61-0.61-0.91c-0.22-0.31-0.43-0.63-0.64-0.94c-0.77-1.11-1.55-2.2-2.35-3.28c-2.22-2.99-4.59-5.87-7.09-8.62
				c-0.3-0.33-0.61-0.66-0.91-0.99c-0.39-0.43-0.79-0.85-1.2-1.27c-0.37-0.39-0.74-0.77-1.12-1.16c-0.43-0.44-0.86-0.87-1.3-1.3
				c-0.46-0.45-0.92-0.91-1.39-1.35c-0.13-0.13-0.27-0.26-0.4-0.38c-0.33-0.31-0.67-0.63-1.01-0.94c-0.21-0.2-0.42-0.39-0.63-0.58
				c-0.4-0.37-0.8-0.73-1.21-1.08c-0.13-0.13-0.26-0.24-0.4-0.36c-0.44-0.38-0.88-0.76-1.32-1.14c-0.1-0.1-0.21-0.19-0.31-0.26
				c-0.38-0.33-0.76-0.65-1.14-0.96c-0.3-0.25-0.6-0.49-0.9-0.73c-0.4-0.33-0.82-0.66-1.23-0.98c-0.53-0.42-1.06-0.83-1.6-1.23
				c-0.17-0.13-0.34-0.26-0.51-0.38c-0.38-0.29-0.77-0.58-1.16-0.85c-0.24-0.19-0.49-0.36-0.74-0.54c-0.42-0.31-0.84-0.61-1.27-0.9
				c-0.05-0.03-0.1-0.07-0.15-0.1c-0.54-0.38-1.07-0.75-1.62-1.11c-0.52-0.35-1.05-0.7-1.58-1.04c-0.52-0.33-1.04-0.67-1.57-1
				c-0.75-0.47-1.52-0.94-2.29-1.39c-0.66-0.39-1.32-0.78-1.99-1.16c-0.66-0.38-1.33-0.75-2.01-1.12c-0.68-0.38-1.37-0.74-2.06-1.1
				c-1.35-0.69-2.71-1.37-4.09-2.01c-0.69-0.33-1.39-0.65-2.08-0.96c-0.7-0.31-1.39-0.61-2.08-0.9c-0.6-0.25-1.2-0.5-1.81-0.75
				c-0.46-0.18-0.92-0.36-1.39-0.54c-0.43-0.17-0.85-0.33-1.28-0.49c-0.66-0.25-1.32-0.49-1.99-0.72c-0.49-0.18-0.98-0.35-1.48-0.51
				c0,0,0-0.01-0.01-0.01c-0.05-0.72-0.11-1.44-0.19-2.15c-0.01-0.09-0.01-0.19-0.03-0.28c-0.13-1.31-0.28-2.62-0.45-3.92
				c0-0.03-0.01-0.07-0.01-0.11c-0.04-0.33-0.09-0.66-0.14-0.98c-0.07-0.5-0.14-1-0.22-1.5c-0.11-0.74-0.24-1.48-0.37-2.22
				c-0.1-0.57-0.2-1.14-0.31-1.71c-0.01-0.1-0.03-0.22-0.06-0.32c-0.15-0.79-0.32-1.58-0.49-2.36c-0.24-1.15-0.52-2.29-0.8-3.44
				c-0.08-0.36-0.18-0.72-0.27-1.08c-0.17-0.66-0.34-1.32-0.53-1.97c-0.17-0.65-0.36-1.3-0.56-1.95c-0.02-0.08-0.04-0.15-0.07-0.23
				c-0.2-0.66-0.4-1.31-0.61-1.97c-0.01-0.05-0.03-0.1-0.05-0.15c-0.13-0.43-0.26-0.84-0.41-1.25c-0.12-0.38-0.25-0.76-0.38-1.14
				c-0.09-0.28-0.19-0.56-0.29-0.83c-0.14-0.43-0.29-0.85-0.45-1.27c-0.15-0.43-0.32-0.86-0.49-1.3c-0.07-0.21-0.15-0.41-0.24-0.61
				c-0.16-0.43-0.33-0.86-0.5-1.28c-0.15-0.39-0.31-0.77-0.47-1.16c-0.06-0.17-0.14-0.34-0.22-0.52c-0.23-0.55-0.47-1.1-0.7-1.65
				c-0.39-0.89-0.79-1.78-1.21-2.66c-0.2-0.44-0.41-0.88-0.63-1.31c-0.2-0.4-0.39-0.82-0.6-1.22c-0.06-0.11-0.1-0.22-0.17-0.33
				c-0.27-0.56-0.56-1.11-0.85-1.66c-0.08-0.17-0.17-0.33-0.26-0.49c-0.24-0.47-0.49-0.93-0.75-1.39c-0.3-0.55-0.61-1.09-0.91-1.64
				c-0.31-0.55-0.63-1.09-0.96-1.63c-0.07-0.13-0.14-0.25-0.22-0.38c-0.29-0.49-0.58-0.97-0.89-1.45c-0.1-0.17-0.21-0.35-0.32-0.52
				c-0.25-0.41-0.52-0.82-0.78-1.23c-0.18-0.29-0.36-0.56-0.55-0.85c-0.18-0.28-0.36-0.55-0.55-0.83c-0.25-0.39-0.52-0.77-0.78-1.16
				c-0.38-0.56-0.78-1.14-1.19-1.69c-0.29-0.41-0.58-0.82-0.87-1.21c-0.61-0.84-1.23-1.67-1.88-2.5c-0.4-0.52-0.8-1.04-1.21-1.55
				c-0.57-0.72-1.16-1.43-1.75-2.14c-0.57-0.68-1.15-1.35-1.74-2.02c-0.42-0.47-0.84-0.95-1.27-1.42c-0.02-0.03-0.05-0.05-0.07-0.08
				c-0.5-0.55-1.02-1.1-1.53-1.65c-0.37-0.4-0.74-0.79-1.12-1.17c-0.24-0.25-0.47-0.49-0.72-0.74c-0.03-0.03-0.06-0.06-0.08-0.08
				c-0.91-0.92-1.83-1.82-2.77-2.7c-0.08-0.08-0.16-0.15-0.24-0.22c-0.53-0.49-1.05-0.98-1.59-1.46c-0.45-0.42-0.91-0.82-1.37-1.22
				c-0.24-0.22-0.48-0.43-0.73-0.63c-0.34-0.3-0.68-0.59-1.03-0.88c-1.78-1.5-3.6-2.94-5.47-4.33c-0.29-0.22-0.6-0.45-0.9-0.66
				c-0.4-0.3-0.81-0.59-1.21-0.88c-0.1-0.07-0.21-0.15-0.31-0.22c-0.41-0.29-0.83-0.59-1.25-0.87c-1.92-1.32-3.9-2.59-5.9-3.79
				c-0.47-0.29-0.96-0.57-1.44-0.85c-0.12-0.07-0.24-0.14-0.36-0.2c-0.4-0.24-0.79-0.46-1.19-0.68c-0.54-0.31-1.09-0.61-1.65-0.91
				c-0.47-0.26-0.93-0.5-1.4-0.75c-1.51-0.79-3.04-1.55-4.59-2.27c-0.66-0.31-1.32-0.62-1.99-0.91c-0.58-0.26-1.16-0.52-1.74-0.76
				c-0.22-0.1-0.44-0.2-0.67-0.29c-0.49-0.22-0.99-0.42-1.48-0.61c-1.45-0.59-2.91-1.14-4.39-1.67c12.65-5.07,26.29-7.71,40-7.71
				C205.83,50.96,253.97,99.11,253.97,158.29z"
            />
          </g>
          <g>
            <path
              className={classes.st3}
              d="M172.33,155.67c0.73,0.16,1.45,0.33,2.17,0.52v-0.01C173.78,156,173.06,155.83,172.33,155.67z
				 M174.46,155.82c0.01,0.12,0.03,0.24,0.03,0.36h0.01C174.49,156.06,174.48,155.94,174.46,155.82z M114.27,157.39
				C114.27,157.39,114.27,157.39,114.27,157.39c0.06-0.43,0.12-0.86,0.17-1.3C114.39,156.52,114.33,156.96,114.27,157.39z"
            />
            <path
              className={classes.st3}
              d="M172.33,155.67c0.73,0.16,1.45,0.33,2.17,0.52v-0.01C173.78,156,173.06,155.83,172.33,155.67z
				 M114.27,157.39C114.27,157.39,114.27,157.39,114.27,157.39c0.06-0.43,0.12-0.86,0.17-1.3
				C114.39,156.52,114.33,156.96,114.27,157.39z"
            />
            <path
              className={classes.st3}
              d="M174.5,156.18c-0.72-0.18-1.44-0.35-2.17-0.51c-0.7-0.16-1.39-0.31-2.1-0.45c-0.69-0.15-1.38-0.28-2.07-0.41
				c-0.01,0-0.03-0.01-0.04-0.01c-0.7-0.13-1.41-0.26-2.11-0.38c-1.26-0.21-2.53-0.4-3.81-0.57c-0.33-0.04-0.66-0.08-0.98-0.13
				c-0.5-0.06-1-0.13-1.51-0.17c-0.06-0.01-0.11-0.02-0.17-0.02c-0.5-0.06-1.01-0.11-1.52-0.16c-1.79-0.17-3.59-0.31-5.4-0.4
				c-0.35-0.02-0.7-0.03-1.05-0.05c-0.45-0.02-0.91-0.03-1.37-0.05c-1.19-0.03-2.38-0.06-3.57-0.06c-0.74,0-1.47,0.01-2.21,0.02
				c-0.25,0.01-0.5,0.01-0.75,0.02c-0.7,0.01-1.39,0.03-2.09,0.07c-0.82,0.03-1.63,0.08-2.45,0.13c-0.03,0-0.07,0.01-0.11,0.01
				c-0.82,0.06-1.64,0.12-2.46,0.19c-0.83,0.07-1.67,0.15-2.5,0.24c-0.79,0.08-1.59,0.17-2.38,0.28c-0.13,0.01-0.27,0.03-0.4,0.06
				c-0.55,0.07-1.09,0.15-1.65,0.23c-0.4,0.06-0.81,0.12-1.21,0.18c-0.15,0.02-0.3,0.04-0.45,0.08c-1.89,0.3-3.77,0.65-5.65,1.05
				c-0.06,0.01-0.12,0.02-0.17,0.03c-0.42,0.09-0.84,0.19-1.27,0.29c-0.43,0.09-0.85,0.19-1.28,0.29c-0.21,0.05-0.43,0.1-0.63,0.15
				c-0.59,0.14-1.16,0.29-1.74,0.44c-0.21,0.06-0.42,0.11-0.63,0.17c-0.49,0.13-0.98,0.26-1.46,0.4c-0.3,0.08-0.6,0.17-0.89,0.25
				c0.06-0.43,0.11-0.86,0.16-1.3c0.36-2.87,0.61-5.74,0.75-8.62c0.03-0.57,0.05-1.14,0.07-1.72c0.02-0.68,0.04-1.35,0.05-2.02
				c0.01-0.68,0.02-1.35,0.02-2.02c0-28.7-10.58-56.28-29.8-77.66c-0.66-0.72-1.32-1.45-2-2.16c3.29,0.49,6.57,1.12,9.81,1.91
				c22.01,5.37,42.19,17.94,56.82,35.41c0.47,0.56,0.93,1.13,1.39,1.69c0.45,0.56,0.89,1.12,1.33,1.69
				c0.02,0.02,0.04,0.05,0.06,0.08c0.43,0.55,0.85,1.12,1.27,1.68c0.38,0.51,0.75,1.02,1.12,1.53c0.13,0.17,0.25,0.35,0.38,0.53
				c0.25,0.36,0.5,0.71,0.75,1.07c0.23,0.33,0.46,0.68,0.69,1.01c0.29,0.43,0.58,0.86,0.86,1.3c0.37,0.56,0.73,1.14,1.09,1.71
				c0.36,0.57,0.7,1.14,1.05,1.71c0.03,0.06,0.07,0.11,0.1,0.17c0.27,0.46,0.54,0.92,0.81,1.38c0.53,0.92,1.05,1.85,1.55,2.79
				c0.25,0.47,0.49,0.94,0.74,1.42c0.33,0.63,0.65,1.27,0.96,1.91c0.31,0.63,0.61,1.27,0.91,1.9c0.01,0.03,0.03,0.06,0.05,0.1
				c0.29,0.62,0.57,1.25,0.84,1.88c0.25,0.56,0.49,1.12,0.73,1.69c0.11,0.26,0.22,0.53,0.33,0.79c0.24,0.57,0.47,1.14,0.69,1.71
				c0.23,0.58,0.45,1.16,0.66,1.74c0.25,0.67,0.49,1.34,0.72,2.01c0.01,0.01,0.01,0.01,0.01,0.01c0.21,0.59,0.4,1.17,0.6,1.76
				c0.88,2.69,1.66,5.42,2.31,8.18c0.08,0.31,0.15,0.63,0.22,0.96c0.11,0.45,0.21,0.89,0.31,1.34c0.03,0.16,0.07,0.33,0.1,0.49
				c0.11,0.5,0.22,1,0.31,1.51c0.04,0.2,0.08,0.38,0.11,0.58c0.08,0.41,0.16,0.82,0.23,1.23c0.09,0.48,0.17,0.96,0.25,1.44
				c0.01,0.08,0.03,0.15,0.03,0.23c0.08,0.49,0.16,0.98,0.23,1.48c0.08,0.45,0.14,0.9,0.2,1.35c0.03,0.14,0.04,0.28,0.06,0.43
				c0.08,0.63,0.16,1.26,0.23,1.9c0.01,0.08,0.02,0.15,0.03,0.23C174.48,155.94,174.49,156.06,174.5,156.18z"
            />
          </g>
          <path
            className={classes.st4}
            d="M238.64,213.59L238.64,213.59c-19.38,32.16-54.39,52.03-92,52.03c-8.6,0-17.19-1.02-25.51-3.05
			c-20.95-5.1-39.7-16.32-54.25-32.47c-4.58-5.08-8.69-10.63-12.24-16.52c0.06-0.09,0.1-0.18,0.16-0.26c0.4-0.68,0.82-1.34,1.24-2
			c0.39-0.62,0.79-1.24,1.21-1.85c0.27-0.43,0.56-0.84,0.85-1.26c0.15-0.24,0.32-0.47,0.48-0.7c0.23-0.33,0.46-0.66,0.7-0.98
			c0.2-0.29,0.4-0.58,0.62-0.86c0.08-0.13,0.17-0.24,0.26-0.36c0.14-0.2,0.29-0.4,0.45-0.61c0.01-0.03,0.03-0.05,0.05-0.07
			c0.32-0.43,0.64-0.86,0.98-1.28c0.19-0.25,0.38-0.5,0.58-0.75c0.33-0.42,0.66-0.84,0.99-1.25c0.2-0.24,0.4-0.49,0.59-0.73
			c0.46-0.56,0.92-1.11,1.39-1.65c0.03-0.04,0.06-0.08,0.1-0.12c0.4-0.47,0.82-0.94,1.23-1.4c0.2-0.24,0.42-0.47,0.64-0.71
			c0.36-0.4,0.73-0.8,1.09-1.19c0.75-0.81,1.53-1.61,2.31-2.39c0.28-0.28,0.56-0.56,0.84-0.84c0.29-0.28,0.57-0.56,0.86-0.83
			c0.5-0.49,1.02-0.98,1.53-1.44c0.01-0.01,0.01-0.01,0.02-0.02c0.23-0.22,0.46-0.43,0.7-0.63c0.06-0.06,0.11-0.11,0.17-0.16
			c0.31-0.29,0.63-0.56,0.94-0.84c0.29-0.26,0.59-0.52,0.89-0.77c0.4-0.35,0.8-0.69,1.21-1.02c0.2-0.17,0.4-0.34,0.61-0.51
			c0.19-0.16,0.38-0.31,0.57-0.46l1.19-0.94c9.13-7.23,19.28-12.88,30.17-16.81c11.66-4.2,23.9-6.32,36.38-6.32
			c10.74,0,21.32,1.58,31.43,4.67c21.68,6.64,41.12,20.36,54.72,38.65c0.42,0.56,0.83,1.12,1.23,1.69c0.17,0.23,0.33,0.46,0.49,0.69
			c0.52,0.73,1.02,1.47,1.51,2.22c0.42,0.63,0.83,1.25,1.23,1.89c0.33,0.53,0.67,1.07,1,1.6
			C238.39,213.17,238.52,213.38,238.64,213.59z"
          />
          <g>
            <path
              className={classes.st3}
              d="M101.42,161.95c1.16-0.49,2.34-0.97,3.53-1.42c0-0.01,0-0.01,0-0.01
				C103.76,160.98,102.58,161.46,101.42,161.95z M78.3,175.06C78.3,175.07,78.3,175.07,78.3,175.06c0.2-0.14,0.4-0.29,0.61-0.43
				C78.71,174.77,78.51,174.91,78.3,175.06z"
            />
            <path
              className={classes.st3}
              d="M78.3,175.06C78.3,175.07,78.3,175.07,78.3,175.06c0.2-0.14,0.4-0.29,0.61-0.43
				C78.71,174.77,78.51,174.91,78.3,175.06z M101.42,161.95c1.16-0.49,2.34-0.97,3.53-1.42c0-0.01,0-0.01,0-0.01
				C103.76,160.98,102.58,161.46,101.42,161.95z M78.3,175.06C78.3,175.07,78.3,175.07,78.3,175.06c0.2-0.14,0.4-0.29,0.61-0.43
				C78.71,174.77,78.51,174.91,78.3,175.06z M78.3,175.06C78.3,175.07,78.3,175.07,78.3,175.06c0.2-0.14,0.4-0.29,0.61-0.43
				C78.71,174.77,78.51,174.91,78.3,175.06z"
            />
            <path
              className={classes.st3}
              d="M106.6,141.7c0,0.73-0.01,1.46-0.03,2.18c-0.01,0.75-0.03,1.51-0.07,2.26c0,0.16-0.01,0.33-0.02,0.49
				c0,0.06,0,0.13-0.01,0.19c-0.02,0.52-0.05,1.03-0.08,1.55c-0.01,0.26-0.03,0.51-0.06,0.77c-0.04,0.68-0.09,1.35-0.15,2.02
				c-0.06,0.7-0.13,1.39-0.2,2.08c-0.01,0.15-0.03,0.31-0.05,0.45c-0.08,0.73-0.17,1.45-0.26,2.17c-0.1,0.78-0.22,1.56-0.33,2.34
				c-0.1,0.7-0.22,1.39-0.34,2.09c-0.01,0.08-0.03,0.16-0.04,0.24c-1.19,0.45-2.36,0.93-3.53,1.43c-0.36,0.15-0.72,0.31-1.08,0.47
				c-0.38,0.16-0.75,0.32-1.11,0.49c-0.86,0.38-1.71,0.77-2.57,1.19c-0.52,0.25-1.04,0.5-1.55,0.76c-0.2,0.1-0.4,0.2-0.59,0.29
				c-0.61,0.31-1.21,0.61-1.81,0.93c-1.31,0.69-2.61,1.41-3.9,2.15c-0.6,0.34-1.19,0.69-1.78,1.05c-0.61,0.37-1.23,0.75-1.83,1.13
				c-1.02,0.63-2.03,1.28-3.03,1.96c-0.27,0.18-0.54,0.36-0.81,0.55c-0.82,0.55-1.64,1.12-2.45,1.71c-0.2,0.14-0.4,0.29-0.61,0.43
				c-0.76-1.12-1.51-2.24-2.22-3.38c-0.23-0.36-0.46-0.73-0.68-1.09c-0.22-0.36-0.44-0.71-0.66-1.07c-0.32-0.53-0.63-1.07-0.94-1.6
				c-0.26-0.45-0.52-0.91-0.76-1.36c-0.2-0.36-0.4-0.71-0.59-1.07c-0.01-0.02-0.03-0.04-0.03-0.06c-0.2-0.35-0.38-0.7-0.56-1.06
				c-0.13-0.24-0.26-0.49-0.38-0.74c-0.15-0.29-0.29-0.57-0.43-0.86c-0.84-1.66-1.64-3.34-2.38-5.04c-0.21-0.46-0.4-0.92-0.6-1.39
				c-0.28-0.66-0.55-1.31-0.81-1.97c-0.58-1.44-1.12-2.87-1.62-4.32c-0.15-0.44-0.3-0.87-0.45-1.31c-0.3-0.88-0.58-1.76-0.85-2.64
				c-0.18-0.59-0.36-1.18-0.52-1.77c-0.01-0.01-0.01-0.02-0.01-0.03c-0.15-0.52-0.29-1.04-0.43-1.56c-0.07-0.24-0.14-0.49-0.2-0.73
				c-0.09-0.34-0.18-0.69-0.26-1.04c-0.05-0.15-0.08-0.31-0.12-0.45c-0.11-0.46-0.22-0.92-0.33-1.38c-0.12-0.49-0.23-1-0.33-1.49
				c-0.01-0.05-0.03-0.09-0.03-0.14c-0.11-0.49-0.22-0.98-0.31-1.48c-0.11-0.54-0.22-1.08-0.31-1.62c-0.02-0.08-0.03-0.15-0.04-0.23
				c-0.1-0.56-0.2-1.11-0.29-1.67c-0.07-0.38-0.13-0.77-0.19-1.16c-0.24-1.52-0.45-3.05-0.61-4.58c-0.06-0.44-0.1-0.89-0.15-1.33
				c-0.15-1.43-0.26-2.86-0.34-4.29c-0.03-0.4-0.05-0.8-0.06-1.2c-0.01-0.06-0.01-0.13-0.01-0.2c-0.02-0.34-0.03-0.69-0.05-1.04
				c-0.01-0.06-0.01-0.11-0.01-0.17c-0.01-0.46-0.03-0.92-0.04-1.39c-0.02-0.84-0.03-1.67-0.03-2.52c0-0.98,0.01-1.96,0.04-2.93
				c0.05-1.81,0.14-3.6,0.28-5.4c0.02-0.25,0.03-0.5,0.06-0.75c0.31-3.8,0.84-7.58,1.55-11.31c0.08-0.4,0.16-0.82,0.24-1.23
				c0.03-0.17,0.08-0.34,0.11-0.52l0.26-1.14c2.16-9.79,5.67-19.16,10.45-27.95c0.03,0.03,0.07,0.06,0.1,0.1
				c1.44,1.35,2.84,2.73,4.18,4.14c0.29,0.31,0.58,0.61,0.86,0.91c0.41,0.44,0.82,0.88,1.21,1.32
				C96.82,89.65,106.6,115.16,106.6,141.7z"
            />
          </g>
          <g>
            <path
              className={classes.st2}
              d="M71.44,180.49c-0.08-0.11-0.17-0.23-0.24-0.35c0.08,0.12,0.15,0.23,0.23,0.34c-0.15,0.13-0.3,0.26-0.45,0.38
				C71.13,180.74,71.28,180.61,71.44,180.49z M51.76,202.02c1.02-1.45,2.07-2.87,3.16-4.27C53.83,199.14,52.77,200.57,51.76,202.02z
				"
            />
            <path
              className={classes.st2}
              d="M51.76,202.02c1.02-1.45,2.07-2.87,3.16-4.27C53.83,199.14,52.77,200.57,51.76,202.02z M71.44,180.49
				c-0.08-0.11-0.17-0.23-0.24-0.35c0.08,0.12,0.15,0.23,0.23,0.34c-0.15,0.13-0.3,0.26-0.45,0.38
				C71.13,180.74,71.28,180.61,71.44,180.49z M51.76,202.02c1.02-1.45,2.07-2.87,3.16-4.27C53.83,199.14,52.77,200.57,51.76,202.02z
				 M51.76,202.02c1.02-1.45,2.07-2.87,3.16-4.27C53.83,199.14,52.77,200.57,51.76,202.02z"
            />
            <path
              className={classes.st2}
              d="M71.42,180.48c-0.15,0.13-0.3,0.26-0.45,0.38c-0.22,0.19-0.43,0.37-0.65,0.56
				c-0.34,0.29-0.68,0.59-1.01,0.89c-0.67,0.59-1.33,1.19-1.99,1.81c-0.29,0.28-0.59,0.55-0.88,0.83c-0.13,0.13-0.26,0.25-0.4,0.39
				c-0.48,0.46-0.96,0.93-1.43,1.4c-0.2,0.19-0.4,0.39-0.59,0.59c-0.03,0.03-0.06,0.06-0.08,0.08c-0.56,0.56-1.11,1.14-1.65,1.71
				c-0.32,0.33-0.64,0.68-0.95,1.01c-0.31,0.34-0.63,0.68-0.93,1.02c-0.3,0.33-0.6,0.67-0.89,1c-0.36,0.4-0.72,0.82-1.07,1.23
				c-0.54,0.64-1.08,1.28-1.62,1.93c-0.52,0.65-1.05,1.3-1.56,1.96c-0.13,0.15-0.24,0.29-0.34,0.44c-1.09,1.39-2.15,2.82-3.16,4.27
				c-0.54,0.76-1.07,1.52-1.58,2.29c-0.1,0.15-0.21,0.3-0.31,0.45c-6.93-14.42-10.57-30.34-10.57-46.47
				c0-17.26,4.03-33.87,11.76-48.89c-0.06,1.21-0.09,2.43-0.1,3.65c-0.01,0.53-0.01,1.06-0.01,1.59c0,2.89,0.1,5.77,0.31,8.62
				c0.01,0.2,0.03,0.4,0.05,0.6c0.01,0.23,0.03,0.46,0.06,0.68c0.03,0.37,0.06,0.74,0.1,1.11c0.03,0.43,0.08,0.87,0.13,1.3
				c0.04,0.49,0.1,0.98,0.16,1.47c0.02,0.2,0.04,0.41,0.08,0.62c0.07,0.61,0.15,1.21,0.24,1.82c0.01,0.09,0.02,0.17,0.03,0.26
				c0.08,0.54,0.15,1.07,0.24,1.6c0.06,0.36,0.11,0.71,0.17,1.06c0.07,0.45,0.15,0.9,0.23,1.34c0.06,0.34,0.11,0.68,0.18,1.02
				c0.06,0.32,0.12,0.65,0.19,0.96c0.1,0.52,0.2,1.05,0.31,1.56c0.07,0.35,0.14,0.7,0.22,1.04c0.12,0.56,0.24,1.11,0.38,1.66
				c0.11,0.5,0.24,1,0.36,1.51c0.36,1.47,0.76,2.93,1.19,4.39c0.08,0.27,0.16,0.54,0.24,0.81c0.01,0.06,0.03,0.13,0.06,0.2
				c0.12,0.41,0.24,0.82,0.37,1.22c0.13,0.42,0.26,0.84,0.4,1.25c0.13,0.4,0.26,0.82,0.4,1.22c0.72,2.15,1.52,4.27,2.38,6.37
				c0.18,0.47,0.38,0.93,0.58,1.4c0.03,0.1,0.08,0.19,0.12,0.29c0.2,0.46,0.39,0.91,0.59,1.37c0.19,0.44,0.38,0.87,0.59,1.3
				c0.17,0.38,0.35,0.77,0.53,1.15c0.2,0.42,0.4,0.84,0.6,1.25c0.2,0.41,0.4,0.82,0.61,1.23c0.29,0.59,0.59,1.18,0.9,1.76
				c0.36,0.68,0.72,1.37,1.09,2.04c0.37,0.68,0.75,1.35,1.13,2.02c0.38,0.68,0.78,1.35,1.18,2.01c0.44,0.75,0.9,1.48,1.37,2.22
				c0.16,0.26,0.33,0.52,0.49,0.77c0.2,0.32,0.4,0.63,0.61,0.95c0.37,0.56,0.75,1.13,1.13,1.69c0.08,0.12,0.15,0.24,0.24,0.35
				C71.27,180.26,71.35,180.37,71.42,180.48z"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  base: { lineHeight: "1em" },
  override: {
    transition: "color 0.5s linear",
    "& $st0, & $st1, & $st2, & $st3, & $st4, & $st6, & $st7": {
      fill: "inherit",
    },
  },
  st0: { stroke: "#231F20", strokeMiterlimit: 10 },
  st1: { stroke: "#231F20", strokeWidth: "0.5517", strokeMiterlimit: 10 },
  st2: { fill: "#66C1B9" },
  st3: { fill: "#084964" },
  st4: { fill: "#0B89A0" },
  st5: { fill: "#ffffff" },
  st6: { fill: "#231F20" },
  st7: { opacity: "0.18" },
  transparent: { opacity: 0 },
}))

/*
 **  PropTypes
 */
Brand.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  color: PropTypes.string,
}

export default Brand
