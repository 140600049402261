/********************************************************************
 *
 * /components/category/tips/TipFriends.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link, Typography } from "@mui/material"
import Tip from "./Tip"
import { useSiteTools } from "../../../providers/SiteToolsProvider"

/**
 * Renders a tip for friends booking a sail tour
 *
 * @param {*} param0
 * @returns
 */
const TipFriends = ({ open, onClose }) => {
  const { onSupportRequest } = useSiteTools()
  const { image } = useStaticQuery(graphql`
    {
      image: file(
        relativePath: {
          eq: "products/sailing-tours/friends-on-a-sailboat-1-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `)

  return (
    <Tip image={image} open={open} onClose={onClose}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Sailing Tours for Friends
      </Typography>
      <Typography paragraph>
        Get the crew together for a fun filled day on the water! Whether the
        goal is to try something new, or celebrate a birthday, anniversary, or
        other special event, a day on the water with your friends will be the
        highlight of your summer.
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Sailing Experiences
      </Typography>
      <Typography paragraph>
        For groups of five or less, consider joining others on a shared
        afternoon{" "}
        <Link href="/p/sailing-tours/sailing-experience/">
          Sailing Experience
        </Link>{" "}
        through the majestic cliffs, waterfalls and snowcapped mountains of
        Northern Howe Sound.
      </Typography>
      <Typography paragraph>
        If your crew wants to play in the mountains during the day, or simply
        prefers the chill vibes of alpenglow and twilight, then cozy up under
        some blankets on a{" "}
        <Link href="/p/sailing-tours/sunset-sailboat-cruise/">Sunset Sail</Link>
        , and sail towards the peaceful glow of a sun setting over the Tantalus
        Mountains.
      </Typography>
      <Typography variant="h3" sx={{ marginBottom: 1 }}>
        Sailing Charters
      </Typography>
      <Typography paragraph>
        Big group, big plans or big{" "}
        <Link href="/groups/birthday-activities/" target="_blank">
          birthday ideas
        </Link>
        ?
      </Typography>
      <Typography paragraph>
        <Link href="/p/sailing-charters/private-sailboat-charter/">
          Private Sailboat Charters
        </Link>{" "}
        offer your friends exclusive use of the vessel and crew, and are perfect
        for when you bring your own party.{" "}
        <Link onClick={onSupportRequest}>Tell Us</Link> about your group, event
        or and we'll help you plan a private sailboat charter that is guaranteed
        to impress the entire gang.
      </Typography>
    </Tip>
  )
}

/*
 **  PropTypes
 */
TipFriends.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TipFriends
