/********************************************************************
 *
 * /components/departureMap.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

/**
 * DepartureMap
 *
 * Renders a google map with the departure pin location
 *
 * @param {*} props
 */
const DepartureMap = ({ title }) => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.dark",
        height: "calc(500px + 1.5em)",
        width: "100%",
        margin: "0 auto",
        padding: "0",
      }}
    >
      <Typography
        sx={{
          color: "primary.contrastText",
          paddingLeft: "0.5em",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          overflow: "hidden",
          background: "none !important",
          height: "500px",
          width: "100%",
        }}
      >
        <iframe
          title="Departure Location for Canadian Coastal Sailing"
          width="100%"
          height="500px"
          id="gmap_canvas"
          src="https://maps.google.com/maps/embed/v1/place?key=AIzaSyDDsXrnt22R5Y6ZZSZg4lkSULqHCktmNqE&q=Canadian%20Coastal"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */
DepartureMap.propTypes = {
  title: PropTypes.string,
}

DepartureMap.defaultProps = {
  title: `Canadian Coastal Departs from the Squamish Harbour Authority`,
}

export default DepartureMap
