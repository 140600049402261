/********************************************************************
 *
 * /components/layout/Footer.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import {
  Container,
  Box,
  Toolbar,
  Link,
  Tooltip,
  Typography,
} from "@mui/material"
import { MailOutline, Instagram } from "@mui/icons-material"
import { FooterTheme } from "../../themes"

/**
 * Footer
 *
 * Renders a page footer.
 *
 */
const Footer = () => (
  <FooterTheme>
    <Container
      id="footer"
      component="footer"
      maxWidth={false}
      sx={{
        color: "text.primary",
        backgroundColor: "background.default",
        padding: "4em 0 100px 0",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        "& a, & p, & span": {
          fontSize: { xs: "6pt", sm: "8pt", md: "10pt", lg: "11pt" },
          letterSpacing: { sm: "0.125em", md: "0.125em", lg: "0.125em" },
        },
        "& a:hover": { color: "text.secondary" },
      }}
    >
      <Toolbar>
        <Link
          data-source="webpage"
          href="https://www.instagram.com/canadiancoastal/"
          target="_blank"
          aria-hidden="true"
          sx={{ px: 1 }}
        >
          <Tooltip title="CanadianCoastal on Instagram">
            <Instagram />
          </Tooltip>
        </Link>
        <Link href="/contact/" aria-hidden="true" sx={{ px: 1 }}>
          <Tooltip title="Contact Us">
            <MailOutline />
          </Tooltip>
        </Link>
      </Toolbar>
      <Box sx={{ pb: 1 }}>
        <Link variant="button" href="/m/vancouver/sailing-tours/">
          Vancouver Sailing Tours
        </Link>
        <Seperator />
        <Link variant="button" href="/m/squamish/sailing-tours/">
          Squamish Boat Tours
        </Link>
        <Seperator />
        <Link variant="button" href="/m/whistler/sailing-tours/">
          Whistler Yacht Tours
        </Link>
      </Box>
      <Box sx={{ pb: 4 }}>
        <Link variant="button" href="/giving/community-support/">
          Conservation &amp; Community
        </Link>
        <Seperator />
        <Link
          variant="button"
          href="/commercial/film-location-sailboat-services/"
        >
          Film &amp; Photo
        </Link>
        <Seperator />
        <Link variant="button" href="/careers/">
          Jobs
        </Link>
        <Seperator />
        <Link variant="button" href="/groups/sailboat-adventure-weddings/">
          Adventure Elopements
        </Link>
      </Box>
      <Box sx={{ pb: 4 }}>
        <Link
          variant="button"
          href="/m/squamish/travel-from-vancouver-to-squamish/"
        >
          Travelling from Vancouver to Squamish
        </Link>
      </Box>
      <Typography
        gutterBottom
        sx={{
          letterSpacing: "0.225em",
          textTransform: "uppercase",
        }}
      >
        {`© ${new Date().getFullYear()} Canadian Coastal Sailing Inc. All rights reserved.`}
      </Typography>
      <Box>
        <Link variant="button" href="/legal/terms/">
          Terms of Use
        </Link>
        <Seperator />
        <Link variant="button" href="/legal/privacy/">
          Privacy
        </Link>
        <Seperator />
        <Link variant="button" href="/policies/cancellation/">
          Cancellations
        </Link>
      </Box>
    </Container>
  </FooterTheme>
)

const Seperator = () => (
  <Box
    component="span"
    sx={{
      mx: 1.25,
    }}
  >
    |
  </Box>
)

export default Footer
