/********************************************************************
 *
 * components/tourPicker/SKU.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { Grid, Typography, FormControl, Select, MenuItem } from "@mui/material"
import { useApp, useCCAPI, useAuthAPI } from "../../../../providers/AppProvider"
import { useAffiliate } from "../../../../providers/AffiliateProvider"
import { SyncButton } from "../../../../components"
import format from "../../../../lib/format"
import * as tags from "../../../../lib/tags"

/**
 * SKU
 *
 * Renders a calendar-indexed list of products.
 *
 */
const SKU = ({ sku, meta }) => {
  const [qty, setQty] = useState(sku.available > 2 ? 2 : sku.available)
  const [processing, setProcessing] = useState(false)
  const app = useApp()
  const api = useCCAPI()
  const authApi = useAuthAPI()
  const { getAffiliate } = useAffiliate()

  if (!sku) return null

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnCreateOrder
   *
   * Fired when a user selects the SKU
   *
   * @param {*} event
   */
  const onCreateOrder = ({ sku }) => {
    setProcessing(true)

    api
      .create(
        `/api/orders/order/`,
        { items: [{ skuId: sku.id, qty: qty }] },
        getAffiliate().id
      )
      .then(({ payload: order }) => {
        navigate(`/order/review/${order.code}`)
        trackCreateOrder(order.id)
        tags.fireEvent("add_to_cart", {
          currency: "CAD",
          value: sku.rate / 100,
          items: [
            {
              item_id: sku.productId,
              item_name: sku.name,
              price: sku.rate / 100,
              quantity: qty,
              affiliation: "Canadian Coastal Website",
              item_brand: "Canadian Coastal Sailing",
            },
          ],
        })
      })
      .catch(error => {
        app.error({ error, location: "SKU.onCreateOrder" })
        setProcessing(false)
      })
  }

  /**
   * TrackCreateOrder
   *
   * @param {*} selectedDate
   */
  const trackCreateOrder = orderId => {
    //
    //  Request affiliate id
    //
    authApi.create(`/event/order`, { orderId }).catch(error => {
      app.error({ error, location: "SKU.trackCreateOrder" })
    })
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!sku) return null

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={2}
    >
      {/* Descriptive Content */}
      <Grid item xs={12} sm={7} style={{ flex: "1 1 auto" }}>
        <div>
          {sku.available > 0 && sku.available < 6 && (
            <Typography
              variant="subtitle1"
              sx={{ color: "#ff4444", paddingBottom: 0 }}
            >
              {`Popular date - only ${
                parseInt(sku.available) === 1 ? "1 " : `${sku.available} `
              } remaining!`}
            </Typography>
          )}
          <Typography variant="subtitle1" component="div">
            {sku.startText}
          </Typography>
          <Typography variant="body2" component="div">
            {`CAD ${format.currency(sku.rate)} ${meta && meta.unit}`}
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={5}
        sx={{
          justifyContent: { xs: "flex-start", sm: "flex-end" },
        }}
      >
        {parseInt(sku.available) === 0 ? (
          <Typography
            variant="body2"
            noWrap
            sx={{
              color: "#ff4444",
              padding: 1,
              paddingLeft: "0",
              fontWeight: 700,
              fontSize: "0.9em",
            }}
          >
            SORRY, SOLD OUT
          </Typography>
        ) : (
          <>
            {(!meta || meta.selector) && (
              <FormControl
                variant="outlined"
                margin="dense"
                sx={{ padding: "0 1em 0 0", margin: "0" }}
              >
                <Select
                  value={qty}
                  onChange={e => {
                    e.stopPropagation()
                    setQty(e.target.value)
                  }}
                  sx={{
                    "& .MuiInputBase-input": { px: 2, py: 1.1 },
                  }}
                >
                  {(() => {
                    let items = []

                    for (let i = 1; i <= parseInt(sku.available); i++) {
                      items.push(
                        <MenuItem key={i} value={i}>
                          {i}
                        </MenuItem>
                      )
                    }
                    return items
                  })()}
                </Select>
              </FormControl>
            )}
            <SyncButton
              onClick={() => {
                onCreateOrder({ sku })
              }}
              processing={!!processing}
            >
              Book!
            </SyncButton>
          </>
        )}
      </Grid>
    </Grid>
  )
}

SKU.propTypes = {
  sku: PropTypes.object.isRequired,
}

export default SKU
