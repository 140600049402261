/********************************************************************
 *
 * /components/lead.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Grid,
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
} from "@mui/material"
import { Alert } from "@mui/material"
import { Formik } from "formik"
import * as yup from "yup"
import { useApp, useCCAPI } from "../providers/AppProvider"
import * as tags from "../lib/tags"

const MAX_MESSAGE_LENGTH = 1000

/**
 * Lead
 *
 * Renders a contact form
 *
 */
const Lead = ({
  title,
  subtitle,
  name = "",
  email = "",
  phone = "",
  defaultMessage = "",
  buttonText,
  edges = false,
  typeId = 0,
  onCancelled,
  onSaved,
}) => {
  const [submitted, setSubmitted] = useState(undefined)
  const [error, setError] = useState(null)
  const app = useApp()
  const api = useCCAPI()

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when a form is submitted
   *
   * @param {*} values
   */
  const onSave = values => {
    return new Promise((resolve, reject) => {
      //
      //  Submit the form data
      //
      api
        .create("api/leads/lead/", {
          typeId: typeId,
          source: { id: 2 },
          name: values.name,
          email: values.email,
          phone: values.phone,
          message: values.message,
        })
        .then(({ payload: lead }) => {
          setSubmitted(true)
          onSaved && onSaved()
          resolve()

          //
          //  Fire event tag. Ignore if a career lead
          //
          if (typeId != 5) tags.fireEvent("sales-lead-create", { lead: lead })
        })
        .catch(error => {
          setError(
            "Oops, it seems that there was an error delivering your message. We are sorry for the inconvenience; the issue has been reported to skipper."
          )
          app.error({ error, location: "Lead.onSave" })
          reject()
        })
    })
  }

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = dirty => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled()
    }
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (submitted) return <Submitted />

  return (
    <Box
      sx={[
        {
          width: "100%",
          boxSizing: "border-box",
          margin: 0,
        },
        edges && {
          padding: { xs: 0, md: 4 },
          borderRadius: { xs: 0, md: "10px" },
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderRadius: { xs: 0, md: "4px" },
          boxShadow: {
            xs: "none",
            md: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          },
        },
      ]}
    >
      {!!title && (
        <Typography
          variant="h3"
          sx={{
            marginBottom: "1em",
          }}
        >
          {title}
        </Typography>
      )}
      {!!subtitle && (
        <Typography
          variant="body2"
          sx={{
            marginBottom: "1em",
          }}
        >
          {subtitle}
        </Typography>
      )}
      {!!error && (
        <Snackbar open={!!error} onClose={() => setError(undefined)}>
          <Alert severity="error" onClose={() => setError(undefined)}>
            {error}
          </Alert>
        </Snackbar>
      )}
      <FormBody
        name={name}
        email={email}
        phone={phone}
        defaultMessage={defaultMessage}
        buttonText={buttonText}
        onCancel={onCancelled && onCancel}
        onSave={onSave}
      />
    </Box>
  )
}

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({
  name = "",
  email = "",
  phone = "",
  defaultMessage = "",
  buttonText,
  onCancel,
  onSave,
}) => (
  <Formik
    initialValues={{
      name: name,
      email: email,
      phone: phone,
      message: defaultMessage,
    }}
    onSubmit={(lead, actions) => {
      onSave(lead).then(() =>
        actions.resetForm({ name: "", email: "", phone: "", message: "" })
      )
    }}
    validationSchema={yup.object().shape({
      name: yup.string().required("Name is required"),
      email: yup
        .string()
        .email("It seems that the email address is not valid.")
        .required("Please share your email so that we may respond!"),
      message: yup
        .string()
        .required("Please provide us with a little more information.")
        .test(
          "len",
          `Message is too long. Maximum length is ${MAX_MESSAGE_LENGTH} characters.`,
          value => value == null || value.length < MAX_MESSAGE_LENGTH
        ),
    })}
  >
    {({
      values,
      touched,
      errors,
      dirty,
      isSubmitting,
      setFieldValue,
      handleChange,
      handleBlur,
      handleSubmit,
    }) => {
      return (
        <form onSubmit={handleSubmit}>
          <LeadForm
            lead={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 2,
            }}
          >
            {!!onCancel && (
              <Button
                onClick={() => onCancel(dirty)}
                sx={{
                  marginRight: 2,
                }}
              >
                Cancel
              </Button>
            )}
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              disabled={
                (!dirty && !(values.name || values.email || values.message)) ||
                isSubmitting
              }
            >
              {buttonText}
            </Button>
          </Box>
        </form>
      )
    }}
  </Formik>
)

/**
 * LeadForm
 *
 * @param {*} param0
 */
const LeadForm = ({
  lead,
  touched,
  errors,
  setFieldValue,
  onBlur,
  onChange,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid container item justifyContent="center" xs={12}>
        <TextField
          id="name"
          label="Your Name"
          value={lead.name}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.name && touched.name}
          helperText={errors.name || " "}
        />
      </Grid>
      <Grid container item justifyContent="center" xs={12}>
        <TextField
          id="email"
          label="Your Email"
          value={lead.email}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          required
          error={errors.email && touched.email}
          helperText={errors.email || " "}
        />
      </Grid>
      <Grid container item justifyContent="center" xs={12}>
        <TextField
          id="phone"
          label="Phone (Optional)"
          value={lead.phone}
          onBlur={onBlur}
          onChange={onChange}
          fullWidth
          error={errors.phone && touched.phone}
          helperText={errors.phone || " "}
        />
      </Grid>
      <Grid container item justifyContent="center" xs={12}>
        <TextField
          id="message"
          label="Message"
          value={lead.message}
          onBlur={onBlur}
          onChange={onChange}
          multiline={true}
          rows={5}
          variant="outlined"
          fullWidth
          required
          error={errors.message && touched.message}
          helperText={
            errors.message ||
            `${
              Lead.message ? lead.message.length : 0
            }/${MAX_MESSAGE_LENGTH} characters`
          }
        />
      </Grid>
    </Grid>
  )
}

/**
 * Submitted
 *
 */
const Submitted = () => (
  <Box style={{ padding: "0" }}>
    <Typography
      variant="h5"
      sx={{
        marginBottom: "1em",
      }}
    >
      Thanks for reaching out!
    </Typography>

    <Typography
      sx={{
        marginBottom: "1em",
      }}
    >
      We have received your message and thank you for contacting us. If your
      enquiry is urgent, please call or text us at (604) 967-3360 to speak with
      one of our crew. Otherwise, we'll reply by email as soon as possible.
    </Typography>
  </Box>
)

/*
 **  PropTypes
 */
Lead.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  typeId: PropTypes.number,
}

Lead.defaultProps = {
  title: undefined,
  subtitle: undefined,
  buttonText: "Submit",
  typeId: 0,
}

export default Lead
