/********************************************************************
 *
 * app/lib/Notification.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useEffect, useState } from "react"
import { Slide, AppBar, Box } from "@mui/material"

/**
 * Notification
 *
 * Adds an appbar to the bottom of the page with the message provided
 *
 * @param {*} props
 */
const Notification = ({
  delay = 0,
  display = null,
  enter = 1000,
  exit = 1000,
  color = "lightBanner",
  sx,
  children,
}) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
      if (!!display)
        setTimeout(() => {
          setShow(false)
        }, display)
    }, delay)
  }, [])

  return (
    <Slide
      direction="up"
      in={show}
      timeout={{ enter, exit }}
      mountOnEnter
      unmountOnExit
    >
      <AppBar
        position="fixed"
        component="section"
        color={color}
        sx={[
          {
            width: "100%",
            height: "85px",
            top: "auto",
            borderTop: "solid 1px #999999",
            bottom: 0,
          },
          sx,
        ]}
      >
        <Box sx={{ px: { xs: 2, sm: 4 }, margin: "auto" }}>{children}</Box>
      </AppBar>
    </Slide>
  )
}

/*
 **  PropTypes
 */
Notification.propTypes = {}

export default Notification
