/********************************************************************
 *
 * /themes/linktree.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles"

let theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(0deg, #01899f 0%, #0c4964 50%)",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          padding: "25px",
          color: "#ffffff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          color: "#0c4964",
          borderRadius: "10px",
          marginBottom: "25px",
        },
        contained: {
          color: "#0c4964",
          backgroundColor: "#ffffff",
          padding: "10px",
        },
        containedSizeLarge: {
          fontSize: "1.5em",
          padding: "15px",
        },
        iconSizeLarge: {
          "& *:first-child": { fontSize: "30px" },
        },
        label: {
          justifyContent: "flex-start",
        },
        startIcon: { fontSize: "30px", color: "#01899f", marginLeft: "0px" },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      },
    },
  },
  palette: {
    primary: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    text: {
      primary: "#484848",
      secondary: "#999999",
    },
  },
})

theme = responsiveFontSizes(theme, { factor: 2 })

const LinkTreeThemeProvider = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
export default LinkTreeThemeProvider
