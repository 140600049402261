/********************************************************************
 *
 * /components/ImageLink.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Box, Typography, Link, Chip } from "@mui/material"

/**
 * Renders an ImageLink card-style item in the product list
 *
 * @param {*} param0
 * @returns
 */
const ImageLink = ({
  img,
  label,
  tag,
  target,
  description,
  href,
  bonus,
  sx,
  children,
}) => (
  <Box
    data-component-id="IMAGE_LINK"
    sx={[
      {
        display: "flex",
        flexDirection: "column",
        alignContent: "stretch",
      },
      sx,
    ]}
  >
    <Box
      sx={{
        flex: "0 0 auto",
        aspectRatio: "1 / 1",
        position: "relative",
        borderRadius: "16px",
        boxShadow: "0px 2px 8px rgb(0 0 0 / 15%)",
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      {!!tag && (
        <Chip
          label={tag}
          color="info"
          sx={{
            position: "absolute",
            top: 10,
            left: 10,
            zIndex: 100,
            bgcolor: "#FFFFFF",
            color: "black",
            borderRadius: 1,
            height: "fit-content",
            py: 0.5,
            px: 1,
            "& .MuiChip-label": {
              fontWeight: 700,
              fontSize: "14px",
              textTransform: "uppercase",
              p: 0,
            },
          }}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          right: 0,
          zIndex: 100,
        }}
      >
        {bonus}
      </Box>
      <Link
        href={href}
        sx={{
          display: "block",
          position: "relative",
          boxSizing: "border-box",
          cursor: !!href ? "pointer" : "inherit",
          transition:
            "box-shadow 0.2s ease, -ms-transform 0.1s ease, -webkit-transform 0.1s ease, transform 0.1s ease",
          p: 0,
          m: 0,
          height: "100%",
          width: "100%",
          color: "text.primary",
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            "& .gatsby-image-wrapper": { transition: "transform 1s ease" },
            "& .gatsby-image-wrapper:hover": {
              transform: href ? "scale(1.1)" : "none",
            },
          }}
        >
          <GatsbyImage
            image={img}
            alt={label}
            objectPosition="50% 50%"
            objectFit="cover"
            style={{ width: "100%", height: "100%", zIndex: 0 }}
            imgStyle={{ borderRadius: "16px 16px 0 0" }}
          />
        </Box>
      </Link>
    </Box>
    <Box
      sx={{
        flex: "1 1",
        px: "10px",
        pt: 2,
        pb: { xs: 8, md: 2 },
      }}
    >
      <Link href={href}>
        <Box
          sx={{
            height: "100%",
            display: "Flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignContent: "space-between",
          }}
        >
          <Box>
            {!!label && (
              <Typography
                variant="imageLink"
                color="text.primary"
                component="h3"
                sx={{ pb: 1 }}
              >
                {label}
              </Typography>
            )}
            {!!target && (
              <Typography variant="body2" color="text.primary" component="div">
                {target}
              </Typography>
            )}
            {!!description && (
              <Typography
                variant="body2"
                color="text.primary"
                component="div"
                dangerouslySetInnerHTML={
                  typeof description === "object" ? description : null
                }
              >
                {typeof description === "string" ? description : null}
              </Typography>
            )}
          </Box>
          <Box>{children}</Box>
        </Box>
      </Link>
    </Box>
  </Box>
)

/*
 **  PropTypes
 */
ImageLink.propTypes = {
  img: PropTypes.object,
  label: PropTypes.string,
  tag: PropTypes.string,
  target: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
}

export default ImageLink
