/********************************************************************
 *
 * CCAPIs.js
 *
 * Template API client for Canadian Coastal APIs.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import HTTPError from "./HTTPError"

/**
 * Export a closure that contains methods to call methods on the
 * respective APIs.
 */
function CCAPIs(url, getAuthToken) {
  this.url = url
  this.getAuthToken = getAuthToken

  return {
    create: (path, body) => {
      return callAPI(
        this.url,
        path,
        "POST",
        this.getAuthToken,
        JSON.stringify(body)
      )
    },
    update: (path, body) => {
      return callAPI(
        this.url,
        path,
        "PUT",
        this.getAuthToken,
        JSON.stringify(body)
      )
    },
    fetch: path => {
      return callAPI(this.url, path, "GET", this.getAuthToken)
    },
    delete: path => {
      return callAPI(this.url, path, "DELETE", this.getAuthToken)
    },
  }
}

/**
 * callAPI
 *
 * Calls the Canadian Coastal REST API. The arguments have been
 * initialized with the route, HTTP verb and payload. This method
 * configures the HTTP request parameters.
 *
 * @param {string} url
 * @param {string} path
 * @param {string} method
 * @param {string} getAuthToken
 * @param {string} body
 */
function callAPI(url, path, method = "GET", getAuthToken, body = null) {
  return new Promise((resolve, reject) => {
    //
    //  Keep getAuthToken in the Promise.resolve method. In the case
    //  of non-secure (public) APIs, the function reference may be null.
    //
    Promise.resolve(getAuthToken && getAuthToken())
      .then(token => {
        const headerOptions = {
          "Content-Type": "application/json",
          agent: 35,
        }

        if (token) {
          //
          //  Old APIs
          //
          headerOptions.cctoken = token

          //
          //  New APIs
          //
          headerOptions.authorization = `Bearer ${token}`
        }

        let resource = new URL(path.replace(/^(\/)/, ""), url)

        const request = new Request(resource.href, {
          method: method,
          credentials: "include",
          mode: "cors",
          headers: new Headers(headerOptions),
          body: body,
        })

        return fetch(request)
      })
      .then(res => {
        if (!res.ok)
          return res.text().then(text => {
            throw new HTTPError(res.status, text)
          })

        switch (res.status) {
          case 204:
            resolve()
            break
          default:
            resolve(res.json())
        }
      })
      .catch(error => {
        if (error.status) reject(error)
        else
          reject(
            new HTTPError(
              500,
              `Unable to ${method} ${url}${path}: ${error.message}`
            )
          )
      })
  })
}

export default CCAPIs
