/********************************************************************
 *
 * /components/BannerTitle.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

/**
 * BannerTitle
 *
 * Renders a banner title
 *
 * @param {*} param0
 */
const BannerTitle = ({ title, subtitle }) => {
  return (
    <Box sx={{ mb: 2.5 }}>
      <Typography variant="h2">{title}</Typography>
      {!!subtitle && (
        <Typography sx={{ mt: 1 }} variant="body1">
          {subtitle}
        </Typography>
      )}
    </Box>
  )
}

/*
 **  PropTypes
 */
BannerTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default BannerTitle
