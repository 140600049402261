/********************************************************************
 *
 * app/orderProcessing/components/DatePicker.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import { CircularProgress, Box } from "@mui/material"
import {
  LocalizationProvider,
  CalendarPicker,
  PickersDay,
} from "@mui/x-date-pickers"
import { DateTime } from "luxon"
import { useSiteTools } from "../../../../providers/SiteToolsProvider"
import CustomAdapterLuxon from "../../../../lib/CustomAdapterLuxon"
import Popup from "../../../../components/Popup"
import EndOfSeason from "../EndOfSeason"
import ControlBase from "./ControlBase"

const DatePicker = ({
  value,
  loading,
  showLoading = false,
  onDateStatus,
  onMonthChange,
  onChange,
  min,
  max,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { season } = useSiteTools()

  return (
    <Box>
      <ControlBase
        label="Date"
        value={
          value
            ? value.toLocaleString({
                weekday: "short",
                month: "short",
                day: "2-digit",
              })
            : "Choose date"
        }
        open={openModal}
        onClick={ref => {
          if (!openModal) {
            setOpenModal(true)
          }
        }}
      />
      <LocalizationProvider dateAdapter={CustomAdapterLuxon}>
        <Popup
          top={10}
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
        >
          <Box
            sx={{
              background: "rgb(255, 255, 255)",
              boxShadow:
                "rgb(0 0 0 / 5%) 0px 2px 6px, rgb(0 0 0 / 7%) 0px 0px 0px 1px",
              borderRadius: "5px",
            }}
          >
            {!season.display ? (
              <EndOfSeason />
            ) : (
              <CalendarPicker
                date={value}
                defaultCalendarMonth={
                  DateTime.now() > min ? DateTime.now() : min
                }
                minDate={min}
                maxDate={max}
                loading={showLoading && loading}
                renderLoading={() => showLoading && <CircularProgress />}
                onMonthChange={onMonthChange}
                disableHighlightToday={true}
                shouldDisableDate={onDateStatus}
                disableScrollLock={true}
                onChange={date => {
                  setOpenModal(false)
                  onChange(date)
                }}
                renderDay={(day, value, DayComponentProps) => {
                  return (
                    <PickersDay
                      {...DayComponentProps}
                      sx={() =>
                        day < DateTime.now().startOf("day")
                          ? {
                              color: "rgba(0, 0, 0, 0.25) !important",
                              textDecoration: "line-through",
                            }
                          : !DayComponentProps.disabled && { fontWeight: 600 }
                      }
                    />
                  )
                }}
              />
            )}
          </Box>
        </Popup>
      </LocalizationProvider>
    </Box>
  )
}

/*
 **  PropTypes
 */
DatePicker.propTypes = {
  onMonthChange: PropTypes.func.isRequired,
  onDateStatus: PropTypes.func,
  min: PropTypes.object,
  max: PropTypes.object,
}

export default DatePicker
