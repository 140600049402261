/********************************************************************
 *
 * /components/Feature.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import { SplitScreen, SplitScreenImagePane, SplitScreenContentPane } from "."

const Feature = ({ img, alt, mb = 8, flip, children }) => (
  <Box sx={{ backgroundColor: "#f4f2ef", p: 2, mb: mb }}>
    <SplitScreen flip={flip}>
      <SplitScreenImagePane
        xs={12}
        md={5}
        fluid={img}
        alt={alt}
        mobileHeight="300px"
      />
      <SplitScreenContentPane xs={12} md={7}>
        <Box sx={{ p: { xs: "32px 16px 16px 16px", sm: 4, md: 8 } }}>
          {children}
        </Box>
      </SplitScreenContentPane>
    </SplitScreen>
  </Box>
)

/*
 **  PropTypes
 */
Feature.propTypes = {
  img: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  flip: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Feature
