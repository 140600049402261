/********************************************************************
 *
 * /components/Sandwich.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

const Sandwich = ({ children, py = 4, sx, ...other }) => {
  return (
    <Box
      sx={[
        {
          display: "inline-block",
          margin: "0 auto",
          py: py,
          borderTop: "solid 1px #EBEBEB",
          borderBottom: "solid 1px #EBEBEB",
        },
        sx,
      ]}
      {...other}
    >
      {children}
    </Box>
  )
}

/*
 **  PropTypes
 */
Sandwich.propTypes = {
  children: PropTypes.node,
}

export default Sandwich
