/********************************************************************
 *
 * /components/materialize.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"

const Materialize = ({ content, variant }) => {
  return (
    <>
      {content.split("\n").map((para, index) => (
        <Typography key={index} variant={variant} paragraph>
          {para}
        </Typography>
      ))}
    </>
  )
}

/*
 **  PropTypes
 */
Materialize.propTypes = {
  content: PropTypes.string,
}

Materialize.defaultProps = {}

export default Materialize
