/********************************************************************
 *
 * /providers/AppProvider.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import CCAPIs from "../lib/CCAPIs"

const AUTH_ISSUER = "https://accounts.canadiancoastal.com"
const AUTH_CONTEXT_WEBSITE = "100003.apps.canadiancoastal.com"
const AUTH_CONTEXT_PUBLIC_API = "100004.apps.canadiancoastal.com"
const AUTH_PUBLIC_KEY_PUBLIC_API =
  "-----BEGIN PUBLIC KEY-----\nMFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAINI8865rRLK/9LoSNHopDOcJHljdnWgJe/NccC0SkZ2A4wx1sd4pHAK/H/BjtWkrVnkv69teR9Fs7FJ4VPSz4kCAwEAAQ==\n-----END PUBLIC KEY-----"

const AppContext = React.createContext()

const AppConsumer = AppContext.Consumer

const AppProvider = ({ children }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  App API functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * GetPublicApiToken
   */
  const getPublicApiToken = () => {
    let token = null

    return new Promise((resolve, reject) => {
      token = getAuthToken(AUTH_CONTEXT_PUBLIC_API)

      validateAccessToken(token, AUTH_PUBLIC_KEY_PUBLIC_API)
        .then(token => {
          if (!token) {
            return requestAnonAccessToken(
              AUTH_CONTEXT_WEBSITE,
              AUTH_CONTEXT_PUBLIC_API,
              AUTH_PUBLIC_KEY_PUBLIC_API
            ).then(token => {
              putAuthToken(AUTH_CONTEXT_PUBLIC_API, token)
              return token
            })
          }

          return token
        })
        .then(token => {
          resolve(token)
        })
        .catch(error => {
          reject(error)
          handleError(error)
        })
    })
  }

  /**
   * Notify
   *
   * Application-wide notificaions
   *
   * @param {*} message
   */
  const notify = message => {
    console.log(message)
  }

  /**
   * Error
   *
   * Application-wide error handling
   *
   * @param {*} error
   */
  const handleError = ({ error, location }) => {
    let api = new CCAPIs(process.env.GATSBY_API_URL, getPublicApiToken)

    api
      .create("/api/log/6/", {
        message: `${location}: ${error.message}`,
      })
      .catch(() => {
        console.debug("Logging error")
        //
        //  Umm, this would be an ironic place to be...
        //  Never log this error, lest you want to be stuck in an
        //  firey API loop from hell
        //
      })
  }

  return (
    <AppContext.Provider
      value={{ getPublicApiToken, notify, error: handleError }}
    >
      {children}
    </AppContext.Provider>
  )
}

///////////////////////////////////////////////////////////////////
//
//  Private Helper Functions
//
///////////////////////////////////////////////////////////////////

/**
 * PutAuthToken
 *
 * @param {*} key
 * @param {*} token
 */
const putAuthToken = (key, token) => {
  localStorage.setItem(key, token)
}

/**
 * GetAuthToken
 *
 * @param {*} token
 */
const getAuthToken = key => {
  return localStorage.getItem(key)
}

/**
 * RequestAnonAccessToken
 *
 * @param {*} contextId
 */
const requestAnonAccessToken = (clientCode, contextId, publicKey) => {
  const authApi = new CCAPIs(process.env.GATSBY_AUTH_API_URL)

  if (!clientCode) throw new Error("Parameter 'clientCode' is required")
  if (!contextId) throw new Error("Parameter 'contextId' is required")
  if (!publicKey) throw new Error("Parameter 'publicKey' is required")

  return new Promise((resolve, reject) => {
    authApi
      .create(
        `signin/v2/anon/?client_id=${clientCode}&response_type=token&scope=${contextId}`
      )
      .then(({ accessToken }) => {
        if (!validateAccessToken(accessToken, publicKey))
          throw new Error("Invalid Token")

        resolve(accessToken)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/**
 * ValidateAccessToken
 *
 * @param {*} accessToken
 */
const validateAccessToken = (accessToken, publicKey) => {
  let validatedAccessToken = null

  return new Promise(resolve => {
    try {
      if (!accessToken) throw new Error("Null token")

      validatedAccessToken = accessToken
    } catch (error) {}

    resolve(validatedAccessToken)
  })
}

/*
 **  Hooks
 */

const useApp = () => {
  return React.useContext(AppContext)
}

const useCCAPI = () => {
  const context = useApp()

  return new CCAPIs(process.env.GATSBY_API_URL, context.getPublicApiToken)
}

const useAuthAPI = () => {
  return new CCAPIs(process.env.GATSBY_AUTH_API_URL)
}

const useCCLabAPI = () => {
  return new CCAPIs(process.env.GATSBY_API_LAB_URL)
}

export { AppProvider, AppConsumer, useApp, useCCAPI, useAuthAPI, useCCLabAPI }
