/********************************************************************
 *
 * Sims.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Typography, Grid } from "@mui/material"
import { Section } from "../index"

/**
 * Sims
 *
 * Displays an array of similar products
 *
 * @param {*} param0
 */
const Sims = ({ productCards }) => {
  return (
    <Section fullWidth>
      <Typography
        variant="h2"
        gutterBottom
        sx={{ letterSpacing: "0.05em", textTransform: "uppercase" }}
      >
        You may also be interested in...
      </Typography>
      <Grid container alignItems="stretch" spacing={0}>
        {productCards.map((productCard, index) => (
          <Grid key={index} item xs={12} md={6} sx={{ padding: 3 }}>
            {productCard}
          </Grid>
        ))}
      </Grid>
    </Section>
  )
}

/*
 **  PropTypes
 */
Sims.propTypes = {
  productCards: PropTypes.array,
}

Sims.defaultProps = {}

export default Sims
