/********************************************************************
 *
 * /components/layout/header/Buttons.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { Box, ButtonGroup, Link, IconButton } from "@mui/material"
import { MenuRounded } from "@mui/icons-material"

/**
 * Buttons
 *
 * Renders a page header including navigation.
 *
 * @param {*} props
 */
const Buttons = ({ toggleMenu }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  //
  //  Renders main buttons for header. Eventually Login will be added
  //
  return (
    <>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <Link
          variant="button"
          href="/gifting/sailing-gift-cards-and-vouchers/"
          sx={styles.link}
        >
          Buy Gift Card
        </Link>
      </Box>
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        <ButtonGroup
          sx={{
            backgroundColor: "secondary.light",
            borderRadius: "9999px",
            border: "1px solid rgb(221,221,221)",
            opacity: { xs: 1, lg: 0 },
          }}
          variant="outlined"
        >
          <IconButton
            sx={{
              padding: 1,
              "&:hover": {
                backgroundColor: "rgba(72, 72, 72, 0.2)",
              },
            }}
            onClick={event => toggleMenu(event, true)}
            aria-label="Menu"
            size="large"
          >
            <MenuRounded />
          </IconButton>
        </ButtonGroup>
      </Box>
    </>
  )
}

/*
 **  Styles
 */
const styles = {
  link: {
    fontFamily: "Quicksand, Helvetica, Arial,sans-serif",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    whiteSpace: "nowrap",
    cursor: "pointer",
    color: "text.primary",
    "&:hover": {
      color: "primary.main",
    },
  },
  submenu: {
    "&:hover": {
      backgroundColor: "background.default",
    },
  },
}
export default Buttons
