/********************************************************************
 *
 * /components/Highlight.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography, Paper } from "@mui/material"

const Highlight = ({ title, children, action, img }) => {
  return (
    <Box
      component="section"
      sx={{
        boxSizing: "border-box",
        position: "relative",
        width: "100%",
        height: { xs: "auto", md: "inherit" },
        minHeight: { xs: "360px", md: undefined },
      }}
    >
      <Box
        sx={{
          position: { xs: "relative", md: "absolute" },
          top: { xs: "auto", md: "0" },
          left: { xs: "auto", md: "0" },
          bottom: { xs: "auto", md: "0" },
          right: { xs: "auto", md: "0" },
          overflow: { xs: "hidden", md: "visible" },
        }}
      >
        <Box
          sx={{
            position: "relative",
            top: "0",
            left: "0",
            width: "auto",
            height: { xs: "auto", md: "100%" },
            pointerEvents: "none",
            overflow: { xs: "visible", md: "hidden" },
          }}
        >
          {img}
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          padding: "0!important",
          paddingBottom: { xs: 0, md: "40px" },
          margin: "auto",
          marginBottom: "0",
          height: { xs: "auto", md: "100%" },
          minHeight: "initial",
          maxHeight: "none",
          width: "100%",
          maxWidth: "100%",
          backgroundColor: "#15181c",
        }}
      >
        <Paper
          sx={{
            position: "relative",
            display: { xs: "block", md: "flex" },
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "40px 20px",
            paddingBottom: { xs: "40px", md: "60px" },
            overflow: "hidden",
            textAlign: "center",
            minHeight: "260px",
            width: { xs: "auto", md: "450px" },
            padding: "60px 60px 120px 60px",
            zIndex: "2",
            boxShadow: "20px 20px 30px 0 rgb(22 22 22 / 13%)",
            transform: {
              xs: "none",
              md: "translate3d(0px, -43.64px, 0px)",
            },
            backgroundColor: "primary.dark",
            left: { md: "10rem" },
          }}
        >
          <Typography variant="h2" component="h3" style={{ color: "#ffffff" }}>
            {title}
          </Typography>
          <Box>{children}</Box>
          <Box>{action}</Box>
        </Paper>
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */
Highlight.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  action: PropTypes.node,
  picture: PropTypes.node,
}

export default Highlight
