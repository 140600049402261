/********************************************************************
 *
 * /components/bookButton.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Button } from "@mui/material"

const BookButton = ({ label, url = "/order/" }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      href={url}
      sx={{
        fontWeight: 700,
        textAlign: "center",
        fontSize: { xs: "8pt", sm: "10pt", md: "11pt", lg: "12pt" },
        letterSpacing: { sm: "0.125em", md: "0.125em", lg: "0.125em" },
      }}
    >
      {label}
    </Button>
  )
}

/*
 **  PropTypes
 */
BookButton.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
}

BookButton.defaultProps = {
  label: "Book Now!",
  url: "/order/",
}

export default BookButton
