/********************************************************************
 *
 * app/componenets/PopupPromo.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2023 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import { Notification } from "./index"
import { useSiteTools } from "../providers/SiteToolsProvider"

/**
 * PopupPromo
 *
 * @param {*} param0
 * @returns
 */
const PopupPromo = ({ color = "primary", action }) => {
  const { campaign } = useStaticQuery(graphql`
    query {
      campaign: allCampaignJson {
        nodes {
          title
          description
          interval {
            start
            end
          }
          delay
        }
      }
    }
  `)
  const { title, description, interval, delay } = campaign.nodes[0]
  const { locale } = useSiteTools()

  const campaignStart = DateTime.fromISO(interval.start, {
    zone: locale.zone,
  }).startOf("day")
  const campaignEnd = DateTime.fromISO(interval.end, {
    zone: locale.zone,
  }).endOf("day")

  if (DateTime.now() < campaignStart || DateTime.now() > campaignEnd) return

  return (
    <Notification
      delay={delay}
      color={color}
      sx={{
        height: "110px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          columnGap: { xs: null, sm: 2 },
          rowGap: { xs: 1, sm: null },
          "& p": { textAlign: { xs: "center", sm: "left" } },
        }}
      >
        <Box>
          <Typography
            variant="body1"
            color="primary.contrastText"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            color="primary.contrastText"
            sx={{ fontSize: "14px", fontWeight: 500 }}
          >
            {description}
          </Typography>
        </Box>
        {!!action && action}
      </Box>
    </Notification>
  )
}
/*
 **  PropTypes
 */
PopupPromo.propTypes = {
  color: PropTypes.string,
  action: PropTypes.element,
}

export default PopupPromo
