/********************************************************************
 *
 * /components/SEO/StructuredData/WebsiteSchema.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import { useStaticQuery, graphql } from "gatsby"

const WebsiteSchema = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          name
          title
          description
          author
        }
      }
    }
  `)

  return {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "@id": `${site.siteMetadata.siteUrl}/#WebSite`,
    url: site.siteMetadata.siteUrl,
    name: site.siteMetadata.name,
    description:
      "Canadian Coastal is British Columbia's premiere sailing experience, featuring daily summer sailboat tours, located between Vancouver and Whistler in Canada's southern-most fjord!",
    image: `${site.siteMetadata.siteUrl}/images/canadian-coastal-sailing-sm.png`,
  }
}

export default WebsiteSchema
