/********************************************************************
 *
 * /components/endorsement.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Typography, CircularProgress } from "@mui/material"
import { Star } from "@mui/icons-material"
import { useApp, useCCAPI } from "../providers/AppProvider"

/**
 * Endorsement
 *
 * Renders a random review
 *
 * @param props
 */
const Endorsement = ({ classes, showRating }) => {
  const [review, setReview] = useState(undefined)
  const [loaded, setLoaded] = useState(false)

  const app = useApp()
  const api = useCCAPI()

  useEffect(() => {
    fetchReview()
  }, [])

  /**
   * FetchReview
   *
   * Fetches a review from the CC API
   */
  const fetchReview = () => {
    api
      .fetch("api/reviews/review/")
      .then(({ payload: review }) => {
        setReview(review)
        setLoaded(true)
      })
      .catch(error => {
        setReview(null)
        setLoaded(true)
        app.error({ error, location: "Endorsement.fetchReview" })
      })
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded) return <CircularProgress color="secondary" />
  if (!review) return null

  return (
    <Box sx={[{ display: "flex", flexDirection: "column" }, classes.root]}>
      <Typography sx={classes.quote}>{review.lead}</Typography>
      {!!showRating && (
        <Box sx={classes.rating}>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </Box>
      )}
      <Typography sx={classes.name}>
        {review.author} - {review.location}
      </Typography>
    </Box>
  )
}

/*
 **  PropTypes
 */
Endorsement.propTypes = {
  classes: PropTypes.object,
  showRating: PropTypes.bool,
}

Endorsement.defaultProps = {
  showRating: false,
}

export default Endorsement
