/********************************************************************
 *
 * /icons/Mask.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { SvgIcon } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles"

const Mask = ({ height = 100, width = 100, color, background = false, sx }) => {
  const classes = styles()

  return (
    <div
      className={classes.base}
      role="img"
      style={{ color: !!color ? color : undefined }}
    >
      <SvgIcon
        width={width}
        height={height}
        fill={!!color ? "currentColor" : undefined}
        viewBox="0 0 60 44"
        sx={[
          {
            transition: "color 0.5s linear",
            width: "inherit",
            height: "inherit",
          },
          !!color && {
            color: color,
            "& path": {
              fill: "inherit",
            },
          },
          sx,
        ]}
      >
        <g fill="white" fill-rule="nonzero">
          <path d="M16.7 24.955A44.625 44.625 0 0030 26.988a44.625 44.625 0 0013.3-2.033 1 1 0 00-.594-1.91 42.507 42.507 0 01-25.406 0 1 1 0 00-.594 1.91zM16.846 18.988c4.352.673 8.75 1.01 13.154 1.006a85.637 85.637 0 0013.154-1.006 1 1 0 10-.308-1.976 84.471 84.471 0 01-25.692 0 1 1 0 00-.308 1.976zM42.569 30.1a28.632 28.632 0 01-25.138 0 1 1 0 10-.862 1.8 30.6 30.6 0 0026.862 0 1 1 0 10-.862-1.8z" />
          <path d="M56 10h-5.184A3 3 0 0048 8h-1.51a12.337 12.337 0 01-9.513-4.68 8.992 8.992 0 00-13.952 0A12.338 12.338 0 0113.51 8H12a3 3 0 00-2.816 2H4a4 4 0 00-4 4v5c0 5.256 4.706 10.093 10.418 10.881a21.557 21.557 0 0017.933 14.026c1.097.124 2.203.124 3.3 0a21.557 21.557 0 0017.931-14.026C55.294 29.093 60 24.256 60 19v-5a4 4 0 00-4-4zm-44 0h1.51a14.336 14.336 0 0011.067-5.42 6.99 6.99 0 0110.849 0A14.332 14.332 0 0046.49 10H48a1 1 0 011 1v1l-3-.005h-.02A15.834 15.834 0 0133.87 5.84a4.989 4.989 0 00-7.747.009 15.821 15.821 0 01-12.1 6.144H14L11 12v-1a1 1 0 011-1zM2 19v-5a2 2 0 012-2h5v10c.003 1.932.254 3.855.745 5.723C5.772 26.766 2 23.372 2 19zm29.442 22.918c-.958.108-1.926.108-2.884 0A19.568 19.568 0 0112.12 28.661v-.01A20.5 20.5 0 0111 22v-8l3-.005a17.717 17.717 0 0013.675-6.882 2.99 2.99 0 014.645-.008 17.824 17.824 0 0013.657 6.89H46L49 14v8a20.5 20.5 0 01-1.117 6.649v.01a19.568 19.568 0 01-16.441 13.259zM58 19c0 4.372-3.772 7.766-7.745 8.725.492-1.869.742-3.793.745-5.725V12h5a2 2 0 012 2z" />
        </g>
      </SvgIcon>
    </div>
  )
}

const styles = makeStyles(theme => ({
  base: { lineHeight: "1em" },
  override: {
    transition: "color 0.5s linear",
    "& $st0, & $st1, & $st2, & $st3, & $st4, & $st6, & $st7": {
      fill: "inherit",
    },
  },
  transparent: { opacity: 0 },
}))

/*
 **  PropTypes
 */
Mask.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string,
}

export default Mask
