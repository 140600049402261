/********************************************************************
 *
 * components/Features/Feature.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2023 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"

/**
 * Feature
 *
 * @param {*} param0
 * @returns
 */
const Feature = ({ title, children }) => {
  return (
    <Box
      sx={{ display: "inline-block", borderBottom: "1px solid #dddddd", py: 4 }}
    >
      <Typography
        variant="h3"
        sx={{ mb: 4, textAlign: { xs: "center", sm: "left" } }}
      >
        {title}
      </Typography>
      <Typography variant="body1">{children}</Typography>
    </Box>
  )
}

/*
 **  PropTypes
 */
Feature.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
}

export default Feature
