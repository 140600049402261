/********************************************************************
 *
 * /components/SEO/StructuredData/BreadCrumbListSchema.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

const BreadCrumbListSchema = (...args) => {
  return {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: args.map((arg, index) => ({
      "@type": "ListItem",
      position: index,
      item: {
        "@id": arg.id,
        name: arg.name,
      },
    })),
  }
}

export default BreadCrumbListSchema
