/********************************************************************
 *
 * components/Carousel/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { Container, Box, Typography, useMediaQuery } from "@mui/material"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import { useTheme } from "@mui/material/styles"
import CarouselButton from "./CarouselButton"

/**
 * Carousel
 *
 * @param {*} param0
 * @returns
 */
const Carousel = ({ label, children }) => {
  const [index, setIndex] = useState(0)
  const scrollRef = useRef()
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.only("xs"))
  const sm = useMediaQuery(theme.breakpoints.only("sm"))

  useEffect(() => {
    if (!scrollRef || !scrollRef.current) return

    scrollRef.current.scrollLeft =
      ((scrollRef.current.scrollWidth - (xs ? 32 : 64)) / children.length) *
      index
  }, [index, scrollRef])

  return (
    <Container
      data-component-id="CAROUSEL"
      maxWidth={xs || sm ? false : "lg"}
      sx={{ px: { xs: 0, md: 4 }, mx: { xs: 0, md: "auto" } }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexWrap: "nowrap",
          pb: 4,
          px: { xs: 4, md: 0 },
        }}
      >
        {!!label && (
          <Typography variant="h3" component="h2">
            {label}
          </Typography>
        )}
        <Box sx={{ display: { xs: "flex", md: "none" }, pl: { xs: 2, md: 0 } }}>
          <CarouselButton
            ariaLabel="Previous"
            disabled={index <= 0}
            onClick={() => {
              setIndex(index - 1)
            }}
          >
            <KeyboardArrowLeft />
          </CarouselButton>
          <span style={{ marginLeft: "8px" }}></span>
          <CarouselButton
            ariaLabel="Next"
            disabled={index >= children.length - (xs ? 1 : 2)}
            onClick={() => {
              setIndex(index + 1)
            }}
          >
            <KeyboardArrowRight />
          </CarouselButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexWrap: "wrap",
          "&:nth-child(n) > *": {
            flex: "0 0",
            flexBasis: {
              md: "33%",
            },
            p: 2,
          },
        }}
      >
        {children}
      </Box>
      <Box
        ref={scrollRef}
        sx={{
          display: { xs: "flex", md: "none" },
          py: "4px",
          my: "-4px",
          scrollBehavior: "smooth",
          scrollSnapType: "x mandatory",
          overflowX: "auto",
          scrollPadding: { xs: "8px", sm: "24px", md: "initial" },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
          "&:nth-child(n) > :first-child": {
            pl: { xs: "24px", sm: "40px" },
            flex: "0 0",
            flexBasis: {
              xs: "calc((100vw - 40px) / 1.66 + 8px)",
              sm: "calc((100vw - 72px) / 2.66 + 24px)",
            },
            scrollSnapAlign: "start",
          },
          "&:nth-child(n) > *": {
            pl: "16px",
            flex: "0 0",
            flexBasis: {
              xs: "calc((100vw - 40px) / 1.66)",
              sm: "calc((100vw - 72px) / 2.66)",
            },
            scrollSnapAlign: "start",
          },
          "&:nth-child(n) > :last-child": {
            pr: { xs: "24px", sm: "40px" },
            flex: "0 0",
            flexBasis: {
              xs: "calc((100vw - 40px) / 1.66 + 24px)",
              sm: "calc((100vw - 72px) / 2.66 + 40px)",
            },
          },
          "&::-webkit-scrollbar": {
            display: "none !important",
          },
        }}
      >
        {children}
      </Box>
    </Container>
  )
}

/*
 **  PropTypes
 */
Carousel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
}

export default Carousel
