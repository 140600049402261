/********************************************************************
 *
 * Day.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"

/**
 * Day
 *
 * Renders a day cell on the calendar grid.
 *
 * onDateRender is a callback used to initialize the status of the
 * date and include any content.
 *
 * A date cell can have the following states:
 *  - active / inactive
 *  - past / present / future
 *  - available / soldout
 *
 * Parameters:
 *
 * date         : the DateTime being rendered.
 * zone         : timezone
 * activeDate   : the date currently active, which is different than the selected date
 * value        : the currently selected DateTime value on the calendar
 * onDateRender : callback to render content for the date
 * onChanged    : callback triggered when the user clicks the date
 *
 */
const Day = ({ date, zone, activeDate, value, onDateRender, onChanged }) => {
  //
  //  Error checking
  //
  if (!date) return null

  const {
    disable = false,
    soldOut = false,
    innerHTML = "",
  } = !!onDateRender ? onDateRender({ date }) : {}

  const past =
    date.startOf("day") < DateTime.fromObject({}, { zone }).startOf("day")
  const isAvailable = !disable && !past && !soldOut

  return (
    <Box
      sx={{
        display: "inline-block",
        position: "relative",
        boxSizing: "border-box",
        width: "45px",
        height: "45px",
      }}
    >
      <Box
        role="button"
        tabIndex="0"
        onClick={() => {
          !past && !soldOut && !disable && onChanged && onChanged(date)
        }}
        onKeyUp={() => {
          !past && !soldOut && !disable && onChanged && onChanged(date)
        }}
        sx={[
          {
            position: "absolute",
            boxSizing: "border-box",
            backgroundColor: "rgb(255, 255, 255)",
            borderWidth: "1.5px",
            borderStyle: "solid",
            borderColor: "rgb(255,255,255)",
            borderRadius: "50%",
            top: "1px",
            bottom: "1px",
            left: "1px",
            right: "1px",
            paddingTop: "0.3em",
            paddingBottom: "0.3em",
            paddingLeft: "0.1em",
            paddingRight: "0.1em",
          },
          past && {
            cursor: "default",
            color: "text.secondary",
            textDecoration: "line-through",
          },
          date.hasSame(activeDate, "day") && {},
          disable && {
            cursor: "default",
            color: "text.secondary",
          },
          isAvailable && {
            cursor: "pointer",
            color: "text.primary",
            transition: "0.5s",
            "&:hover": {
              outline: "none",
              borderColor: "primary.main",
            },
            "&:focus": {
              outline: "none",
            },
          },
          DateTime.isDateTime(value) &&
            value.isValid &&
            date.hasSame(value, "day") && {
              //   color: "rgb(255, 255, 255)",
              backgroundColor: "rgb(246, 246, 246)",
              borderColor: "rgb(221, 221, 221)",
            },
        ]}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {soldOut ? (
            <Typography
              sx={{
                fontSize: "0.8em",
                color: "#66c1b9",
                textTransform: "uppercase",
                fontWeight: 600,
                lineHeight: "1.2",
              }}
            >
              Sold Out
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  lineHeight: 1,
                  fontSize: "0.95rem",
                  fontWeight: 600,
                }}
              >
                {date.toFormat("d")}
              </Typography>
              <Box>{!disable && innerHTML}</Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */

Day.propType = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  zone: PropTypes.string.isRequired,
  activeDate: PropTypes.instanceOf(DateTime).required,
  value: PropTypes.instanceOf(DateTime),
  onDateRender: PropTypes.func,
  onChanged: PropTypes.func,
}

export default Day
