/********************************************************************
 *
 * Calendar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Typography, Button } from "@mui/material"
import { DateTime } from "luxon"
import Month from "./Month"

const NUM_WEEKS = 6

/**
 * Calendar
 *
 * Renders a calendar grid.
 *
 * ActiveDate   - the currently active date, which may or may not be the selected date
 *
 */
const Calendar = ({
  value,
  zone,
  min = DateTime.fromISO("1970-01-01", { zone }),
  max = DateTime.fromISO("2100-01-01", { zone }),
  onCalendarUpdating,
  onDateRender,
  onChanged,
}) => {
  const [activeDate, setActiveDate] = useState(null)
  const [loading, setLoading] = useState(false)

  //
  //  Initialize calendar boundaries
  //
  if (!min instanceof DateTime || !min.isValid)
    throw new Error("Min must be a valid DateTime object")
  if (!max instanceof DateTime || !max.isValid)
    throw new Error("Max must be a valid DateTime object")
  if (min > max) throw new Error("Minimum date is greater than maximum")

  useEffect(() => {
    let date = DateTime.min(
      max,
      DateTime.max(
        min,
        DateTime.isDateTime(value) && value.isValid ? value : DateTime.now()
      )
    )
    setActiveDate(date)
    updateCalendarView(date)
  }, [])

  /**
   * Date value has been set. Update activeDate to match and render view if necessary
   */
  useEffect(() => {
    if (DateTime.isDateTime(value) && value.isValid) onUpdateActiveDate(value)
  }, [value])

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const getViewStart = date => {
    let som = date.startOf("month")
    return som.minus({ days: som.weekday % 7 })
  }

  const getViewEnd = date => {
    return getViewStart(date)
      .plus({ days: NUM_WEEKS * 7 })
      .endOf("day")
  }

  /**
   * UpdateCalendarView
   *
   * @param {*} date
   */
  const updateCalendarView = date => {
    setLoading(true)

    //
    //  Fire CalendarUpdating event. Parameters are the range of
    //  dates that will be rendered by the calendar.
    //
    //  We sync the onCalendarUpdating callback and startDate state
    //  update so that the parent can update data before the calendar
    //  is rerendered.
    //
    Promise.race([
      onCalendarUpdating
        ? onCalendarUpdating({
            start: getViewStart(date),
            end: getViewEnd(date),
          })
        : null,
      timeout(),
    ])
      .catch(error => console.log(error.message))
      .finally(() => setLoading(false))
  }

  const timeout = () =>
    new Promise((resolve, reject) => {
      setTimeout(reject, 5000, new Error("OnCalendarUpdating Timeout"))
    })

  /////////////////////////////////////////////////////////////////////
  //
  //  Event handler methods
  //
  /////////////////////////////////////////////////////////////////////

  const onUpdateActiveDate = newDate => {
    setActiveDate(prevDate => {
      //
      //  BUGBUG: Could the new date may be in the same month, but a different range?
      //
      if (
        DateTime.isDateTime(newDate) &&
        newDate.isValid &&
        (!prevDate || !prevDate.hasSame(newDate, "month"))
      )
        updateCalendarView(newDate)

      return newDate
    })
  }

  /////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  /////////////////////////////////////////////////////////////////////

  if (!activeDate) return null

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: "315px",
        minWidth: "315px",
        fontSize: "1em",
        position: "relative",
        margin: "0 auto",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          fontSize: "1.5em",
          fontWeight: "500",
          marginBottom: "0.75em",
        }}
      >
        <Box>
          <Button
            disabled={
              loading ||
              activeDate.startOf("month") <= DateTime.now() ||
              min.startOf("day") >= activeDate.startOf("month")
            }
            onClick={() => {
              onUpdateActiveDate(activeDate.minus({ months: 1 }))
            }}
            sx={styles.calnav}
          >
            &lt;
          </Button>
        </Box>
        <Typography variant="h3">{activeDate.toFormat("LLLL")}</Typography>
        <Box>
          <Button
            disabled={loading || max.endOf("day") <= activeDate.endOf("month")}
            onClick={() => {
              onUpdateActiveDate(activeDate.plus({ months: 1 }))
            }}
            sx={styles.calnav}
          >
            &gt;
          </Button>
        </Box>
      </Box>
      <Month
        startDate={getViewStart(activeDate)}
        zone={zone}
        numWeeks={NUM_WEEKS}
        activeDate={activeDate}
        value={value}
        loading={loading}
        onDateRender={({ date }) => {
          let obj = (onDateRender && onDateRender(date)) || {}
          if (
            date.startOf("day") < min.startOf("day") ||
            date.endOf("day") > max.startOf("day")
          )
            obj["active"] = false
          return obj
        }}
        onChanged={onChanged}
      />
    </Box>
  )
}

const styles = {
  calnav: {
    color: "#666666",
    fontSize: "0.9em",
    fontWeight: "600",
    letterSpacing: "0.1em",
    padding: "0 1em",
    border: "0",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}

Calendar.propType = {
  value: PropTypes.instanceOf(DateTime),
  zone: PropTypes.string.isRequired,
  min: PropTypes.instanceOf(DateTime),
  max: PropTypes.instanceOf(DateTime),
  onDateRender: PropTypes.func,
  onCalendarUpdating: PropTypes.func,
  onChanged: PropTypes.func,
}

export default Calendar
