/********************************************************************
 *
 * /components/Divider.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { Divider as MuiDivider } from "@mui/material"

const Divider = ({ sx }) => (
  <MuiDivider
    component="div"
    sx={[
      {
        border: "none",
        borderTop: "1px solid #dddddd",
      },
      sx,
    ]}
  />
)

export default Divider
