/********************************************************************
 *
 * /components/product/Includes.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import {
  VerifiedUser,
  LocalDrink,
  LocalHospital,
  CheckCircleOutline,
  Favorite,
} from "@mui/icons-material"
import { BannerItemCard, Materialize } from "../index"

/**
 * Includes
 *
 * Renders the included features of a product
 *
 * @param {*} param0
 */
const Includes = ({ product }) => {
  const getIcon = type => {
    switch (String(type)) {
      case "safety":
        return <LocalHospital fontSize="large" />
      case "vessel":
        return <VerifiedUser fontSize="large" />
      case "drinks":
        return <LocalDrink fontSize="large" />
      case "local":
        return <Favorite fontSize="large" />
      default:
        return <CheckCircleOutline fontSize="large" />
    }
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridColumnGap: "16px",
        gridRowGap: "24px",
        flexWrap: { sm: "wrap" },
        alignItems: { sm: "stretch" },
      }}
    >
      {product.includes.map((include, index) => (
        <BannerItemCard
          key={index}
          icon={getIcon(include.type)}
          label={include.label}
          sx={{ gridColumn: { xs: "span 12", md: "span 6" } }}
        >
          <Materialize content={include.content} />
        </BannerItemCard>
      ))}
    </Box>
  )
}

/*
 **  PropTypes
 */
Includes.propTypes = {
  product: PropTypes.object,
}

export default Includes
