/********************************************************************
 *
 * /components/product/InfoSection.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { Section } from "../index"
/**
 * InfoSection
 *
 * Renders a section with product information
 *
 * @param {*} param0
 */
const InfoSection = ({
  label,
  children,
  top = false,
  divider = false,
  component = "section",
}) => (
  <Section
    fullWidth
    divider={divider}
    top={top}
    mx={{ xs: 4, md: 0 }}
    mb={{ xs: 4, md: 5 }}
    mt={{ xs: 4, md: 5 }}
    component={component}
  >
    <Box
      sx={{
        fontWeight: 700,
        margin: { xs: 0 },
        marginBottom: 3,
        fontSize: { xs: "8pt", sm: "10pt", md: "12pt", lg: "14pt" },
        letterSpacing: { sm: "0.125em", md: "0.125em", lg: "0.125em" },
      }}
    >
      <Typography variant="h2" gutterBottom>
        {label}
      </Typography>
    </Box>
    <Box>{children}</Box>
  </Section>
)

/*
 **  PropTypes
 */
InfoSection.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  divider: PropTypes.bool,
}

InfoSection.defaultProps = {}

export default InfoSection
