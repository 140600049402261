/********************************************************************
 *
 * app/orderProcessing/componenets/Search.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import {
  Typography,
  Paper,
  Box,
  Button,
  Link,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { DateTime } from "luxon"
import { Divider } from "../../../components"
import Calendar from "./calendar"
import { useSiteTools } from "../../../providers/SiteToolsProvider"

const MAX_WIDTH = "355px"

const Search = ({
  min,
  max,
  value,
  zone,
  onCalendarUpdate,
  onDateRender,
  onDateChanged,
}) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Paper
      elevation={mobile ? 0 : 3}
      sx={{
        display: "inline-block",
        boxSizing: "border-box",
        padding: { xs: 0, md: 2 },
        margin: "0 auto",
        marginBottom: 2,
        width: MAX_WIDTH,
      }}
    >
      <Calendar
        min={min}
        max={max}
        value={value}
        zone={zone}
        onCalendarUpdating={onCalendarUpdate}
        onDateRender={onDateRender}
        onChanged={onDateChanged}
      />
      <HelperText />
    </Paper>
  )
}

const HelperText = () => {
  const { onSupportRequest } = useSiteTools()

  return (
    <Box
      sx={{
        width: "315px",
        minWidth: "315px",
        mx: "auto",
        mt: 1,
        mb: { xs: 0, sm: 2, md: 0 },
      }}
    >
      <Divider />
      <Box
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="textPrimary">
          Let us know if you have any{" "}
          <Link
            variant="body2"
            onClick={onSupportRequest}
            style={{ fontWeight: 600, cursor: "pointer" }}
          >
            questions
          </Link>
          , would like to{" "}
          <Link
            variant="body2"
            onClick={onSupportRequest}
            style={{ fontWeight: 600, cursor: "pointer" }}
          >
            speak with us
          </Link>{" "}
          or even{" "}
          <Link
            variant="body2"
            onClick={onSupportRequest}
            style={{ fontWeight: 600, cursor: "pointer" }}
          >
            request times
          </Link>{" "}
          that may not appear on the calendar.
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          paddingTop: 2,
          textAlign: "center",
        }}
      >
        <Button
          variant="outlined"
          style={{ width: "100%" }}
          onClick={onSupportRequest}
        >
          Special Request
        </Button>
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */
Search.propTypes = {
  min: PropTypes.instanceOf(DateTime),
  max: PropTypes.instanceOf(DateTime),
  zone: PropTypes.string.isRequired,
  onCalendarUpdate: PropTypes.func,
  onDateRender: PropTypes.func,
  onDateChanged: PropTypes.func,
}

export default Search
