/********************************************************************
 *
 * /components/layout/header/MainMenu.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { ExpandMore } from "@mui/icons-material"
import { List, ListItem, Link, Menu, MenuItem } from "@mui/material"

/**
 * MainMenu
 *
 * Renders the side menu for smaller displays
 *
 * @param {*} props
 */
const MenuMenu = ({ items }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const onClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const onClose = () => {
    setAnchorEl(null)
  }

  return (
    <List
      component="nav"
      sx={{
        display: "flex",
        fontWeight: "700",
        border: "0",
        padding: "0 1.2em",
      }}
    >
      {items.map(({ label, hide, path, children }, index) => (
        <Fragment key={index}>
          {!hide && (
            <ListItem key={index} component="div">
              <Link
                id={`menuItem${index}`}
                variant="button"
                href={path}
                onClick={onClick}
                sx={styles.link}
              >
                <div>{label}</div> {children && <ExpandMore />}
              </Link>
              {children && (
                <Menu
                  id={`menu${index}`}
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!anchorEl && anchorEl.id === `menuItem${index}`}
                  anchorReference="anchorEl"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={onClose}
                >
                  {children.map(({ label, hide, path }, subindex) => {
                    if (hide) return null
                    return (
                      <MenuItem
                        key={subindex}
                        sx={[styles.link, styles.submenu]}
                      >
                        <Link color="textPrimary" href={path}>
                          {label}
                        </Link>
                      </MenuItem>
                    )
                  })}
                </Menu>
              )}
            </ListItem>
          )}
        </Fragment>
      ))}
    </List>
  )
}

/*
 **  Styles
 */
const styles = {
  link: {
    fontFamily: "Quicksand, Helvetica, Arial,sans-serif",
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    whiteSpace: "nowrap",
    cursor: "pointer",
    color: "text.primary",
    "&:hover": {
      color: "primary.main",
    },
  },
  submenu: {
    "&:hover": {
      backgroundColor: "background.default",
    },
  },
}

/*
 **  PropTypes
 */
MenuMenu.propTypes = {
  items: PropTypes.array.isRequired,
}

MenuMenu.defaultProps = {}

export default MenuMenu
