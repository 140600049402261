/********************************************************************
 *
 * /components/product/header.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Typography, Grid, Box, Chip } from "@mui/material"
import {
  ArrowRight,
  WatchLater,
  SupervisedUserCircle,
  PlaylistAddCheck,
} from "@mui/icons-material"
import { Section, Sandwich, Endorsement, Tag } from "../index"
import MasonryGallery from "./masonryGallery"

/**
 * ProductHeader
 *
 * Renders the header portion of a product page.
 *
 * @param {*} param0
 */
const ProductHeader = ({ product }) => (
  <Section maxWidth="lg" backgroundColor="primary.dark">
    {/* Gallery */}
    <MasonryGallery media={product.images} />
    {/* Text Content */}
    <Grid container>
      <Grid item xs={12} md={5} lg={4}>
        <Box
          sx={{
            paddingRight: { md: "4em" },
          }}
        >
          <Chip
            icon={<ArrowRight fontSize="large" />}
            component="h2"
            label={product.category}
            sx={{
              color: "rgba(0, 0, 0, 0.87)",
              backgroundColor: "#e0e0e0",
              borderRadius: "5px",
              border: "none",
              fontSize: "0.71em",
              textTransform: "uppercase",
              letterSpacing: "0.25em",
              margin: "0",
              "& .MuiChip-root": {},
            }}
          />
          <Typography
            variant="h1"
            style={{}}
            sx={{
              color: "primary.contrastText",
              marginTop: "0.5em",
              marginBottom: "0.5em",
              fontSize: {
                xs: "1.75em",
                sm: "2em",
                md: "2.25em",
                lg: "2.5em",
              },
              letterSpacing: {
                sm: "0.125em",
                md: "0.125em",
                lg: "0.125em",
              },
            }}
          >
            {product.name}
          </Typography>
          <Box
            sx={{
              marginBottom: "1em",
              width: { sm: "80%", md: "100%" },
            }}
          >
            {product.tags.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} style={{ color: "#ffffff" }}>
        <Sandwich sx={{ display: "block", mt: 0, mb: 2 }}>
          <Endorsement
            classes={{
              quote: {
                marginBottom: "0.8em",
              },
              name: { fontSize: "0.8em" },
            }}
          />
        </Sandwich>
        <Box display="flex">
          <Feature
            icon={<WatchLater />}
            title="Duration"
            content={product.time}
            size="33%"
          />
          <Feature
            icon={<SupervisedUserCircle />}
            title="Group size"
            content={product.capacity}
            size="33%"
          />
          <Feature
            icon={<PlaylistAddCheck />}
            title="Includes"
            content={product.includes.map((item, index) => (
              <React.Fragment key={index}>
                {index > 0 && <br />}
                {item.label}
              </React.Fragment>
            ))}
            size="33%"
          />
        </Box>
      </Grid>
    </Grid>
  </Section>
)

/**
 * Feature
 *
 * Renders an included feature in the product page header
 *
 * @param {*} param0
 */
const Feature = ({ icon, title, content, size = "25%" }) => (
  <Box style={{ width: size, paddingRight: "1em" }}>
    {icon}
    <Typography variant="body2">{title}</Typography>
    <Typography
      variant="body2"
      sx={{
        letterSpacing: "0.05em",
        fontWeight: 600,
      }}
    >
      {content}
    </Typography>
  </Box>
)

/*
 **  PropTypes
 */
ProductHeader.propTypes = {
  product: PropTypes.object,
}

export default ProductHeader
