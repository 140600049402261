/********************************************************************
 *
 * /components/spotlight.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Container, Typography, Link, Box, Button } from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/**
 * Spotlight
 *
 * Renders a featured product
 *
 */
const Spotlight = ({ id, product, flip, page }) => (
  <Container
    id={id}
    maxWidth="md"
    sx={{
      display: "flex",
      flexDirection: { xs: "column", lg: flip ? "row-reverse" : "row" },
      alignItems: { xs: "center", lg: "flex-start" },
      flexWrap: "nowrap",
      boxSizing: "border-box",
      pt: 10,
      width: "100%",
    }}
  >
    {/* Image */}
    <Box
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        paddingLeft: { lg: flip && 6 },
        paddingRight: { lg: !flip && 6 },
        paddingBottom: { xs: 5, md: 8, lg: 0 },
      }}
    >
      <LinkImage product={product} />
    </Box>
    {/* Text */}
    <Box
      sx={{
        flexGrow: 1,
        flexShrink: 1,
      }}
    >
      <LinkText product={product} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: "center",
        }}
      >
        {product.page && product.page.url && (
          <Box sx={{ paddingTop: 3 }}>
            <Button
              variant="outlined"
              href={product.page.url}
              sx={{
                color: "primary.main",
                textAlign: "center",
                "&:visited": {
                  color: "primary.main",
                },
              }}
            >
              {`Book a ${product.name}`}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  </Container>
)

/**
 * LinkImage
 *
 * Container for spotlight link and image
 *
 * @param {*} param0
 */
const LinkImage = ({ product }) => (
  <Box
    sx={{
      position: "relative",
      borderRadius: "50%",
      overflow: "hidden",
      width: { xs: "250px", md: "400px", lg: "250px" },
      height: { xs: "250px", md: "400px", lg: "250px" },
    }}
  >
    <Link href={product.page.url}>
      <GatsbyImage
        image={getImage(product.image.img)}
        alt={product.image.alt}
        loading="lazy"
        imgStyle={{ borderRadius: "50%" /* Fixes Safari Bug */ }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: "50%",
        }}
      />
    </Link>
  </Box>
)

/**
 * LinkText
 *
 * Container for spotlight link and image
 *
 * @param {*} param0
 */
const LinkText = ({ product }) => (
  <Box sx={{ textAlign: { xs: "center", lg: "left" } }}>
    <Link href={product.page.url}>
      <Typography
        component="h3"
        sx={{
          color: "spotlight.dark",
          fontFamily: "Oswald, sans-serif",
          textTransform: "uppercase",
          fontWeight: 500,
          lineHeight: "1.25em",
          letterSpacing: "normal",
          fontSize: {
            xs: "1.33rem",
            sm: "1.8rem",
            md: "2.25rem",
            lg: "2.7rem",
          },
        }}
      >
        {product.name}
      </Typography>
      <Typography
        component="h4"
        sx={{
          color: "spotlight.main",
          fontFamily: "PT Sans Narrow, sans-serif",
          fontWeight: 400,
          lineHeight: "1.25em",
          letterSpacing: "normal",
          fontSize: { xs: "1.22rem", sm: "1.7rem", md: "1.9rem", lg: "2.1rem" },
          mt: { xs: 0.5 },
        }}
      >
        {product.tagLine}
      </Typography>
      <Typography
        paragraph
        sx={{
          color: "spotlight.light",
          fontFamily: "PT Sans Narrow, sans-serif",
          fontWeight: 400,
          lineHeight: "1.25em",
          letterSpacing: "normal",
          paddingTop: "0.5em",
          fontSize: { xs: "1.2rem", sm: "1.21rem", md: "1.4rem" },
        }}
      >
        {product.target}
      </Typography>
      <Typography
        paragraph
        sx={{
          color: "spotlight.dark",
          fontFamily: "PT Sans Narrow, sans-serif",
          textTransform: "uppercase",
          fontWeight: 400,
          lineHeight: "1.25em",
          letterSpacing: "normal",
          marginTop: "0.25em",
          fontSize: { xs: "1.2rem", sm: "1.4rem", md: "1.7rem", lg: "1.9rem" },
        }}
      >
        {product.time}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.primary",
          marginTop: "1em",
        }}
      >
        {product.description}
      </Typography>
    </Link>
  </Box>
)

/*
 **  PropTypes
 */
Spotlight.propTypes = {
  id: PropTypes.string,
  flip: PropTypes.bool,
  product: PropTypes.object,
  action: PropTypes.object,
}

Spotlight.defaultProps = {
  flip: true,
}

export default Spotlight
