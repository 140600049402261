/********************************************************************
 *
 * /components/Policies.jsx
 *
 * Copyright 2023 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Policy from "./Policy"

const Policies = () => {
  const { policies } = useStaticQuery(
    graphql`
      query {
        policies: allMysqlPolicies(filter: { mysqlId: { in: [5, 7, 10] } }) {
          nodes {
            id
            name
            policy
          }
        }
      }
    `
  )

  return policies.nodes.map((node, index) => (
    <Policy key={index} policy={node} />
  ))
}

/*
 **  PropTypes
 */
Policies.propTypes = {
  children: PropTypes.node,
}

export default Policies
