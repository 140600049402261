/********************************************************************
 *
 * Tags.js
 *
 * Copyright 2024 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

export const fireEvent = (name, data) => {
  //
  //  If the event does not have a name, throw an exception in debug
  //  mode, or exit gracefully in production mode.
  //
  if (process.env.DEBUG) {
    if (!name) throw new Error("Tag events require a name")
  }

  if (!name) return

  //
  //    NB: The window object is undefined during the Gatsby build
  //    process, and dataLayer is a property of the window object.
  //    Therefore, we need to check for the existance of the object
  //    to avoid a crash in the development build process.
  //
  if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(Object.assign({ event: name }, data))
  }
}
