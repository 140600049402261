exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-affiliate-slug-sailing-tours-js": () => import("./../../../src/pages/a/[affiliateSlug]/sailing-tours.js" /* webpackChunkName: "component---src-pages-a-affiliate-slug-sailing-tours-js" */),
  "component---src-pages-captains-log-index-jsx": () => import("./../../../src/pages/captains-log/index.jsx" /* webpackChunkName: "component---src-pages-captains-log-index-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-commercial-film-location-sailboat-services-jsx": () => import("./../../../src/pages/commercial/film-location-sailboat-services.jsx" /* webpackChunkName: "component---src-pages-commercial-film-location-sailboat-services-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-deals-last-minute-sailing-jsx": () => import("./../../../src/pages/deals/last-minute/sailing.jsx" /* webpackChunkName: "component---src-pages-deals-last-minute-sailing-jsx" */),
  "component---src-pages-gifting-sailing-gift-cards-and-vouchers-jsx": () => import("./../../../src/pages/gifting/sailing-gift-cards-and-vouchers.jsx" /* webpackChunkName: "component---src-pages-gifting-sailing-gift-cards-and-vouchers-jsx" */),
  "component---src-pages-giving-community-support-index-jsx": () => import("./../../../src/pages/giving/community-support/index.jsx" /* webpackChunkName: "component---src-pages-giving-community-support-index-jsx" */),
  "component---src-pages-groups-birthday-activities-old-jsx": () => import("./../../../src/pages/groups/birthday-activities-old.jsx" /* webpackChunkName: "component---src-pages-groups-birthday-activities-old-jsx" */),
  "component---src-pages-groups-corporate-incentives-jsx": () => import("./../../../src/pages/groups/corporate-incentives.jsx" /* webpackChunkName: "component---src-pages-groups-corporate-incentives-jsx" */),
  "component---src-pages-groups-group-landing-pages-json-slug-jsx": () => import("./../../../src/pages/groups/{GroupLandingPagesJson.slug}.jsx" /* webpackChunkName: "component---src-pages-groups-group-landing-pages-json-slug-jsx" */),
  "component---src-pages-groups-sailboat-adventure-weddings-jsx": () => import("./../../../src/pages/groups/sailboat-adventure-weddings.jsx" /* webpackChunkName: "component---src-pages-groups-sailboat-adventure-weddings-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-linktree-jsx": () => import("./../../../src/pages/linktree.jsx" /* webpackChunkName: "component---src-pages-linktree-jsx" */),
  "component---src-pages-location-jsx": () => import("./../../../src/pages/location.jsx" /* webpackChunkName: "component---src-pages-location-jsx" */),
  "component---src-pages-order-js": () => import("./../../../src/pages/order.js" /* webpackChunkName: "component---src-pages-order-js" */),
  "component---src-pages-p-sailing-charters-private-sailboat-charter-jsx": () => import("./../../../src/pages/p/sailing-charters/private-sailboat-charter.jsx" /* webpackChunkName: "component---src-pages-p-sailing-charters-private-sailboat-charter-jsx" */),
  "component---src-pages-p-sailing-tours-index-jsx": () => import("./../../../src/pages/p/sailing-tours/index.jsx" /* webpackChunkName: "component---src-pages-p-sailing-tours-index-jsx" */),
  "component---src-pages-p-sailing-tours-sailing-experience-jsx": () => import("./../../../src/pages/p/sailing-tours/sailing-experience.jsx" /* webpackChunkName: "component---src-pages-p-sailing-tours-sailing-experience-jsx" */),
  "component---src-pages-p-sailing-tours-sunset-sailboat-cruise-jsx": () => import("./../../../src/pages/p/sailing-tours/sunset-sailboat-cruise.jsx" /* webpackChunkName: "component---src-pages-p-sailing-tours-sunset-sailboat-cruise-jsx" */),
  "component---src-pages-p-vouchers-vouchers-json-slug-jsx": () => import("./../../../src/pages/p/vouchers/{VouchersJson.slug}.jsx" /* webpackChunkName: "component---src-pages-p-vouchers-vouchers-json-slug-jsx" */),
  "component---src-pages-partner-jsx": () => import("./../../../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-policies-index-jsx": () => import("./../../../src/pages/policies/index.jsx" /* webpackChunkName: "component---src-pages-policies-index-jsx" */),
  "component---src-pages-shop-sailing-gift-certificate-jsx": () => import("./../../../src/pages/shop/sailing-gift-certificate.jsx" /* webpackChunkName: "component---src-pages-shop-sailing-gift-certificate-jsx" */),
  "component---src-pages-shop-thank-you-index-jsx": () => import("./../../../src/pages/shop/thank-you/index.jsx" /* webpackChunkName: "component---src-pages-shop-thank-you-index-jsx" */),
  "component---src-pages-theme-jsx": () => import("./../../../src/pages/theme.jsx" /* webpackChunkName: "component---src-pages-theme-jsx" */),
  "component---src-pages-weatherproof-booking-protection-jsx": () => import("./../../../src/pages/weatherproof-booking-protection.jsx" /* webpackChunkName: "component---src-pages-weatherproof-booking-protection-jsx" */),
  "component---src-templates-blog-jsx-content-file-path-home-vsts-work-1-s-src-data-blog-howe-sound-index-mdx": () => import("./../../../src/templates/blog.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/blog/howe-sound/index.mdx" /* webpackChunkName: "component---src-templates-blog-jsx-content-file-path-home-vsts-work-1-s-src-data-blog-howe-sound-index-mdx" */),
  "component---src-templates-blog-jsx-content-file-path-home-vsts-work-1-s-src-data-blog-squamish-wind-index-mdx": () => import("./../../../src/templates/blog.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/blog/squamish-wind/index.mdx" /* webpackChunkName: "component---src-templates-blog-jsx-content-file-path-home-vsts-work-1-s-src-data-blog-squamish-wind-index-mdx" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-markets-sailboat-tour-index-js": () => import("./../../../src/templates/markets/sailboat-tour/index.js" /* webpackChunkName: "component---src-templates-markets-sailboat-tour-index-js" */),
  "component---src-templates-partners-index-js": () => import("./../../../src/templates/partners/index.js" /* webpackChunkName: "component---src-templates-partners-index-js" */),
  "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-cancellation-mdx": () => import("./../../../src/templates/policy.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/policies/cancellation.mdx" /* webpackChunkName: "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-cancellation-mdx" */),
  "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-gift-certificate-and-voucher-terms-mdx": () => import("./../../../src/templates/policy.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/policies/gift-certificate-and-voucher-terms.mdx" /* webpackChunkName: "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-gift-certificate-and-voucher-terms-mdx" */),
  "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-privacy-mdx": () => import("./../../../src/templates/policy.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/policies/privacy.mdx" /* webpackChunkName: "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-privacy-mdx" */),
  "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-terms-mdx": () => import("./../../../src/templates/policy.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/policies/terms.mdx" /* webpackChunkName: "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-terms-mdx" */),
  "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-weather-safety-mdx": () => import("./../../../src/templates/policy.jsx?__contentFilePath=/home/vsts/work/1/s/src/data/policies/weather-safety.mdx" /* webpackChunkName: "component---src-templates-policy-jsx-content-file-path-home-vsts-work-1-s-src-data-policies-weather-safety-mdx" */)
}

