/********************************************************************
 *
 * /themes/default.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles"

var theme = createTheme({
  palette: {
    primary: {
      light: "#66c1b9",
      main: "#01899f",
      dark: "#0c4964",
      contrastText: "#eeeeee",
    },
    secondary: {
      light: "#ffffff",
      main: "#66c1b9",
      dark: "#01899f",
      contrastText: "#666666",
    },
    text: {
      primary: "#484848",
      secondary: "#999999",
    },
    action: {
      active: "#484848",
      hover: "#ebebeb",
      selected: "#01899f",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    spotlight: {
      light: "#425957",
      main: "#01899f",
      dark: "#2e3842",
    },
    highlight: {
      light: "#eeeeee",
      main: "#eeeeee",
      dark: "#eeeeee",
      contrastText: "#eeeeee",
    },
    lightBanner: {
      light: "#ffffff",
      main: "#f8f8f8",
    },
    darkBanner: {
      light: "#eeeeee",
      main: "#0c4964",
    },
  },
  typography: {
    fontFamily: "Quicksand, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    body1: {},
    body2: {},
    subtitle1: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 800,
    },
    subtitle2: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 200,
    },
    h1: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: 40,
      letterSpacing: "normal",
      textTransform: "none",
      lineHeight: 1.25,
      color: "#2e3842",
    },
    h2: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: 30,
      letterSpacing: "0.01em",
      lineHeight: 1.25,
      color: "#2e3842",
    },
    h3: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: 22,
      letterSpacing: "0.01em",
      textTransform: "none",
      lineHeight: 1.25,
      color: "#2e3842",
    },
    h4: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: 16.5,
      letterSpacing: "0.112em",
      textTransform: "uppercase",
      color: "#2e3842",
    },
    h5: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: 15,
      letterSpacing: "0.112em",
      color: "#2e3842",
    },
    h6: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: 18,
      fontStyle: "italic",
      letterSpacing: "0.112em",
      color: "#2e3842",
    },
    button: {
      fontWeight: 800,
      fontSize: "1rem",
    },
    hero1: {
      fontFamily: "Raleway, Helvetica, Arial, sans-serif",
      fontWeight: 800,
      fontSize: "2rem",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      textTransform: "none",
      color: "#ffffff",
      paddingBottom: "24px",
      "@media (min-width:600px)": {
        fontSize: "2.5rem",
      },
      "@media (min-width:900px)": {
        fontSize: "3rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "3.5rem",
      },
      "@media (min-width:153600px)": {
        fontSize: "4rem",
      },
    },
    hero2: {
      fontFamily: "Raleway, Helvetica, Arial, sans-serif",
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: "normal",
      letterSpacing: "0.1px",
      textTransform: "none",
      color: "#ffffff",
      paddingBottom: "16px",
    },
    hero3: {
      fontFamily: "Quicksand, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: "1.2em",
      letterSpacing: "0.225em",
      color: "#ffffff",
      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
    },
    banner: {
      fontFamily: "Quicksand, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: "1em",
      lineHeight: 2,
    },
    feature: {
      color: "#000000",
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "26px",
      letterSpacing: "0.1em",
      "@media (min-width:900px)": {
        fontSize: "24px",
        lineHeight: "30px",
      },
    },
    splashTitle: {
      display: "inline-block",
      color: "#2e3842",
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: "50px",
      lineHeight: 1.1,
      letterSpacing: "-0.025em",
      textAlign: "center",
      marginBottom: "50px",
      "@media (min-width:600px)": {
        fontSize: "60px",
        lineHeight: 1.1,
        marginBottom: "60px",
      },
      "@media (min-width:900px)": {
        fontSize: "70px",
        lineHeight: 1.25,
        marginBottom: "70px",
      },
      "@media (min-width:1200px)": {
        fontSize: "85px",
        lineHeight: 1.25,
        marginBottom: "80px",
      },
    },
    splashSubTitle: {
      display: "inline-block",
      color: "#2e3842",
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: "26px",
      letterSpacing: "0.01em",
      lineHeight: 1.25,
      textAlign: "center",
      marginBottom: "20px",
      "@media (min-width:600px)": {
        fontSize: "34px",
      },
      "@media (min-width:900px)": {
        fontSize: "40px",
      },
      "@media (min-width:1200px)": {
        fontSize: "48px",
      },
    },
    pageTitle: {
      display: "inline-block",
      color: "#2e3842",
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: 40,
      marginRight: "10px",
    },
    pageTitleScript: {
      display: "inline-block",
      position: "relative",
      color: "#01899f",
      fontFamily: "Marck Script, Arial, sans-serif",
      fontWeight: 400,
      fontSize: 50,
      letterSpacing: 0,
      textTransform: "none",
      transformOrigin: "20% 100%",
      transform: "rotate(-7deg)",
      marginRight: "10px",
    },
    splitScreenTitle: {
      fontFamily: "Lato, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      textTransform: "uppercase",
      letterSpacing: "0.112em",
      lineHeight: 1.25,
      "@media (min-width:900px)": {
        fontSize: "1.5rem",
      },
      "@media (min-width:1200px)": {
        fontSize: "2rem",
      },
    },
    label: {
      fontFamily: "Quicksand, Helvetica, Arial, sans-serif",
      fontWeight: 800,
      fontSize: "0.7em",
      textAlign: "left",
      textTransform: "uppercase",
    },
    imageLink: {
      fontFamily: "Quicksand, Helvetica, Arial, sans-serif",
      fontWeight: 700,
      fontSize: "1.1em",
      lineHeight: 1.5,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: { marginBottom: "2rem" },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
})

theme = responsiveFontSizes(theme, { factor: 2 })

const DefaultThemeProvider = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default DefaultThemeProvider
