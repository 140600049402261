/********************************************************************
 *
 * components/product/index.jsx
 *
 * Copyright 22022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React, { useRef } from "react"
import PropTypes from "prop-types"
import Header from "./header"
import Body from "./body"
import Footer from "./footer"

/**
 * Product
 *
 * Renders a product page
 *
 * @param {*} props
 */
const Product = ({ product, sims }) => {
  const calendarRef = useRef()

  const onShowCalendar = () => {
    calendarRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <>
      <Header product={product} />
      <Body product={product} sims={sims} calendarRef={calendarRef} />
      <Footer product={product} onShowCalendar={onShowCalendar} />
    </>
  )
}

/*
 **  PropTypes
 */
Product.propTypes = {
  product: PropTypes.object.isRequired,
  sims: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}

export default Product
