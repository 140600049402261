/********************************************************************
 *
 * MasonryGallery.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Container, Box, Hidden } from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/**
 * MasonryGallery
 *
 * Renders an image gallery in masonry layout
 *
 * @param {*} param0
 */
const MasonryGallery = ({ media }) => {
  return (
    <Box
      sx={{
        width: "100%",
        mt: { xs: 6 },
        mb: { xs: 4 },
      }}
    >
      <Container maxWidth="lg" sx={{ padding: { xs: 0 } }}>
        <Box
          display="block"
          sx={{
            borderRadius: 2,
            overflow: "hidden",
            height: "100%",
            width: "100%",
            zIndex: "1",
          }}
        >
          <Box
            sx={{
              boxSizing: "border-box",
              display: "grid",
              gap: 1,
              gridTemplateColumns: { xs: "1fr", md: "1fr 1.5fr 1fr" },
            }}
          >
            {/* Column 1 */}
            <Hidden mdDown>
              <Box
                sx={[
                  {
                    gridArea: { md: "1/1/auto/auto" },
                  },
                  styles.galleryCell,
                ]}
              >
                <GatsbyImage
                  image={getImage(media[0].img)}
                  alt={media[0].alt}
                  placeholder="blurred"
                  loading="eager"
                  imgStyle={{ zIndex: "0" }}
                  style={styles.galleryImage}
                />
              </Box>
            </Hidden>
            {/* Column 2 & 3 */}
            <Box
              sx={[
                { gridArea: { xs: "1/1/auto/auto", md: "1/2/auto/auto" } },
                styles.galleryCell,
              ]}
            >
              <MobileGallery media={media} />
            </Box>
            {/* Column 4 */}
            <Hidden mdDown>
              <Box
                sx={[
                  {
                    gridArea: { md: "1/3/auto/auto" },
                  },
                  styles.galleryCell,
                ]}
              >
                <GatsbyImage
                  image={getImage(media[4].img)}
                  alt={media[4].alt}
                  placeholder="blurred"
                  loading="eager"
                  imgStyle={{ zIndex: "0" }}
                  style={styles.galleryImage}
                />
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const MobileGallery = ({ media }) => (
  <Box
    sx={[
      {
        boxSizing: "border-box",
        display: "grid",
        gridAutoColumns: "1fr",
        gridAutoRows: "1fr",
        gap: 1,
        gridTemplateColumns: "1fr 1fr 1fr",
        height: "100%",
      },
    ]}
  >
    {/* Column 2 */}
    <Box sx={[{ gridArea: "1/1/span 2/span 2" }, styles.galleryCell]}>
      <GatsbyImage
        image={getImage(media[1].img)}
        alt={media[1].alt}
        placeholder="blurred"
        loading="eager"
        imgStyle={{ zIndex: "0" }}
        style={styles.galleryImage}
      />
    </Box>
    {/* Column 3 */}
    <Box sx={[{ gridArea: "1/3/auto/auto" }, styles.galleryCell]}>
      <GatsbyImage
        image={getImage(media[2].img)}
        alt={media[2].alt}
        placeholder="blurred"
        loading="eager"
        imgStyle={{ zIndex: "0" }}
        style={styles.galleryImage}
      />
    </Box>
    <Box sx={[{ gridArea: "2/3/auto/auto" }, styles.galleryCell]}>
      <GatsbyImage
        image={getImage(media[3].img)}
        alt={media[3].alt}
        placeholder="blurred"
        loading="eager"
        imgStyle={{ zIndex: "0" }}
        style={styles.galleryImage}
      />
    </Box>
  </Box>
)
const styles = {
  galleryImage: {
    boxSizing: "border-box",
    height: "100%",
    objectFit: "cover",
    objectPosition: "50% 50%",
    overflow: "hidden",
    "& img": { zIndex: "0" },
  },
  galleryCell: {
    display: "block",
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
  },
}

/*
 **  PropTypes
 */
MasonryGallery.propTypes = {
  media: PropTypes.array,
}

export default MasonryGallery
