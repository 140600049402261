/********************************************************************
 *
 * /components/SEO/StructuredData/LocalBusinessSchema.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import { useStaticQuery, graphql } from "gatsby"

const LocalBusinessSchema = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          name
          title
          description
          author
        }
      }
    }
  `)

  return {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "@id": `${site.siteMetadata.siteUrl}/#LocalBusiness`,
    name: site.siteMetadata.name,
    url: site.siteMetadata.siteUrl,
    logo: `${site.siteMetadata.siteUrl}/images/canadian-coastal-sailing-sm.png`,
    image: [
      `${site.siteMetadata.siteUrl}/images/stock/1x1/sailing-squamish.png`,
      `${site.siteMetadata.siteUrl}/images/stock/4x3/sailing-squamish.png`,
      `${site.siteMetadata.siteUrl}/images/stock/16x9/sailing-squamish.png`,
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: "37778 Loggers Ln",
      addressLocality: "Squamish",
      addressRegion: "BC",
      postalCode: "V8B0S8",
      addressCountry: "CA",
    },
    sameAs: [
      "https://www.instagram.com/canadiancoastal/",
      "https://en.wikipedia.org/wiki/Boat_tour/",
      "mailto:skipper@canadiancoastal.com",
    ],
    geo: {
      "@type": "GeoCoordinates",
      "@id": `${site.siteMetadata.siteUrl}/#Geo`,
      latitude: "49.6945523",
      longitude: "-123.1545193",
      name: "Canadian Coastal Sailing Departure Location",
      description:
        "Sail tours departing from the docks of the Squamish Harbour Authority",
    },
    telephone: "",
    priceRange: "$$$",
  }
}

export default LocalBusinessSchema
