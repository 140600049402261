/********************************************************************
 *
 * components/tourPicker/SKUs.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import {
  Card,
  CardContent,
  Grid,
  Box,
  Avatar,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Divider } from "../../../../components"
import SKU from "./SKU"

/**
 * SKUs
 *
 * Renders a description and set of SKUs
 *
 */
const SKUs = ({ skus }) => {
  const theme = useTheme()
  const md = useMediaQuery(theme.breakpoints.only("md"))
  const { SKUMeta } = useStaticQuery(graphql`
    query {
      SKUMeta: allSkusJson {
        nodes {
          items {
            id
            name
            selector
            unit
          }
        }
      }
    }
  `)
  const SKUData = SKUMeta.nodes[0]

  if (!skus || !skus.length) return null

  return (
    <Card
      elevation={!md ? 1 : 0}
      sx={{
        maxWidth: "500px",
        margin: "0 auto",
        marginBottom: 3,
      }}
    >
      <CardContent>
        <Description {...skus[0]} />
        {skus.map((sku, index) => (
          <React.Fragment key={sku.id}>
            {index > 0 && index < skus.length && <Divider sx={{ my: 2 }} />}
            <SKU
              sku={sku}
              meta={SKUData.items.find(item => item.id == sku.productTypeId)}
            />
          </React.Fragment>
        ))}
      </CardContent>
    </Card>
  )
}

const Description = ({ name, imageURL, target, shortDescription }) => (
  <>
    <Grid
      container
      alignItems="center"
      spacing={2}
      wrap="nowrap"
      sx={{
        pb: 2,
      }}
    >
      <Grid item>
        <Avatar
          alt={name}
          src={imageURL}
          sx={{
            width: 64,
            height: 64,
          }}
        />
      </Grid>
      <Grid item sx={{ flexGrow: "2" }}>
        <Typography
          variant="h3"
          sx={{
            color: "#2e3842",
            fontFamily: "Oswald, sans-serif",
            textTransform: "uppercase",
            fontWeight: 500,
            lineHeight: "1.75em",
            letterSpacing: "normal",
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: "primary.main",
            fontFamily: "PT Sans Narrow, sans-serif",
            textTransform: "uppercase",
            fontWeight: 400,
            lineHeight: "1.25em",
            letterSpacing: "normal",
          }}
        >
          {target}
        </Typography>
      </Grid>
    </Grid>
    <Box
      sx={{
        pb: 2,
      }}
    >
      <Typography variant="body2">{shortDescription}</Typography>
    </Box>
  </>
)
/*
 **  PropTypes
 */

SKUs.propTypes = {
  skus: PropTypes.array.isRequired,
}

export default SKUs
