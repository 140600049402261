/********************************************************************
 *
 * /icons/Cart.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { SvgIcon } from "@mui/material"

const Cart = ({ primary, secondary }) => (
  <SvgIcon viewBox="0 0 576 512">
    <g>
      <path
        fill={secondary}
        d="M552 64H159.21l52.36 256h293.15a24 24 0 0 0 23.4-18.68l47.27-208a24 24 0 0 0-18.08-28.72A23.69 23.69 0 0 0 552 64z"
      ></path>
      <path
        fill={primary}
        d="M218.12 352h268.42a24 24 0 0 1 23.4 29.32l-5.52 24.28a56 56 0 1 1-63.6 10.4H231.18a56 56 0 1 1-67.05-8.57L93.88 64H24A24 24 0 0 1 0 40V24A24 24 0 0 1 24 0h102.53A24 24 0 0 1 150 19.19z"
      ></path>
    </g>
  </SvgIcon>
)

export default Cart
