/********************************************************************
 *
 * app/orderProcessing/components/DesktopBar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Button, IconButton } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { keyframes } from "@mui/system"
import ProductTypePicker from "./ProductTypePicker"
import DatePicker from "./DatePicker"
import VerticalDivider from "./VerticalDivider"

const spin = keyframes`
0% {
    transform: scale(1);
  }
  45% {
    transform: scale(0.97);
  }
  100% {
    transform: scale(1);
  }
`

/**
 * DesktopBar
 *
 * Renders a search bar for desktop layouts
 *
 */
const DesktopBar = ({
  defaultPT,
  productTypes,
  ptName,
  selectedPT,
  onSelectPT,
  selectedDate,
  loading,
  onMonthChange,
  onDateStatus,
  onChangeDate,
  minDate,
  maxDate,
  onSubmit,
}) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Render
   */
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        textAlign: "left",
        backgroundColor: "secondary.light",
        borderRadius: "33px",
        border: "1px solid rgb(221,221,221)",
        boxShadow:
          "0px 16px 32px rgb(0 0 0 / 15%), 0px 3px 8px rgb(0 0 0 / 10%)",
        height: "66px",
        width: "100%",
      }}
    >
      <Box sx={{ flexBasis: "0%", flexGrow: 2, flexShrink: 0 }}>
        <ProductTypePicker
          defaultPT={defaultPT}
          productTypes={productTypes}
          ptName={ptName}
          value={selectedPT}
          onSelect={onSelectPT}
        />
      </Box>
      <VerticalDivider />
      <Box sx={{ flexBasis: "0%", flexGrow: 1.5, flexShrink: 0 }}>
        <DatePicker
          value={selectedDate}
          loading={loading}
          onMonthChange={onMonthChange}
          onDateStatus={onDateStatus}
          onChange={onChangeDate}
          min={minDate}
          max={maxDate}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: "auto",
          padding: "0 9px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          disabled={loading}
          onClick={onSubmit}
          sx={{
            display: { xs: "none", md: "flex" },
            backgroundColor: "primary.dark",
            color: "primary.contrastText",
            borderRadius: "33px",
            padding: "0 16px",
            flexGrow: "0",
            height: "48px",
            "&.Mui-disabled": {
              backgroundColor: "primary.dark",
              color: "#aaaaaa",
              animation: `${spin} 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1)`,
            },
          }}
        >
          Search
        </Button>
        <IconButton
          aria-label="search"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{
            display: { xs: "flex", md: "none" },
            backgroundColor: "primary.dark",
            color: "primary.contrastText",
            flexGrow: "0",
            height: "48px",
            width: "48px",
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

/*
 **  PropTypes
 */
DesktopBar.propTypes = {}

export default DesktopBar
