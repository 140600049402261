/********************************************************************
 *
 * /components/BentoBox.js
 *
 * Copyright 2024 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"

/**
 * Renders an Bento Box container
 *
 * @param {*} param0
 * @returns
 */
const BentoBox = ({ children }) => (
  <Box
    data-component-id="BENTO_BOX"
    sx={{
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridColumnGap: "16px",
      gridRowGap: "16px",
      alignContent: "stretch",
      alignItems: "stretch",
      my: 0,
    }}
  >
    {children.map((child, index) => (
      <Box
        key={index}
        sx={{
          gridColumn: { xs: "span 12", sm: "span 6", md: "span 4" },
        }}
      >
        {child}
      </Box>
    ))}
  </Box>
)

/*
 **  PropTypes
 */
BentoBox.propTypes = {
  child: PropTypes.arrayOf(PropTypes.element),
}

export default BentoBox
