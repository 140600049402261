/********************************************************************
 *
 * /components/SEO/StructuredData/Product.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import { DateTime } from "luxon"

const ProductSchema = (product, location) => {
  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: product.name,
    description: product.tagLine,
    image: [
      `https://canadiancoastal.com${product.image.img.childImageSharp.gatsbyImageData.images.fallback.src}`,
    ],
    sku: `PT${product.id}`,
    mpn: `CCPT${product.id}`,
    brand: {
      "@type": "Brand",
      name: "Canadian Coastal Sailing",
    },
    offers: [
      {
        "@type": "Offer",
        price: product.pricing.amount,
        priceCurrency: "CAD",
        url: `https://canadiancoastal.com${location.pathname}`,
        priceValidUntil: DateTime.now().plus({ week: 1 }).toISO(),
        availability: "https://schema.org/OnlineOnly",
        seller: {
          "@type": "Organization",
          name: "Canadian Coastal Sailing",
        },
      },
    ],
    review: {
      "@type": "Review",
      reviewRating: {
        "@type": "Rating",
        ratingValue: product.ratings.average,
        bestRating: "5",
      },
      author: {
        "@type": "Person",
        name: product.ratings.reviews[0].author,
      },
      reviewBody: product.ratings.reviews[0].text,
      dateCreated: product.ratings.reviews[0].date,
      publisher: {
        "@type": "Organization",
        name: product.ratings.reviews[0].source,
      },
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: product.ratings.average,
      reviewCount: product.ratings.count,
    },
  }
}

export default ProductSchema
