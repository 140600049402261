/********************************************************************
 *
 * components/category/index.jsx
 *
 * Copyright 2024 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Marquee } from "../index"
import ProductList from "../ProductList"
import SearchCriteria from "./tips/Index"
import { Section, Endorsement, ThingsToDo, AdvantageBanner } from "../index"

/**
 * Category
 *
 * Renders a category page
 *
 * @param {*} props
 */
const Category = ({ category, products }) => (
  <>
    <Section
      top
      mt={{ xs: 4, sm: 8, md: 8, lg: 10 }}
      mb={{ xs: 2, sm: 8, md: 8, lg: 10 }}
      maxWidth="lg"
    >
      <Marquee image={category.image} title={category.tagLine}>
        <Endorsement classes={reviewStyles} showRating={true} />
      </Marquee>
    </Section>
    <Section maxWidth="lg">
      <AdvantageBanner />
    </Section>
    <Section maxWidth="lg">
      <ProductList
        title={`Top-rated ${category.name} by Canadian Coastal Sailing`}
        target={category.target}
        products={products}
      />
    </Section>
    <Section maxWidth="lg">
      <ThingsToDo
        headerText={category.banner.title}
        text={category.banner.subtitle}
        href={"/m/squamish/sailing-tours/"}
        image={category.banner.image}
      />
    </Section>
    <Section maxWidth="lg">
      <SearchCriteria productName={category.product} />
    </Section>
  </>
)

const reviewStyles = {
  root: { mb: 5 },
  quote: {
    mb: 0.5,
  },
  rating: {
    color: "primary.main",
    mt: 1,
  },
  name: { fontSize: "1em", fontWeight: 600 },
}

/*
 **  PropTypes
 */
Category.propTypes = {
  category: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
}

export default Category
