/********************************************************************
 *
 * /components/layout.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box } from "@mui/material"
import Header from "./header"
import Footer from "./Footer"

/**
 * Layout
 *
 * Renders the layout template
 *
 * @param {*} props
 */
const Layout = ({ holdBackMenu, children }) => {
  return (
    <>
      <Header hide={holdBackMenu} />
      <Box
        id="content"
        sx={{
          minWidth: "320px",
          minHeight: "100vh",
          marginTop: holdBackMenu ? 0 : "100px",
        }}
      >
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0",
          }}
        >
          {children}
        </Box>
      </Box>
      {/* <Announcement
        message={
          <div>
            <p>
              Nonessential travel is currently restricted across BC to protect
              communities and slow the spread of COVID-19.
            </p>
            <h4>
              If you do not reside locally, we invite you to join us following
              the expiration of the health order on May 25th, 2021.
            </h4>
            Contact us for more information or for assistance preparing your
            summer sailing plans!
          </div>
        }
        href="/contact/"
      /> */}
      <Footer />
    </>
  )
}

/*
 **  PropTypes
 */
Layout.propTypes = {
  holdBackMenu: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  holdBackMenu: false,
}

export default Layout
