/********************************************************************
 *
 * /components/splitScreen/Container.js
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Box, Grid } from "@mui/material"

/**
 * Container
 *
 * Renders a splitscreen (l/r pane) container
 */
const Container = ({
  flip,
  flipForMobile = false,
  borderRadius = "inherit",
  color,
  backgroundColor,
  p = 0,
  mb = 0,
  sx,
  children,
}) => (
  <Box className="SplitScreenContainer" sx={{ mb: mb }}>
    <Grid
      container
      sx={[
        {
          flexDirection: {
            xs: flipForMobile ? "column-reverse" : "column",
            md: flip ? "row-reverse" : "row",
          },
          color: !!color ? color : "inherit",
          backgroundColor: !!backgroundColor ? backgroundColor : "inherit",
          borderRadius: borderRadius,
          padding: p,
          overflow: "hidden",
        },
        sx,
      ]}
    >
      {children}
    </Grid>
  </Box>
)

/*
 **  PropTypes
 */
Container.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  flip: PropTypes.bool,
}

Container.defaultProps = {
  flip: false,
}

export default Container
