/********************************************************************
 *
 * /components/marquee/index.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Box } from "@mui/material"
import MarqueeBoard from "./MarqueeBoard"

/**
 * Marquee
 *
 * Renders a marquee banner.
 *
 * @param {*} param0
 */
const Marquee = ({ image, title, children }) => (
  <Box
    data-component-id="MARQUEE"
    sx={{
      m: 0,
      p: 0,
    }}
  >
    <Box
      sx={{
        position: { xs: "static", sm: "relative" },
        minHeight: { xs: 0, sm: "500px" },
        py: { xs: 0, sm: "40px" },
      }}
    >
      <Box
        aria-hidden="true"
        sx={{
          position: { sm: "static", sm: "absolute" },
          top: 0,
          height: "100%",
          width: { xs: "100%", sm: "75%" },
          marginLeft: { xs: 0, sm: "25%" },
          marginBottom: { xs: 4, sm: 0 },
          overflow: "hidden",
          "& img": { borderRadius: { xs: "5px", sm: "10px" } },
        }}
      >
        <GatsbyImage
          image={getImage(image.img)}
          alt={image.alt}
          placeholder="blurred"
          loading="eager"
          height="100%"
          imgStyle={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
      <MarqueeBoard label={title}>{children}</MarqueeBoard>
    </Box>
  </Box>
)

/*
 **  PropTypes
 */
Marquee.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
}

export default Marquee
