/********************************************************************
 *
 * components/tourPicker/EndOfSeason.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import PropTypes from "prop-types"
import {
  Paper,
  Box,
  Typography,
  Button,
  Link,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useSiteTools } from "../../../providers/SiteToolsProvider"
import { Sandwich } from "../../../components"

const EndOfSeason = ({ width = "355px" }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))
  const { season, onSupportRequest } = useSiteTools()

  return (
    <Paper
      elevation={mobile ? 0 : 3}
      sx={{
        display: "inline-block",
        boxSizing: "border-box",
        padding: { xs: 4, md: 4 },
        margin: "0 auto",
        marginBottom: 0,
        width: width,
      }}
    >
      <Typography
        align="center"
        variant="h2"
        color="textPrimary"
        paragraph
        sx={{ py: 1 }}
      >
        Thank You!
      </Typography>
      <Typography
        align="center"
        variant="h4"
        color="textPrimary"
        paragraph
        sx={{ py: 1 }}
      >
        Our {season.name} is now Sold Out
      </Typography>
      <Box>
        <Sandwich>
          <Button
            variant="contained"
            align="center"
            href="/gifting/sailing-gift-cards-and-vouchers/"
            sx={{ textAlign: "center" }}
          >
            Purchase A Sailing Voucher or Gift Certificate
          </Button>
        </Sandwich>
      </Box>
      <Box sx={{ pt: 4 }}>
        <Typography color="textPrimary" align="center" paragraph>
          Next year's sailing schedule will be released early in the New Year.
        </Typography>
        <Typography color="textPrimary" align="center" paragraph>
          If you have any questions, please{" "}
          <Link
            onClick={() => {
              onSupportRequest()
            }}
            sx={{ cursor: "pointer" }}
          >
            contact us!
          </Link>
        </Typography>
      </Box>
    </Paper>
  )
}

/*
 **  PropTypes
 */
EndOfSeason.propTypes = {
  width: PropTypes.string,
}

export default EndOfSeason
