/********************************************************************
 *
 * /components/AdvantageBanner.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { Box, Typography } from "@mui/material"
import { EventAvailable, QuestionAnswer, WbSunny } from "@mui/icons-material"
import { Sandwich } from "../index"
import ItemCard from "./ItemCard"

/**
 * AdvantageBanner
 *
 * Renders an AdvantageBanner banner
 *
 * @param {*} param0
 */
const AdvantageBanner = () => (
  <Box data-component-id="ADVANTAGE_BANNER">
    <Box
      sx={{
        margin: { xs: 0 },
        marginBottom: { xs: 2.5 },
      }}
    >
      <Typography variant="h2">
        Plan with peace of mind when booking with Canadian Coastal Sailing
      </Typography>
    </Box>
    <Sandwich sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        <ItemCard
          icon={<WbSunny fontSize="large" color="primary" />}
          label="WeatherProof bookings"
        >
          <Typography>
            Weather protection for your reservation, included free with every
            booking.
          </Typography>
        </ItemCard>
        <ItemCard
          icon={<EventAvailable fontSize="large" color="primary" />}
          label="Flexible cancellation options"
        >
          <Typography>
            Free cancellation and re-booking options make it easy if your plans
            change.
          </Typography>
        </ItemCard>
        <ItemCard
          icon={<QuestionAnswer fontSize="large" color="primary" />}
          label="Customer support"
        >
          <Typography>
            Questions? Talk to our friendly local support team and get the
            answers you need.
          </Typography>
        </ItemCard>
      </Box>
    </Sandwich>
  </Box>
)

export default AdvantageBanner
