/********************************************************************
 *
 * app/orderProcessing/componenets/searchBar/ProductTypePicker.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Typography,
  Box,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { Brand } from "../../../../icons"
import Popup from "../../../../components/Popup"
import ControlBase from "./ControlBase"
import { useSiteTools } from "../../../../providers/SiteToolsProvider"

/**
 * ProductTypePicker
 *
 * Renders a menu with a default product type (everything), individual product types, and a help event handler
 *
 * @param {*} param0
 */
const ProductTypePicker = ({
  defaultPT,
  productTypes,
  ptName,
  value,
  onSelect,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const { onSupportRequest } = useSiteTools()

  if (!value) return null

  return (
    <Box>
      <ControlBase
        label="Type Of Sailing Tour"
        value={value.name}
        open={openModal}
        onClick={ref => {
          if (!openModal) {
            setOpenModal(true)
          }
        }}
      />
      <Popup
        top={10}
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
      >
        <Box
          sx={{
            background: "rgb(255, 255, 255)",
            boxShadow:
              "rgb(0 0 0 / 5%) 0px 2px 6px, rgb(0 0 0 / 7%) 0px 0px 0px 1px",
            borderRadius: "5px",
            py: 4,
          }}
        >
          <Typography variant="label" component="div" sx={{ px: 4, py: 0 }}>
            Looking At Options?
          </Typography>
          <MenuItem
            key={-1}
            onClick={() => {
              setOpenModal(false)
              onSelect(defaultPT)
            }}
            sx={{ px: 4 }}
          >
            {defaultPT.name}
          </MenuItem>
          <Typography variant="label" component="div" sx={{ px: 4, py: 1 }}>
            Or Choose A {ptName}:
          </Typography>
          {productTypes.map(productType => (
            <MenuItem
              key={productType.id}
              onClick={() => {
                setOpenModal(false)
                onSelect({
                  id: productType.id,
                  name: productType.name,
                  url: productType.page.url,
                })
              }}
              sx={{ px: 4 }}
            >
              <ListItemIcon
                sx={{
                  fontSize: "17px !important",
                  backgroundColor: "rgb(241, 241, 241) !important",
                  border: "1px solid rgba(176, 176, 176, 0.2) !important",
                  borderRadius: "8px !important",
                  minWidth: "48px !important",
                  height: "48px !important",
                  marginRight: "16px !important",
                  display: "flex !important",
                  alignItems: "center !important",
                  justifyContent: "center !important",
                }}
              >
                <Brand height={30} width={30} color={"#666666"} />
              </ListItemIcon>
              <ListItemText>
                <Typography style={{ lineHeight: "0.25rem" }} variant="body2">
                  {productType.name}
                </Typography>
                <Typography variant="caption">
                  {productType.shortDescription}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
          <Typography variant="label" component="div" sx={{ px: 4, py: 1 }}>
            Got Questions?
          </Typography>
          <MenuItem
            key={-2}
            onClick={() => {
              setOpenModal(false)
              onSupportRequest()
            }}
            sx={{ px: 4 }}
          >
            Send Us a Message
          </MenuItem>
        </Box>
      </Popup>
    </Box>
  )
}

/*
 **  PropTypes
 */
ProductTypePicker.propTypes = {
  defaultPT: PropTypes.object.isRequired,
  productTypes: PropTypes.array.isRequired,
  ptName: PropTypes.string.isRequired,
  value: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
}

export default ProductTypePicker
