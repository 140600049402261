/********************************************************************
 *
 * /components/Focus.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Typography } from "@mui/material"
import { Island } from "."

const Focus = ({ title, desc, children }) => {
  return (
    <Island>
      <Typography textAlign="center" variant="h2" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Typography textAlign="center" variant="body1" sx={{ fontWeight: 500 }}>
        {typeof desc === "string" ? (
          desc
        ) : (
          <span dangerouslySetInnerHTML={desc} />
        )}
      </Typography>
      {!!children && children}
    </Island>
  )
}

/*
 **  PropTypes
 */
Focus.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default Focus
