/********************************************************************
 *
 * components/lastMinute/index.jsx
 *
 * Copyright 2024 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { Marquee } from "../index"
import ProductList from "../ProductList"
import { Section, Endorsement, Focus, SKUBanner } from "../index"

const productTypeIds = [26, 28]

/**
 * LastMinute
 *
 * Renders a LastMinute page
 *
 * @param {*} props
 */
const LastMinute = ({ category, products }) => (
  <>
    <Section
      top
      mt={{ xs: 4, sm: 8, md: 8, md: 10 }}
      mb={{ xs: 2, sm: 8, md: 8, lg: 10 }}
      maxWidth="lg"
    >
      <Marquee image={category.image} title={category.tagLine}>
        <Endorsement classes={reviewStyles} showRating={true} />
      </Marquee>
    </Section>
    <Section maxWidth="lg" top divider>
      <Focus
        title="Get a great deal on the #1 customer-rated sailing experience in BC!"
        desc="We often sell out weeks in advance, but occasionally there are a few spots available for last-minute bookings, and we are happy to offer these to locals at a special discounted rate. Bookmark this page and check back often, like when making weekend plans or a spontaneous date-night!"
      />
    </Section>
    <Section maxWidth="lg">
      <SKUBanner productTypeIds={productTypeIds} />
    </Section>
    <Section maxWidth="lg">
      <ProductList
        title={`Other Sailing Tours by Canadian Coastal Sailing`}
        target={category.target}
        products={products}
      />
    </Section>
  </>
)

const reviewStyles = {
  root: { mb: 5 },
  quote: {
    mb: 0.5,
  },
  rating: {
    color: "primary.main",
    mt: 1,
  },
  name: { fontSize: "1em", fontWeight: 600 },
}

/*
 **  PropTypes
 */
LastMinute.propTypes = {
  category: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
}

export default LastMinute
