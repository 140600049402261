/********************************************************************
 *
 * app/orderProcessing/components/Picker.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { DateTime } from "luxon"
import format from "../../../lib/format"
import { useApp, useCCAPI, useAuthAPI } from "../../../providers/AppProvider"
import { useAffiliate } from "../../../providers/AffiliateProvider"
import * as tags from "../../../lib/tags"
import Search from "./Search"
import Results from "./results"
import EndOfSeason from "./EndOfSeason"
import useSelectedDate from "./selectedDate"
import { useSiteTools } from "../../../providers/SiteToolsProvider"

/**
 * TourPicker
 *
 * Renders a calendar-indexed list of skus.
 *
 */
const TourPicker = ({ productTypeIds, cover }) => {
  const { locale, season } = useSiteTools()

  const [ptSkuTrees, setPtSkuTrees] = useState(null)
  const [selectedDate, setSelectedDate] = useSelectedDate(
    season.start,
    season.end,
    locale.zone
  )
  const [SKUs, setSKUs] = useState(null)
  const app = useApp()
  const api = useCCAPI()
  const authApi = useAuthAPI()
  const { getAffiliate } = useAffiliate()

  useEffect(() => {
    if (!DateTime.isDateTime(selectedDate) || !selectedDate.isValid) return

    trackDateHit(selectedDate)
    tags.fireEvent("tour-search-date", {
      searchDate: selectedDate.toISODate(),
      component: "Tour Picker",
      productTypes: productTypeIds,
    })
  }, [selectedDate])

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchSKUs
   *
   * Calls the API to fetch the product types' sku trees for the dates
   * visible on the calendar.
   *
   * @param {*} start
   * @param {*} end
   */
  const fetchSKUs = (start, end) => {
    let qs = ""

    return new Promise((resolve, reject) => {
      try {
        if (!productTypeIds) throw new Error("Missing Product Types")
        if (!DateTime.isDateTime(start) && !start.isValid)
          throw new Error("Invalid start date")
        if (!DateTime.isDateTime(end) && !end.isValid)
          throw new Error("Invliad end date")

        if (end < start) throw new Error("End date is before start date")
      } catch (error) {
        reject(error)
        return
      }

      //
      //  Product types are sent in the query string as one or
      //  more pt values. If no pt values are included, use the default
      //
      //  TODO: Add more intelligence to query string parsing and defaults
      //
      productTypeIds.forEach(productTypeId => {
        qs += `${qs.length > 0 ? "&" : ""}pt=${productTypeId}`
      })

      //
      //  Request searches
      //
      api
        .fetch(
          `/api/skus/daterange/${start.toISO()}/${end.toISO()}?${qs}`,
          getAffiliate().id
        )
        .then(({ payload: ptSkuTrees }) => {
          setPtSkuTrees(ptSkuTrees)
          resolve()
        })
        .catch(error => {
          reject(error)
          setPtSkuTrees(null)
        })
    })
  }

  /**
   * TrackDateHit
   *
   * @param {Datetime} selectedDate
   */
  const trackDateHit = selectedDate => {
    //
    //  Verify date
    //
    if (!DateTime.isDateTime(selectedDate) || !selectedDate.isValid) return

    //
    //  Log the search date
    //
    authApi
      .create(`/event/producttype/`, {
        date: selectedDate.toISODate(),
        productTypeIds,
        affiliateId: getAffiliate().id,
      })
      .catch(error => {
        app.error({ error, location: "ProductSelector.trackDateHit" })
      })
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches skus for dates visible on the calendar.
   *
   * @param {*} event
   */
  const onCalendarUpdate = event => {
    const { start, end } = event

    return new Promise((resolve, reject) => {
      fetchSKUs(start, end, getAffiliate().id)
        .then(() => resolve())
        .catch(error => {
          app.error({ error, location: "ProductSelector.onCalendarUpdate" })
          reject(error)
        })
    })
  }

  /**
   * onDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {DateTime} date
   */
  const onDateChanged = date => {
    setSelectedDate(date)
    setSKUs(getSKUsForDate(ptSkuTrees, date))
  }

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are tours on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {DateTime} date
   */
  const onDateRender = date => {
    let min = null,
      max = null
    let dayPTsSkus = null
    let soldOut

    //
    //  Sanity checks
    //
    if (!DateTime.isDateTime(date) || !date.isValid)
      throw new Error("Invalid calendar date")

    if (date < DateTime.now()) {
      return {
        disable: true,
        soldout: false,
        innerHTML: null,
      }
    }

    //
    //  Fetch skus (product instances) for date
    //
    dayPTsSkus = getSKUsForDate(ptSkuTrees, date)

    soldOut = !!dayPTsSkus

    //
    //  Compute availability status and minimum price for the date
    //
    dayPTsSkus &&
      dayPTsSkus.forEach(dayPTSkus => {
        dayPTSkus &&
          dayPTSkus.forEach(sku => {
            soldOut &= parseInt(sku.available) <= 0
            let rate = parseInt(sku.rate)
            if (min === null || (rate < min && sku.available > 0)) min = rate
            if (max === null || (rate > max && sku.available > 0)) max = rate
          })
      })

    return {
      disable: !dayPTsSkus,
      soldOut: soldOut,
      innerHTML: (
        <Typography
          variant="caption"
          sx={{
            textAlign: "center",
          }}
        >
          {
            min === max
              ? format.currency(min, true)
              : `${format.currency(min, true)}` /* to ${format.currency(max)}`*/
          }
        </Typography>
      ),
    }
  }

  /**
   * Render
   */
  return (
    <Box
      sx={{
        display: { xs: "flex" },
        justifyContent: { md: "center" },
        alignItems: "flex-start",
      }}
    >
      {season.display ? (
        <>
          <Search
            min={season.start}
            max={season.end}
            value={selectedDate}
            zone={locale.zone}
            onCalendarUpdate={onCalendarUpdate}
            onDateRender={onDateRender}
            onDateChanged={onDateChanged}
          />
          <Results
            date={selectedDate}
            zone={locale.zone}
            skus={SKUs}
            cover={cover}
            onClear={() => {
              setSKUs(null)
              //   setSelectedDate(null)
            }}
          />
        </>
      ) : (
        <EndOfSeason />
      )}
    </Box>
  )
}

/////////////////////////////////////////////////////////////////////
//
//  Helper functions
//
/////////////////////////////////////////////////////////////////////

/**
 * GetSKUsForDate
 *
 * Parses the array of PT SKU trees for skus available for the
 * given date.
 *
 * @param {*} ptSkuTrees
 * @param {*} date
 *
 * @returns Returns a 2D array of skus.
 */
const getSKUsForDate = (ptSkuTrees, date) => {
  let y,
    m,
    d,
    daySkus = null

  if (!ptSkuTrees || !DateTime.isDateTime(date) || !date.isValid) return null

  //
  //  Fetch the 2D array of product type skus for the day
  //
  ptSkuTrees.forEach(ptSkuTree => {
    y = ptSkuTree.years[date.year]
    if (y) m = y.months[date.month - 1]
    if (m) d = m.days[date.day]
    if (d) {
      if (!daySkus) daySkus = []
      daySkus.push(d.nodes)
    }
  })

  return daySkus
}

/*
 **  PropTypes
 */
TourPicker.propTypes = {
  cover: PropTypes.bool,
}

export default TourPicker
