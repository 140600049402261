/********************************************************************
 *
 * app/orderProcessing/componenets/searchBar/VerticalDivider.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import React from "react"
import { Divider } from "@mui/material"

const VerticalDivider = () => (
  <Divider
    sx={{
      alignSelf: "center",
      flexBasis: "0px",
      flexGrow: 0,
      flexShrink: 0,
      borderRight: "1px solid rgb(221, 221, 221)",
      height: "32px",
      margin: "0 4px",
    }}
  />
)

export default VerticalDivider
