/********************************************************************
 *
 * /providers/AffiliateProvider.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import { useApp, useCCAPI } from "./AppProvider"

const AFFILIATE_KEY = "cc-affiliate"

const AffiliateContext = React.createContext()

const AffiliateProvider = ({ children }) => {
  const app = useApp()
  const api = useCCAPI()

  /** InitAffiliate
   *
   * Initializes the affiliate id for the booking. The affiliate will
   * be assigned as the booking agent.
   *
   * @param {*} slug
   */
  const setAffiliateFromSlug = slug => {
    const affiliate = getAffiliate()

    //
    //  Return if slug is not initialized, or if the affilate Id has previously been initialized
    //
    if (
      !slug ||
      affiliate.id !== parseInt(process.env.GATSBY_AFFILIATE_DEFAULT)
    )
      return

    //
    //  Request affiliate id
    //
    api
      .fetch(`/api/affiliates/affiliate/slug/${slug}`)
      .then(({ payload: user }) => {
        if (user && user.id) putAffiliate(user)
      })
      .catch(error => {
        app.error({ error, location: "ProductSelector.initAffiliate" })
        putAffiliate(null)
      })
  }

  ///////////////////////////////////////////////////////////////////
  //
  //  Affiliate API functions
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <AffiliateContext.Provider value={{ getAffiliate, setAffiliateFromSlug }}>
      {children}
    </AffiliateContext.Provider>
  )
}

///////////////////////////////////////////////////////////////////
//
//  Private Helper Functions
//
///////////////////////////////////////////////////////////////////

/**
 * PutAffiliate
 *
 * @param {*} key
 * @param {*} token
 */
const putAffiliate = affiliate => {
  //
  //  Protect from Gatsby build
  //
  if (typeof window !== `undefined`)
    window.sessionStorage.setItem(AFFILIATE_KEY, JSON.stringify(affiliate))
}

/**
 * GetAffiliate
 *
 * @param {*} token
 */
const getAffiliate = () => {
  let data = null

  //
  //  Protect from Gatsby build
  //
  if (typeof window !== `undefined`)
    data = window.sessionStorage.getItem(AFFILIATE_KEY)

  if (!data) return { id: parseInt(process.env.GATSBY_AFFILIATE_DEFAULT) }

  return JSON.parse(data)
}

/*
 **  Hooks
 */
const useAffiliate = () => {
  return React.useContext(AffiliateContext)
}

export { AffiliateProvider, useAffiliate }
