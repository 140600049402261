/********************************************************************
 *
 * /components/ThingsToDo.jsx
 *
 * Copyright 2022 David B. Crewson. All rights reserved.
 *
 ********************************************************************/

import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"
import { Typography, Button, Box } from "@mui/material"
import {
  SplitScreen,
  SplitScreenContentPane,
  SplitScreenImagePane,
} from "./index"

/**
 * ThingsToDo
 *
 * Renders a ThingsToDo banner
 *
 * @param {*} param0
 */
const ThingsToDo = ({ headerText, text, href, image }) => (
  <SplitScreen
    data-component-id="THINGS_TO_DO_BANNER"
    flipForMobile
    sx={{
      border: "solid 1px #ebebeb",
      borderRadius: 4,
    }}
  >
    <SplitScreenContentPane xs={12} md={6} lg={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: { xs: "center", lg: "left" },
          p: { xs: 6, md: 8 },
        }}
      >
        <Typography variant="h1" component="h2" sx={{ mb: 2 }}>
          {headerText}
        </Typography>
        <Typography gutterBottom>{text}</Typography>
        <Button variant="contained" size="small" href={href}>
          Explore More
        </Button>
      </Box>
    </SplitScreenContentPane>
    <SplitScreenImagePane
      xs={12}
      md={6}
      lg={8}
      fluid={getImage(image.img)}
      alt={image.alt}
      mobileHeight="300px"
    />
  </SplitScreen>
)

/*
 **  PropTypes
 */
ThingsToDo.propTypes = {
  headerText: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  image: PropTypes.object,
}

export default ThingsToDo
